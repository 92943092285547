/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    angular.module('app')
        .directive('judgeEditEntry', editJudgeEntry);

    editJudgeEntry.$inject = ['activeEntry', 'judgeStatus', 'user', 'activeJudges', 'webAPI', '$q'];
    function editJudgeEntry(activeEntry, judgeStatus, user, activeJudges, api, $q) {
        return {
            scope: {
                entry: '=',
                group: '=',
                score: '=',
                numberOfJudges: '='
            },
            link: function (scope, e, attr) {
                function judge() {
                    if (!scope.entry.Group) {
                        scope.entry.Group = scope.group;
                    }

                    var numberOfJudges = scope.numberOfJudges;
                    if (!numberOfJudges) {
                        if (user.keyFields) {
                            numberOfJudges = api.judges.get({
                                EventId: scope.score.EventId,
                                RoundNumber: scope.score.Round,
                                ExerciseId: scope.score.ExerciseId
                            });
                        } else {
                            numberOfJudges = { $promise: $q.when(null) };
                        }
                    }

                    $q.all([
                        activeJudges.setRoom(scope.score),
                        //api.tariffs.getForExercise({ id: scope.score.EntryId, exercise: scope.score.ExerciseId }).$promise,
                        numberOfJudges.$promise
                    ])

                        .then(function (r) {
                            judgeStatus.clear();
                            var score = new api.entryScore(r[0].score);
                            // scope.entry.Tariffs = r[1].tariffs;
                            // scope.entry.Formations = r[1].formations;
                            scope.entry.Tariffs = [];
                            scope.entry.Formations = [];
                            activeEntry.set(scope.entry, score, r[2]);
                            return r;
                        });
                }

                e.on('click.judgeEntry', judge);
                scope.$on('$destroy', function () {
                    e.off('click.judgeEntry', judge);
                });
            }

        };
    }

}());
