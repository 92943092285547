/**
 * Created by kevinpayne on 28/01/15.
 */
(function () {
    'use strict';


    angular.module("app").controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', 'categories', 'viewstatus', 'user'];
    function DashboardController($scope, categories, viewstatus, user) {

        viewstatus.title = 'Scoreboard Console';
        $scope.judging = categories;
        $scope.overrideLocation = user.overrideLocation;
        $scope.location = user.location && user.location.toLowerCase() || '';

        $scope.canJudgeExercise = function(ex, e){
            if(user.overrideLocation)
                return true;
            if(ex.Name.toLowerCase() !== user.location.toLowerCase()){
                e.preventDefault();
            }
        };
    

    }
}());
