(function (app) {

    var dateSettings, validationSettings;

    dateSettings = {
        datepickerPopup: 'MMM dd, yyyy'
    };

    validationSettings = {
        enabled: true, defaultMsgs: {
            valid: '',
            required: ''
        }
    };

    app.constant('schemaOptions', {
        validation: validationSettings,
        layout: { type: 'horizontal', labelSize: 4, inputSize: 8 },
        dateSettings: dateSettings
    });
    app.constant('schemaOptionsVertical', {
        validation: validationSettings,
        layout: { type: 'vertical', labelSize: 5, inputSize: 7 },
        dateSettings: dateSettings
    });

}(angular.module('app')));