/**
 * Created by kevinpayne on 17/02/16.
 */
(function(){
    angular.module('app.UI').directive('title', title);
    title.$inject = ['viewstatus'];
function title(viewstatus) {
    return {
        restrict: 'E',
        link: function (s, e, a) {
            s.$watch(function () {
                return viewstatus.title;
            }, function (newvalue) {
                if (newvalue) {
                    e.get(0).innerText = newvalue;
                } else{
                    e.get(0).innerText = 'Scoreboard Console';
                }
            });
        }
    };
}
}());