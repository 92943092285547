(function (app) {

    function status($timeout) {
        return function () {
            var self = this;
            this.title = '';
            this.loadingView = false;
            this.startLoading = function () {
                self.loadingView = true;
            };
            this.stopLoading = function () {
                self.loadingView = false;
            };
            this.create = function (msg, type, dismiss) {
                var status = {
                    type: type || 'danger',
                    msg: msg,
                    close: function () {
                        self.dismiss(this);
                    }
                };
                self.collection.push(status);


                status.timer = $timeout(function () { status.close(); }, dismiss || 2000);
                
                self.lastStatus = status;
                return status;
            };
            this.dismiss = function (status) {

                var index;
                if (status.timer) {
                    $timeout.cancel(status.timer);
                }
                index = self.collection.indexOf(status);
                if (index > -1) {
                    self.collection.splice(index, 1);
                }
                if (angular.equals(status, self.lastStatus)) {
                    self.lastStatus = null;
                }
            };
            this.clear = function () {
                self.collection.length = 0;
            };
            this.clearLastStatus = function () {
                if (self.lastStatus) {
                    self.lastStatus.close();
                    self.lastStatus = null;
                }
            };
            this.collection = [];
            this.displayWebApiError = function (data) {
                var error, err;
                if (data.data) {
                    err = data.data;
                } else {
                    err = data;
                }
                if (typeof err === 'string') {
                    error = err;
                }
                if (err.ExceptionMessage) {
                    error = err.ExceptionMessage;
                } else if (err.Message || err.message) {
                    error = err.Message || err.message;
                }


                return self.create(error || 'Unknown error occurred', undefined, 5000);

            };
            this.lastStatus = null;

        };

    }

    function createStatus($timeout) {
        var obj = status($timeout);
        return new obj();
    }

    app.factory('viewstatus', ['$timeout', createStatus]);
    app.factory('status', ['$timeout', status]);

}(angular.module('app.UI')));
