/**
 * Created by kevinpayne on 28/01/15.
 */
(function (app) {
    'use strict';
    
    app
        .controller('EntrySearchController', EntrySearchController)
        .controller('EntryResultExercisesController', EntryResultExercisesController)
        .directive('entrySearch', entrySearch)
        .directive('entryResult', entryResult)
        .directive('entryResultExercises', entryResultExercises);
    
    
    entrySearch.$inject = ['routes'];
    function entrySearch(routes) {
        return {
            templateUrl: routes.templateDirectory('entry-search'),
            controller: 'EntrySearchController',
            controllerAs: 'search'
        };
    }
    entryResult.$inject = ['routes'];
    function entryResult(routes) {
        return {
            templateUrl: routes.templateDirectory('entry-result'),
            scope: { entry: '=', exercise: '=' }
        };
    }


    EntrySearchController.$inject = ['search', 'viewstatus', 'webAPI'];
    function EntrySearchController(search, viewstatus, webAPI) {
        var vm = this;
        vm.model = search;

    }
    entryResultExercises.$inject = ['routes'];
    function entryResultExercises(routes) {
        return {
            scope: {
                entry: '=',
                exerciseName: '='
            },
            controller: 'EntryResultExercisesController',
            templateUrl: routes.templateDirectory('entry-result-exercise')
        };
    }
    
    EntryResultExercisesController.$inject = ['$scope', '$routeParams', '$filter', 'webAPI', 'user', 'viewstatus'];
    function EntryResultExercisesController($scope, $routeParams, $filter, webAPI, user, viewstatus) {
        var vm = $scope;
        vm.overrideLocation = user.overrideLocation;
        vm.location = user.location.toLowerCase();
        vm.headJudge = user.headJudge ? user.id : false;
        vm.unlock = unlock;
        vm.entry.$promise.then(function () {
            
            if ($routeParams.exercise) {
                vm.exercises = $filter('filter')(vm.entry.Exercises, findExercise);
            } else {
                if (!$routeParams.category || filterByCategory()) {
                    vm.exercises = vm.entry.Exercises;
                } else {
                    vm.exercises = [];
                }
            }

           
        });
        vm.getScore = function (ex) {
            var scores = $filter('filter')(vm.entry.Scores, { ExerciseId : ex.Id });
            if (scores.length === 0) {
                //todo: round rotation?? - these scores should already be created at database
                scores.push(new webAPI.entryScore({
                    ExerciseId: ex.Id,
                    EventId: vm.entry.CompetitionId,
                    EntryNumber: vm.entry.EntryNumber,
                    EntryId: vm.entry.Id,
                    JudgeScores: [],
                    HasScore: false,
                    DidNotCompete: false,
                    D: 0,
                    E: 0,
                    Pen: 0,
                    A: 0,
                    Score: 0
                })
                );
            }
            return scores;
        };

        function unlock(score){
            webAPI.entryScore.unlockScore({ scoreId: score.Id }, function(result){
                if(result){
                    score.Locked = result.Locked;
                    score.UpdatedBy = result.UpdatedBy;
                }
            },  viewstatus.displayWebApiError);
        }
        
        function findExercise(e) {
            return filterByExercise(e) && filterByCategory();// && filterByDiscipline();
        }
        function filterByExercise(e) {
            return e.Name.toLowerCase() === $routeParams.exercise.toLowerCase();
        }
        function filterByCategory() {
            return $routeParams.category.toLowerCase() === vm.entry.Discipline.Category.Name.toLowerCase();
        }
        function filterByDiscipline(){
            if($routeParams.discipline){
                return $routeParams.discipline.toLowerCase() === vm.entry.Discipline.ShortName.toLowerCase();
            }
            return true;
        }

    }


}(angular.module('app')));