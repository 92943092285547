 /**
 * Created by kevinpayne on 05/03/15.
 */
(function(app){
    'use strict';
    app.controller('AuthorizeController', ['$scope', 'user', '$location', function($scope,user, $location){

        $scope.user = user;
        $scope.redirect = function(){
            $location.url('/');
        };
    }]);
}(angular.module('app')));