/**
 * Created by kevinpayne on 21/12/14.
 * todo: rollback score values.
 */
(function (app) {
    'use strict';
    angular.module('app').directive('editScore', editScore);


    editScore.$inject = ['viewstatus', 'activeEntry'];

    function editScore(viewstatus, activeEntry) {


        return {

            scope: {
                exercise: '=',
                score: '=',
                entry: '='
            },
            link: function (scope, el, attr) {
                var cachedValue;

                function clickHandler() {


                    scope.$apply(
                        function () {
                            if(scope.score.JudgeScores.length > 0) {
                                activeEntry.set(scope.entry, scope.score);
                                return;
                            }
                            var exercise;
                            exercise = scope.exercise;
                            exercise._edit = !exercise._edit;
                            if (exercise._edit) {
                                cachedValue = angular.copy(scope.score);
                                if(scope.score.DidNotCompete) {
                                    scope.score.DidNotCompete = false;
                                    scope.score.HasScore = false;
                                }
                            } else {
                                angular.copy(cachedValue, scope.score);
                            }
                        }
                    );

                }


                el.on('click.app.editScore', clickHandler);


                scope.$on('$destroy', function () {
                    el.off('click.app.editScore', clickHandler);
                });

                scope.$watch('exercise._edit', function (newvalue) {
                        if (newvalue === true) {
                            el.title = 'Cancel editing';
                        } else {
                            el.title = 'Edit score';
                        }
                    }
                );
                scope.$watch(function () {
                    return viewstatus.loadingView;
                }, function (newvalue) {
                    if (newvalue === true) {
                        el.attr('disabled', 'disabled');
                    } else {
                        el.removeAttr('disabled');
                    }

                });


            }
        };

    }

}());