/**
 * Created by kevinpayne on 29/05/15.
 */
(function(){
    "use strict";
    angular.module('app')
        .factory('sendStatus', sendStatus );

    sendStatus.$inject = ['scoreboardTicker'];

    function sendStatus(messaging){
        return function(entry, exercise, judgeStatus){
            messaging.invoke('judgeStatus', {
                entryId: entry.Id,
                entryNum: entry.EntryNumber,
                entryName: (entry.TeamName) ? entry.TeamName : entry.$gymnasts(),
                entryTeam: entry.TeamName,
                clubName: entry.ClubName || entry.Club.Name,
                exerciseName: exercise.Name || exercise,
                exerciseId: exercise.Id,
                status: judgeStatus,
                group: entry.GroupName || entry.Group.Name,
                discipline: entry.Discipline || entry.Group.Discipline
            });
        };
    }

}());