/**
 * Created by kevinpayne on 29/05/15.
 */
(function(){
    "use strict";

    angular.module('app')
        .directive('didNotCompete', didNotCompete);

    didNotCompete.$inject = ['viewstatus', 'activeEntry'];
    function didNotCompete(viewstatus, activeEntry){
        return {
            scope: {
                score: '=didNotCompete'
            },
            link: function(scope, e, attr) {
                function setDidNotComplete() {
                    scope.$apply(function(){
                        viewstatus.startLoading();

                        var score = activeEntry.setForWithdraw(scope.score);
                      
                       score[(score.isNew() ? '$save' : '$update')](function (data) {

                            angular.copy(score, scope.score);
                            
                            return data;
                        }, viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);


                    });
                }

                e.on('click.didNotCompete', setDidNotComplete);
                scope.$on('$destroy', function(){
                    e.off('click.didNotCompete', setDidNotComplete);
                });
            }
        };
    }


}());
