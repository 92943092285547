/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    angular.module('app')
        .directive('headJudgeKeyFields', headJudgeKeyFields);

    headJudgeKeyFields.$inject = ['routes'];
    function headJudgeKeyFields(routes) {
        return {
            controller: HeadJudgeKeyFieldsController,
            controllerAs: 'judging',
            templateUrl: routes.templateDirectory('judging/head-judge-keyfields')
        };
    }

    HeadJudgeKeyFieldsController.$inject = ['webAPI', 'scoreboardTicker', 'activeEntry', 'judgeStatus', 'activeJudges', 'user', '$scope', 'teamGymTolerances', '$timeout', 'viewstatus'];
    function HeadJudgeKeyFieldsController(webAPI, messaging, activeEntry, judgeStatus, activeJudges, user, $scope, teamGymTolerances, $timeout, viewstatus) {
        var vm = this;

        var exercise = activeEntry.getExercise();
        var score = activeEntry.getScore();

        var userKeyFields = user.keyFields.split(',');
        var showMessages = false;


        var listeners = [];

        vm.submitscore = submitscore;
        vm.activeEntry = activeEntry;
        vm.activeJudges = activeJudges;
        vm.judgeEnter = activeJudges.focus;
        vm.judgeExit = activeJudges.blur;
        vm.saveComplete = saveComplete;
        vm.revertScore = revertScore;

        vm.saveSJ = saveSJ;
        vm.negateSJ = negateSJ;
        vm.currentEntry = angular.copy(activeEntry.get());
        vm.score = score;
        vm.exercise = exercise;
        vm.exercisePasses = setPasses(exercise, score);
        vm.isEditable = isEditable;

        if (exercise.$promise) {
            exercise.$promise.then(function (e) {

                setUpSchema();

                return e;
            });
        } else {
            setUpSchema();
        }

        listeners.push(
            createUnsubscribe('judgeChangedKeyValue', judgeChangedKeyValueHandler),
            createUnsubscribe('judgeRevert', revertHandler),
            createUnsubscribe('judgeChangedKeyValueAfterSubmit', snitchHandler),
            createUnsubscribe('sj', updateSJ)
        );

        function updateSJ(d) {
            if (d.id === vm.currentEntry.Id) {
                vm.currentEntry.SJ = d.value;
            }
            if (activeEntry.get().Id == d.id) {
                activeEntry.get().SJ = d.value;
            }
        }

        $scope.$on('$destroy', function () {
            listeners.forEach(function (fn) { fn(); });
        });

        function saveSJ() {
            vm.currentEntry.$sj(function (r) {
                activeEntry.get().SJ = vm.currentEntry.SJ;
            });
        }
        function negateSJ() {
            var value = vm.currentEntry;
            if (value.SJ && value.SJ > 0) {
                value.SJ = value.SJ * -1;
            }
        }


        function revertHandler() {
            $timeout(function () {
                //$scope.scoresheet.$setPristine();
                score = activeEntry.getScore();
                score._judges = {};
                score.tolerances = undefined;
                score.credits = undefined;
                setUpSchema();
            });
        }

        function snitchHandler(data) {

            data.changedFields.forEach(function (key) {
                var judge;
                if (activeEntry.schema[key[0]].UseTariff) {
                    judge = vm.judges[key.substr(0, 2)];
                } else {
                    judge = vm.judges[key];
                }
                if (judge) {
                    judge.editing = true;
                }
            });
        }

        function findScore(s, defaultValue) {
            var filter = s.tariffid ? function (j) { return j.type === s.type && j.judgeNumber === s.judgeNumber && j.tariffid === s.tariffid; } : function (j) { return j.type === s.type && j.judgeNumber === s.judgeNumber; };
            return score.JudgeScores.find(filter) || defaultValue || null;
        }

        function judgeChangedKeyValueHandler(d) {
            score._judges = d.judgesSubmitted;

            d.scores.forEach(function (s) {
                var judge = findScore(s);
                if (judge) {
                    judge.score = s.score;
                } else {
                    score.JudgeScores.push(s);
                }
            });

            for (var key in d.score) {
                score[key] = d.score[key];
            }


            updateScores();
            updateSubmissions();
            total();
        }


        function updateScores() {
            var judge;
            score
                .JudgeScores
                .sort(sortByJudgeNumber)
                .forEach(function (s) {

                    if (s.tariffid) {
                        var key = s.type + s.judgeNumber;
                        if (!vm.judgeScores.hasOwnProperty(s.type)) {
                            vm.judgeScores[s.type] = {};
                        }

                        judge = vm.judgeScores[s.type][key];
                        if (!judge) {
                            judge = vm.judgeScores[s.type][key] = {};
                        }
                        if (judge[s.tariffid]) {
                            judge[s.tariffid].judgingid = s.judgingid;
                            judge[s.tariffid].score = s.score;
                            judge[s.tariffid].judgeNumber = s.judgeNumber;
                            judge[s.tariffid].type = s.type;
                        } else {
                            judge[s.tariffid] = s;
                        }

                    } else {

                        var judgekey = s.type + s.judgeNumber;
                        var judgeScore;
                        if (userKeyFields.indexOf(judgekey) > -1) {
                            if (!vm.judgeScores.hasOwnProperty(s.type)) {
                                vm.judgeScores[s.type] = [];
                            }
                            judge = vm.judgeScores[s.type];

                            judgeScore = judge.find(function (j) {
                                return j.judgeNumber === s.judgeNumber;
                            });
                            if (judgeScore) {
                                judge = vm.judgeScores[s.type] = judge.filter(function (j) {
                                    return j !== judgeScore;
                                });

                            }

                            judge.push(s);
                            judge.sort(sortByJudgeNumber);
                        }

                    }
                });


        }

        function updateSubmissions() {
            var judges = {};
            var submitted = true;
            if (score.HasScore) {
                judges.submitted = true;

                activeEntry.schemaAsArray.forEach(function (schema) {
                    if (schema.HasMulti) {
                        for (var i = 1; i <= schema.NumberOfJudges; i++) {
                            judges[schema.Key + i] = { submitted: true };
                        }
                    }
                });
            } else {
                var judgeSubmissions = score._judges;

                if (judgeSubmissions) {
                    for (var key in judgeSubmissions) {
                        /*jshint loopfunc: true */
                        judgeSubmissions[key].forEach(function (j) {
                            judges[key + j] = { submitted: true };
                        });

                    }
                    activeEntry.schemaAsArray.forEach(function (s) {
                        if (s.UseTariff || s.HasMulti) {
                            if (!judgeSubmissions.hasOwnProperty(s.Key)) {
                                submitted = false;
                            } else {
                                if (judgeSubmissions[s.Key].length !== s.NumberOfJudges) {
                                    submitted = false;
                                }
                            }

                        }
                    });
                    judges.submitted = submitted;
                }
            }

            vm.judges = judges;
            vm.submitted = userHasSubmitted();

        }
        function isEditable(element) {
            if (score.HasScore) {
                return true;
            }
            return userKeyFields.indexOf(element) > -1;
        }

        function total() {
            if (vm.judges.submitted || vm.submitted) {

                calculateTariffScores();
                activeEntry.schemaAsArray.forEach(function (schema) {
                    if (schema.HasMulti || schema.UseTariff) {
                        activeEntry.judgeTotal(schema, activeEntry.getScore(), schema.Key);
                    }
                });
            } else {
               
                   activeEntry.schemaAsArray.filter(function (s) {
                        return userKeyFields.indexOf(s.Key) === -1;
                    }).forEach(function (s) {
                       
                        if (s.UseTariff) {
                            calculateTariffScores();
    
                        }
                        if (s.HasMulti) {
                            activeEntry.judgeTotal(s, activeEntry.getScore(), s.Key);
                        }
                    });
    
                
            }

        }

        function userHasSubmitted() {
            if (!vm.judges) {
                return false;
            }
            return userKeyFields.reduce(function (o, v) {

                return vm.judges.hasOwnProperty(v) && vm.judges[v].submitted ? ++o : o;
            }, 0) === userKeyFields.length;
        }
        function calculateTariffScores() {
            if (vm.tariffs && vm.judges.submitted) {
                activeEntry.schemaAsArray.filter(function (s) {
                    return s.UseTariff;
                }).forEach(function (s) {
                    var scores = vm.score.JudgeScores.filter(function (score) {
                        return score.type === s.Key && !score._deleted;
                    });
                    var credit = teamGymTolerances.calculateTariffScores(s, vm.tariffs, scores);
                    vm.credits = credit;
                });
            }
        }

        function setPasses(exercise, score) {

            if ((score.Passes || 1) < exercise.Passes) {
                score.Passes = exercise.Passes;
            }

            return new Array(exercise.Passes > 1 ? 2 : 1);

        }

        function setUpSchema() {
            var schemaAsArray = activeEntry.schemaAsArray;

            var viewSchema = [];
            var judgeScores = {};
            // var allJudgeScores = {};

            schemaAsArray.forEach(function (schema) {
                if (showMessages) return;
                if (schema.HasMulti) {
                    if (userKeyFields.filter(function (k) { return k[0] === schema.Key; }).length < schema.NumberOfJudges) {
                        showMessages = true;
                    }
                }
            });



            vm.scoringSchema = viewSchema;
            vm.judgeScores = judgeScores;
            vm.credits = {};
            vm.hasSchema = schemaAsArray.length > 0;

            if (!vm.tariffs) {
                vm.tariffs = activeEntry.getTariffs();
            }

            if (score.HasScore) {
                viewSchema.push.apply(viewSchema, schemaAsArray);
                webAPI.entryScore.get({ id: score.EntryNumber, scoreId: score.Id }).$promise.then(function (original) {

                    original.JudgeScores.reduce(function (o, s) {
                        o[s.judgingid] = s;
                        return o;
                    }, original);

                    vm.originalScores = original;
                });

            } else {
                schemaAsArray.forEach(function (s) {
                    viewSchema.push(s);
                    // if (s.Key === 'Pen') {
                    //     viewSchema.push(s);
                    // } else {
                    //     if (user.keyFields.indexOf(s.Key) > -1) {
                    //         viewSchema.push(s);
                    //     }
                    // }
                });
            }

            viewSchema.forEach(function (s) {
                if (s.UseTariff) {

                    //if there are two d panels on one table, then the ui needs to change a bit

                    judgeScores[s.Key] = Array.apply(null, new Array(s.NumberOfJudges)).reduce(function (j, v, i) {
                        var judgeNumber = i + 1;
                        var key = s.Key + judgeNumber;
                        if (userKeyFields.indexOf(key) > -1) {
                            vm.judgeField = key;
                        }
                        j[key] = vm.tariffs.reduce(function (o, tariff, i) {
                            o[tariff.TariffId] = {
                                score: undefined,
                                type: s.Key,
                                judgeNumber: judgeNumber,
                                tariffid: tariff.TariffId,
                                scoreid: score.Id
                            };
                            return o;
                        }, {});


                        return j;
                    }, {});

                    score.JudgeScores.filter(function (j) {
                        return j.type === s.Key;
                    }).forEach(function (judgeScore) {
                        judgeScores[s.Key][s.Key + judgeScore.judgeNumber][judgeScore.tariffid] = judgeScore;
                    });


                } else {

                    if (s.HasMulti) {
                        judgeScores[s.Key] = score.JudgeScores.filter(function (j) {
                            return j.type === s.Key && (score.HasScore || userKeyFields.indexOf(j.type + j.judgeNumber) > -1);
                        }).sort(sortByJudgeNumber);

                    }
                }
            });

            //fill in any blanks
            userKeyFields.forEach(function (field) {
                if(field === 'Pen'){
                    return;
                }
                var key = field[0];
                var judgeNumber = +field[1];
                var s = activeEntry.schema[key];
                if(!s.HasMulti){
                    return;
                }
                if (judgeNumber > s.NumberOfJudges) {
                    return;
                }
                if (judgeScores.hasOwnProperty(key) && s.HasMulti && !s.UseTariff) {

                    var tempScore = judgeScores[key].find(function (s) {
                        return s.judgeNumber === judgeNumber;
                    });
                    if (!tempScore) {
                        judgeScores[key].push({ type: key, judgeNumber: judgeNumber, scoreid: score.Id });
                        judgeScores[key].sort(sortByJudgeNumber);
                    }
                }
            });

            updateSubmissions();
            total();


        }

        function sortByJudgeNumber(a, b) {
            return a.judgeNumber - b.judgeNumber;
        }

        function createUnsubscribe(name, cb) {
            var fn = messaging.on(name, cb);
            return function () {
                messaging.off(name, fn);
            };
        }


        function submitscore() {

            var data = {
                id: exercise.Id,
                scores: [],
                score: {}
            };
            //activeJudges.judgeKeyChange();]
            vm.scoringSchema.forEach(function (schema) {
                if (schema.UseTariff) {
                    var types = vm.judgeScores[schema.Key];
                    for (var judgeField in types) {
                        if ((score.HasScore && !score.DidNotCompete) || userKeyFields.indexOf(judgeField) > -1) {
                            for (var tariff in types[judgeField]) {
                                data.scores.push(types[judgeField][tariff]);
                            }
                        }
                    }

                } else if (schema.HasMulti) {
                    data.scores.push.apply(data.scores, vm.judgeScores[schema.Key].slice());
                } else {
                    data.score[schema.Key] = vm.score[schema.Key];
                    //todo: single scores? 
                }
            });
            activeJudges.judgeKeyChange(data, function () {
                viewstatus.create('Scores submitted, check other judge scores before saving final score', 'success');
            });
        }

        function saveComplete() {
            activeEntry.update();
            activeEntry.clear();
            judgeStatus.clear();
            activeJudges.clear();
        }

        function revertScore() {
            activeJudges.revert(score.Id, showMessages);
        }
    }

}());

/* todo:

How does missing judges work. Will the limitation be just 4 judge or 2 judge panels
    - add ability to define per competition number of judges. Per round/rotation, what would the granularity be.

    - when revertings score edit alert button returns to blue state.
    - when head judge changes satellite scores, change the satellite panels
    - editing a saved score, head judge should have access to all score components?
    

    
-  sj separated from the "Age Bonus" field, UPDATE score panel!! (publish and deploy)

testing
*/