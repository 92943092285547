/**
 * Created by work on 21/01/16.
 */
(function(){
    "use strict";
    angular.module('app')
        .controller('RegisterController', RegisterCtrl);

    RegisterCtrl.$inject=['webAPI', 'activeCompetition', '$routeParams', 'viewstatus', '$location', 'user', 'locations'];
    function RegisterCtrl(api, activeCompetition, $routeParams, viewstatus, $location, user, locations){
        var vm = this;

        vm.register = register;
        vm.model = {
            eventId : activeCompetition.data.Id,
            role :   $routeParams.role || 'door'
        };

        vm.locations = locations;

        function register() {
            user.reset(vm.model);
            user.$register(goToLogin, viewstatus.displayWebApiError);
        }
        function goToLogin() {
            $location.url('/');
        }
    }
}());