(function (app) {

    app.directive('groupScoreTable', [function () {

        return {
            scope: {
                group: '=',
                discipline: '='
            },
            controller: 'GroupScoresController',
            template:'<div ng-include="getTemplate()"></div>',
            link: function (scope) {
                scope.calculate(scope.group.Entries, scope.discipline, scope.group);
                scope.getTemplate = function () {
                    return scope.discipline.Category.Name == 'Acrobatics' ? '/templates/report-acro-group-table.html' : '/templates/report-group-table-artistic.html';
                };
            }
        };
    }]);

}(angular.module('app.shared')));