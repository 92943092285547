/**
 * Created by kevinpayne on 21/12/14.
 */
(function () {
    'use strict';

    angular.module('app')
        .directive('saveScore', saveScore);
    saveScore.$inject = ['viewstatus', 'authorize', 'scoreCache'];

    function saveScore(viewstatus, authorize, scoreCache) {

        return {
            require: '^ngModel',
            link: function (scope, el, attr, ctrl) {

                function success(r) {
                    r.HasScore = true;
                    viewstatus.create('Score has been saved', 'success', 3000);
                    scope.$eval(attr.saveScore);
                    scoreCache.removeEntry(r);
                    return r;
                }
                function fail(err) {
                    if (err.status === 401) {
                        authorize().result.then(function () {
                            saveScore();
                        });
                        return;
                    }
                    viewstatus.displayWebApiError(err);
                }

                function saveScore() {
                    var promise,
                        score = ctrl.$modelValue;

                    if (scope.scoresheet && scope.scoresheet.$valid === false) {
                        viewstatus.create("Correct errors on the scoresheet first. See red boxes");
                        return;
                    }
                    scoreCache.pending(score);

                    viewstatus.startLoading();

                    promise = score[score.isNew() ? '$save' : '$update'](success, fail);
                    promise['finally'](viewstatus.stopLoading);
                    return promise;

                }


                function clickHandler() {
                    scope.$apply(saveScore);
                }
                if (!el.hasClass('btn-primary')) {
                    el.addClass('btn btn-danger');
                }

                el.on('click.app.saveScore', clickHandler);
                el.attr('title', 'Save score');

                scope.$on('$destroy', function () {
                    el.off('click.app.saveScore', clickHandler);
                });

                scope.$watch(function () { return viewstatus.loadingView; }, function (newvalue) {
                    if (newvalue === true) {
                        el.attr('disabled', 'disabled');
                    } else {
                        el.removeAttr('disabled');
                    }

                });
            }
        };
    }


}());