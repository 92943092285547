/**
 * Created by kevinpayne on 07/04/16.
 */
(function () {
    angular.module('app.competitionAdmin')
        .controller('NewGroupController', NewGroupController)
        .controller('EditGroupController', EditGroupController)
        .service('SystemDisciplines', SystemDisciplines)
        .service('SystemExercises', SystemExercises)
        .service('SystemCategories', SystemCategories)
        .directive('groupForm', GroupForm);

    GroupForm.$inject = ['competitionAdminTemplates'];
    function GroupForm(templateDirectory) {
        return {
            templateUrl: templateDirectory('group-form'),
            scope: {
                group: '=group',
                competition: '=',
                showRemove: '='
                //  disciplines: '=disciplines'
            },
            controllerAs: 'view',
            controller: EditGroupController,
            bindToController: true
        };
    }
    NewGroupController.$inject = ['competition'];
    function NewGroupController(competition) {
        this.group = {
            EventId: competition.Id,
            EventGroup: {
                EventId: competition.Id
            }
        };
        this.competition = competition;
        this.entries = [];
    }

    function SystemCategories(){
        return [
            {type: 'Artistic', key:1, code: 'artistic' },
            {type: 'Acrobatics', key: 3, code: 'acrobatics'},
            {type: 'TeamGym', key:4, code:'TeamGym' },
            {type: 'Tumbling', key: 6, code: 'tumbling'},
            {type: 'Floor & Vault', key: 10, code: 'FV'},
            {type: 'Grades', key:12, code: 'grades' }
        ];
    }

    function SystemDisciplines() {
        return [
            { type: 'Mens Artistic', code: 'MAG', key: 4, value: 'MAG Total all apparatus' },
            { type: 'Mens Artistic', code: 'MAG', key: 19, value: 'MAG Best 4 apparatus' },
            { type: 'Mens Artistic', code: 'MAG', key: 17, value: 'MAG National' },
            { type: 'Mens Artistic', code: 'MAG', key: 15, value: 'MAG Regional' },
            { type: 'Mens Artistic', code: 'MAG', key: 22, value: 'MAG Adult newbie' },
            { type: 'Mens Artistic', code: 'CG', key: 32, value: 'MAG Club Grades' },
            { type: 'Mens Artistic', code: 'EG', key: 33, value: 'MAG Elite Grades' },
            { type: 'Womens Artistic', code: 'WAG', key: 14, value: 'WAG, Total all apparatus' },
            { type: 'Womens Artistic', code: 'WAG', key: 20, value: 'WAG, Best 3 of 4 apparatus' },
            { type: 'Womens Artistic', code: 'WAG', key: 23, value: 'WAG Adult newbie' },
            { type: 'Floor & Vault', code: 'FV', key: 29, value: 'F&V: Difficulty, Execution (10)' },
            { type: 'Floor & Vault', code: 'FV', key: 27, value: 'F&V: Start Value, Deductions' },
            // { type: 'Floor & Vault', code: 'FV', key: 29, value: 'F&V: Score Only' },
            { type: 'Floor & Vault - Men', code: 'FVM', key: 45, value: 'F&V: D, E (10)'},
            { type: 'Floor & Vault - Men', code: 'FVM', key: 28, value: 'F&V: Start Value, Deductions'},
            // { type: 'Floor & Vault - Men', code: 'FV-M', key: 45, value: 'F&V: Score Only'},
            { type: 'Floor & Vault - Women', code: 'FVW', key: 46, value: 'F&V: D, E (10)'},
            { type: 'Floor & Vault - Women', code: 'FVW', key: 44, value: 'F&V: Start Value, Deductions'},
            // { type: 'Floor & Vault - Women', code: 'FV-W', key: 46, value: 'F&V: Score Only'},
            { type: 'TeamGym', code: 'TG', key: 18, value: 'TeamGym: Score only' },
            { type: 'TeamGym', code: 'TG', key: 12, value: 'TeamGym: Difficulty, Execution, Composition, HJD' },
            { type: 'TeamGym', code: 'MC', key: 24, value: 'Micro: Score only' },
            { type: 'TeamGym', code: 'MC', key: 16, value: 'Micro: Difficulty, Execution, Composition, HJD' },
            { type: 'Other', code: 'ACRO', key: 2, value: 'Acrobatics' },
            { type: 'Other', code: 'Tum', key: 6, value: 'Tumbling' }];

    }
    function SystemExercises() {
        return {
            'MAG': [{
                key: 1,
                value: 'FX,PH, SR, VT, PB, HB'
            }, {
                key: 9,
                value: 'FX,PH, SR, VT, PB, HB, PPP'
            }, {
                key: 10,
                value: 'FX,PH, SR, VT, APP, PB, HB, PPP'
            }],
            'WAG': [
                {
                    key: 2,
                    value: 'VT, UB, BB, FX'
                }
            ],
            'ACRO': [
                {
                    key: 8,
                    value: 'Balance'
                }, {
                    key: 6,
                    value: 'Balance, Dynamic'
                }, {
                    key: 5,
                    value: 'Balance, Dynamic, Combined'
                }
            ],
            'MC': [
                {
                    key: 4,
                    value: 'Tumble, Trampet'
                }],
            'TG': [
                {
                    key: 3,
                    value: 'Floor, Tumble, Trampet'
                }
            ],
            'Tum': [
                {
                    key: 13,
                    value: '3 tumble runs'
                }
            ],
            'FVW': [

                {
                    key: 7,
                    value: 'FX+Music, VT'
                },
                {
                    key: 23,
                    value: 'Blue Floor & Vault'
                },
                { 
                    key: 24, 
                    value: 'Red Floor & Vault'
                },{
                    key: 25,
                    value: 'Green Floor & Vault'
                },
                { 
                    key: 26, 
                    value: 'Yellow Floor & Vault'
                }
            ],
            'FVM': [
                {
                    key: 11,
                    value: 'FX, VT'
                },
                {
                    key: 23,
                    value: 'Blue Floor & Vault'
                },
                { 
                    key: 24, 
                    value: 'Red Floor & Vault'
                },{
                    key: 25,
                    value: 'Green Floor & Vault'
                },
                { 
                    key: 26, 
                    value: 'Yellow Floor & Vault'
                }
            ],
            'FV': [
                {
                    key: 11,
                    value: 'FX, VT (men)'
                },
                {
                    key: 7,
                    value: 'FX+Music, VT (women)'
                },
                {
                    key: 23,
                    value: 'Blue Floor & Vault'
                },
                { 
                    key: 24, 
                    value: 'Red Floor & Vault'
                },{
                    key: 25,
                    value: 'Green Floor & Vault'
                },
                { 
                    key: 26, 
                    value: 'Yellow Floor & Vault'
                }
            ],
            'CG': [
                {
                    key: 9,
                    value: 'FX,PH, SR, VT, PB, HB, PPP'
                }],
            'EG': [
                {
                    key: 9,
                    value: 'FX,PH, SR, VT, PB, HB, PPP'
                },
                {
                    key: 10,
                    value: 'FX,PH, SR, VT, APP, PB, HB, PPP'
                },
                {
                    key: 15,
                    value: 'FX,PHx2, SR, VTx2, APP, PB, HB, PPP'
                },
                {
                    key: 16,
                    value: 'FX,PHx2, SR, VT, APP, PB, HB, PPP'
                },
                {
                    key: 18,
                    value: 'FX,PHx2, SR, VTx2, PB, HB, PPP'
                },
                {
                    key: 19,
                    value: 'FX,PHx2, SR, VT, PB, HB, PPP'
                }
            ]

        };

    }

    EditGroupController.$inject = ['webAPI', '$routeParams', '$location', 'SystemDisciplines', 'SystemExercises', 'viewstatus'];
    function EditGroupController(api, $routeParams, $location, disciplines, exerciseSets, viewstatus) {
        var vm = this;
        var competition = vm.competition;
        vm.disciplines = disciplines;
        vm.model = angular.copy(vm.group, {});
        vm.setExerciseSets = setExerciseSets;
        vm.save = save;
        vm.reset = reset;
        vm.onDelete = onDelete;

        if (competition) {
            var dates = [];

            var currDate = moment(competition.Start);//.startOf('day');
            var lastDate = moment(competition.End);//.startOf('day');
            dates.push(currDate.clone().toDate());
            while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                dates.push(currDate.clone().toDate());
            }
            if (dates.length === 1) {
                if (!vm.model.EventGroup) {
                    vm.model.EventGroup = {
                        EventId: competition.Id
                    };
                }
                vm.model.EventGroup.EventDate = dates[0];
            }
            vm.dates = dates;
        }

        setExerciseSets();

        function save() {
            var promise;
            viewstatus.startLoading();
            angular.copy(vm.model, vm.group);
            if (vm.group.Id) {
                promise = api.groups.update({ competition: $routeParams.id }, vm.group).$promise.then(finish);
            } else {
                promise = api.groups.save({ competition: $routeParams.id }, vm.group).$promise.then(function (data) {
                    finish(data);
                    $location.path('/manage-competition/' + $routeParams.id + '/group/' + data.GroupId).replace();

                });

            }
            promise.catch(viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);

        }
        function finish(r) {
            viewstatus.create('Group saved successfully', 'success');
            return r;
        }
        function reset() {
            vm.model = angular.copy(vm.group, {});
        }
        function onDelete() {
            $location.path('/manage-competition/' + $routeParams.id).replace();
        }
        var lastCode;
        function setExerciseSets() {
            var discipline;
            if (vm.model.DisciplineId) {
                angular.forEach(vm.disciplines, function (d) {
                    if (d.key === vm.model.DisciplineId) {
                        discipline = d;
                    }
                });
            }


            if (discipline) {
                vm.exerciseSets = exerciseSets[discipline.code];
                if (vm.exerciseSets.length === 1) {
                    vm.model.SetId = vm.exerciseSets[0].key;
                }
                lastCode = discipline.code;
            } else {
                vm.exerciseSets = [];
                lastCode = undefined;
            }


        }

    }
}());