
(function (app) {

    var tariffValues = {

        20: 1, //FFFF
        17: 1, //HFFF
        15: 1, //NFFF
        14: 0.75, //HHFF
        12: 0.75, //NHFF
        11: 0.5, //HHHF
        10: 0.5, //NNFF
        9: 0.5, //NHHF
        8: 0.5, //HHHH
        6: 0.5, //NHHH
        7: 0.25, //NNHF
        4: 0.25 //NNHH
    };


    app
        .constant('tariff', {
            None: 0,
            Half: 2,
            Full: 5
        })
        .constant('tariffValues', tariffValues)
        .filter('tgDCredit', tgDCredit)
        .filter('tgDCreditValue', tgDCreditValue)
        .service('teamGymTolerances', teamGymTolerance);

    var defaultTolerance = 0.2;


    tgDCredit.$inject = ['tariff'];
    function tgDCredit(tariff) {
        return function (n) {
            if (n === undefined || n === null) {
                return '';
            }

            if (+n === tariff.Full) return 'F';
            if (+n === tariff.Half) return 'H';

            return 'N';
        };
    }

    //TODO: This is fixed to a 4 judge Difficulty panel, make it customisable. 
    tgDCreditValue.$inject = ['tariffValues'];
    function tgDCreditValue(tariffValues) {
        return function (judges, tariffId) {
            var total = 0;
            for (var i = 1; i < 5; i++) {
                total += judges['D' + i][tariffId].score || 0;
            }
            return tariffValues[total] || 0;
        };
    }

    function tgDCreditValueArr(scores) {
        var total = scores.reduce(function (s, v) {
            return s + (v.score || 0);
        }, 0);
        return tariffValues[total] || 0;
    }

    teamGymTolerance.$inject = ['entryPosition'];
    function teamGymTolerance(entryPosition) {


        /* 
        TODO:
        
         l = (schema.Average) ? scores.length : 1;
                            var dp;
                            var rounding;
                            if (_isTeamGym) {
                                dp = l === 1 ? 3 : l === 3 ? 1 : 2;
                                rounding = 'floor';
                            }
            
                            score[key] = entryPosition.precision(total / (l || 1), dp, rounding);
                            if (discipline.ShortName.toLowerCase() === 'acro' && schema.Key === 'E') {
                                score[key] = entryPosition.precision(score[key] * 2);
                            }
            
        
        */


        this.checkScores = checkScores;
        this.calculateTariffScores = calculateTariffScores;

        function getByTariffId(key, id) {
            return function (s) {
                return s.type === key && s.tariffid === id;
            };
        }

        function calculateTariffScores(schema, tariffs, judgeScores) {


            //todo: multiple schemas having tariff??
            return tariffs.reduce(function (o, t) {

                var scores = judgeScores.filter(getByTariffId(schema.Key, t.TariffId));
                if (scores.length === schema.NumberOfJudges) {
                    var credit = tgDCreditValueArr(scores);
                    var score = entryPosition.precision(credit * t.Score,2);
                    o.creditedTotal = entryPosition.precision(o.creditedTotal +  score, 2);
                    o[t.TariffId] = {
                        credit: credit,
                        score: score
                    };
                }
                return o;
            }, { creditedTotal: 0 });


        }


        function getAllScoresTolerance(score) {
            if (score > 7.95) {
                return 0.6;
            }
            return 1.0;
        }
        function getMiddleTolerances(score) {
            if (score > 8.95) {
                return 0.2;
            }
            if (score > 7.95) {
                return 0.3;
            }
            if (score > 6.95) {
                return 0.4;
            }
            if (score < 6) {
                return 0.6;
            }
            return 0.5;
        }

        function calculateBaseScore(AverageTwo, Head) {
            return entryPosition.precision((AverageTwo + Head) / 2, 3, 'floor');
        }
        function getScore(judgeScore) {
            return judgeScore.score || 0;
        }
        function getMatchingScore(score) {
            return function (s) {
                return s.isCounted && (s.score || 0) === score;
            };
        }

        function makeScoreCounted(s) {
            s.isCounted = true;
        }


        function scoresWithValue(s) { return !s._deleted && s.score !== undefined && s.score !== null; }
        function scoreIsCounted(s) { return s.isCounted === true; }
        function sortByJudgeNumberDesc(a, b) {
            return b.judgeNumber - a.judgeNumber;
        }
        function sortByJudgeNumber(a, b) {
            return a.judgeNumber - b.judgeNumber;
        }

        function checkScores(judgeScores, numberOfJudges, key, exercise) {

            var result = { scores: judgeScores.sort(sortByJudgeNumber), middleOutOfTolerance: false, outOfTolerance: false };
            if (!exercise) {
                exercise = 'FX';
            }
            if (!numberOfJudges) {
                numberOfJudges = judgeScores.length;
            }
            if (!key) {
                key = 'E';
            }

            var scoresToCheck = judgeScores.filter(scoresWithValue);

            if (scoresToCheck.length !== numberOfJudges) {

                return {
                    scores: scoresToCheck.reduce(function (o, s, i) {
                        s.isCounted = false;
                        o[s.judgeNumber - 1] = s;
                        return o;
                    }, Array.apply(null, Array(numberOfJudges))).map(function (s, i) {
                        if (!s) {
                            return {
                                type: key,
                                judgeNumber: i + 1,
                                score: undefined
                            };
                        }
                        return s;
                    })
                };
            }

            judgeScores.forEach(makeScoreCounted);

            var minValue = Math.min.apply(null, judgeScores.map(getScore));
            var maxValue = Math.max.apply(null, judgeScores.map(getScore));
            var difference = entryPosition.precision(maxValue - minValue);

            if (judgeScores.length === numberOfJudges && numberOfJudges > 3) {
                //check when number of judges is 3 exclude top and bottom or not
                judgeScores.filter(getMatchingScore(maxValue)).sort(sortByJudgeNumberDesc)[0].isCounted = false;
                judgeScores.filter(getMatchingScore(minValue)).sort(sortByJudgeNumberDesc)[0].isCounted = false;
            }

            if (difference === 0) {
                //average would be same
                result.score = minValue;
                result.middleOutOfTolerance = false;
                result.outOfTolerance = false;
                result.baseScore = minValue;
            } else {

                var middleScores = judgeScores.filter(scoreIsCounted).map(getScore);
                var sumMiddleScores = middleScores.reduce(function (result, value) { return result + value; }, 0);

                result.score = entryPosition.precision(sumMiddleScores / middleScores.length, 2, 'floor');

                result.baseScore = result.score;

                if (key === 'E') {
                    if (numberOfJudges > 2) {
                        if (difference > getAllScoresTolerance(result.score)) {
                            // out of tolerance
                            result.outOfTolerance = true;
                        }

                        if (entryPosition.precision(Math.abs(middleScores[0] - middleScores[1]), 2, 'floor') > getMiddleTolerances(result.score)) {
                            //calculate base score
                            result.middleOutOfTolerance = true;
                        }
                    }
                    else {
                        if (entryPosition.precision(Math.abs(middleScores[0] - middleScores[1]), 2, 'floor') > getMiddleTolerances(result.score)) {
                            //calculate base score
                            result.outOfTolerance = true;
                        }
                    }
                }
                else {
                    if (exercise !== 'FX') {
                        if (entryPosition.precision(Math.abs(middleScores[0] - middleScores[1]), 2, 'floor') > defaultTolerance) {
                            result.outOfTolerance = true;
                        }
                    }
                }

                if (result.middleOutOfTolerance || result.outOfTolerance) {
                    result.baseScore = calculateBaseScore(result.score, judgeScores.filter(function (s) { return s.type === key && s.judgeNumber === 1; })[0].score);
                }
            }
            return result;

        }
    }

    app.service('teamPosition', ['$filter', function ($filter) {
        var orderGuests = true;
        var position = 1;
        function precision(score) {
            if (!score) return 0;
            return Math.round(score * 1000) / 1000;
            //return (parseFloat(score.toFixed(3)));
        }

        function ePrecision(score) {
            if (!score) return 0;
            return Math.round(score * 100) / 100;
            //return (parseFloat(score.toFixed(3)));
        }

        function setPosition(s, i, arr) {
            if (s.value === 0) {
                s.order = 0;
                s.index = 0;
                return;
            }
            if (s.guest && !orderGuests) {
                s.order = 0;
                s.index = 0;
                return;
            }
            s.order = position;
            s.index = i / arr.length;
        }
        function filterBScore(b, s) {
            var bScore = b.scores.filter(function (s1) { return s.ExerciseId === s1.ExerciseId; })[0];
            if (bScore) {
                return bScore.Score;
            }
            return 0;
        }

        function calculateWins(a, b) {
            return function (s) {
                var bScore = filterBScore(b, s);
                if (s.Score > bScore) {
                    aWins += 1;
                } else {
                    if (s.Score < bScore) {
                        bWins += 1;
                    }
                }
            };
        }
        function isNotGuest(t) { return !t.guest; }
        function hasScore(s) { return s.hasScore; }
        function notScored(s) { return !s.hasScore; }


        function calculateOrder(scores, splitKey, grp) {

            var positions = _.flatten(splitcollection(scores.filter(hasScore), 'value').map(function (p) {

                var len = p.length;
                var newArr = [];
                function entriesNotInNewArr(entry) { return newArr.indexOf(entry) === -1; }


                if (len > 1) {

                    for (var i = 0; i < len; i++) {
                        var a = p[i];
                        if (newArr.indexOf(a) > -1) {
                            continue;
                        }
                        for (var j = i + 1; j < len; j++) {
                            var b = p[j];
                            if (!b) {
                                continue;
                            }
                            var aWins = 0;
                            var bWins = 0;

                            a.scores.forEach(calculateWins(a, b));
                            if (aWins === 2) {
                                newArr.push(a);
                            }
                            if (bWins === 2) {
                                newArr.push(b);
                            }
                        }
                    }

                    var otherArr = p.filter(entriesNotInNewArr);

                    if (newArr.length > 1) {
                        newArr = split(newArr);
                    }
                    if (otherArr.length > 1) {
                        otherArr = split(otherArr);
                    }
                    return newArr.concat(otherArr);
                }
                return p;
            }));


            positions = _.flatten(positions.map(function (p, i,a) {

                if (p.hasOwnProperty('tie')) {
                    p.tie.forEach(setPosition);
                    // if (orderGuests) {
                    //     position = position + p.tie.length;
                    // } else {
                        position = position + p.tie.filter(isNotGuest).length;
                    // }
                    return p.tie;

                } else {
                    setPosition(p, i, a);
                    if (!p.guest) {
                        position = position + 1;
                    }
                    return p;
                }
            })).concat(_.sortBy(scores.filter(notScored).map(function (s) { s.order = 0; return s; }), 'key'));


            // //1. first highest
            // //2. second highest
            // var maxComparer = function (a, b) {
            //    return Math.max.apply(null, a.Scores.map(s => s.Score)) - Math.max.apply(null, b.Scores.map(s => s.Score));
            // };
            // //3. highest e score
            // //4. second highest e score
            // //5. highest total e score (all three scores)

            return positions;
        }

        function split(collection) {

            return splitcollection(collection, 'highestScore').map(function (highestScore) {

                if (highestScore.length > 1) {
                    return splitcollection(highestScore, 'nextHighestScore').map(function (nextHighestScore) {
                        if (nextHighestScore.length > 1) {
                            return splitcollection(nextHighestScore, 'highestE').map(function (highestE) {
                                if (highestE.length > 1) {
                                    return splitcollection(highestE, 'nextHighestE').map(function (nextHighestE) {
                                        if (nextHighestE.length > 1) {
                                            return splitcollection(nextHighestE, 'totalE').map(function (totalE) {
                                                if (totalE.length > 1) {
                                                    return {
                                                        tie: totalE
                                                    };
                                                } else {
                                                    return totalE;
                                                }
                                            });
                                        }
                                        return nextHighestE;
                                    });
                                }
                                return highestE;
                            });

                        }
                        return nextHighestScore;
                    });
                }
                return highestScore;
            });
        }


        function splitcollection(collection, key) {
            return _.sortBy(_.map(_.groupBy(collection, key)), function (item) { return item[0][key]; }).reverse();
        }

        function sumE(a, r) { return r.E + a; }

        function mapToNewData(entryScores) {
            var scores = _.sortBy(entryScores, 'Score').reverse();
            var eScores = _.sortBy(entryScores, 'E').reverse();
            return {
                highestScore: scores[0].Score,
                nextHighestScore: (scores[1] || { Score: 0 }).Score,
                highestE: eScores[0].E,
                nextHighestE: (eScores[1] || { E: 0 }).E,
                totalE: eScores.reduce(sumE, 0)
            };
        }

        function sumScore(a, v) { return a + (v.Score || 0); }

        function calculate(entries, disc, grp) {
            position = 1;
            var entryHash = {}, groupEntries;

            // loop through all scores to get a total for the exercises the entrant has completed

            groupEntries = entries.map(function (e) {
                var entryOrderModel = Object.assign({
                    key: e.EntryNumber,
                    value: precision(e.Scores.reduce(sumScore, e.SJ)),
                    guest: e.Guest,
                    scores: e.Scores,
                    hasScore: e.Scores.filter(function (s) { return s.HasScore; }).length > 0 || e.SJ !== 0
                },
                    mapToNewData(e.Scores));
                entryHash[e.EntryNumber] = entryOrderModel;
                return entryOrderModel;

            });

            //overall position
            calculateOrder(groupEntries, null, grp); //kp: was splitscore on AA

            return {
                exercisePositions: {},
                groupPositions: entryHash
            };


        }

        return {
            ePrecision: ePrecision,
            precision: precision,
            getPositions: calculateOrder,
            calculate: calculate
        };

    }]);

}(angular.module('app.shared')));