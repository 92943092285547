/**
 * Created by kevinpayne on 01/06/15.
 */
(function(){
    "use strict";

    angular.module('app')
        .service('search', Search);

    Search.$inject = ['webAPI', 'activeCompetition', 'viewstatus'];


    function Search(webAPI, activeCompetition, viewstatus){
        var result = null, self = this;

        this.clear = function(){
            result = null;
            self.bib = undefined;
        };
        this.entry = function(){
            return result;
        };
        this.get = function() {
            if (!self.bib) {
                return;
            }
            if(!result || self.bib !== result.EntryNumber) {
                viewstatus.startLoading();
                activeCompetition.get().$promise.then(function (data) {
                    result = webAPI.entries.get({id: self.bib, competition: data.Id});
                    result.$promise['finally'](viewstatus.stopLoading);
                    result.$promise.catch(function (err) {
                        if (err.status === 404) {
                            viewstatus.create('Entry not found', 'danger', 3000);
                        }
                    });
                });
            }

        };
        this.bib = undefined;
    }

}());