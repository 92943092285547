/**
 * Created by kevinpayne on 08/04/16.
 */
(function(){
    angular.module('app.competitionAdmin')
        .directive('disciplineHeading', discipline);

    discipline.$inject = ['competitionAdminTemplates'];
    function discipline(templateDirectory){
        return {
            templateUrl: templateDirectory('discipline-heading'),
            scope: {
                d: '=discipline',
                competition: '='
            }
        };
    }
}());