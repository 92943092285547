/**
 * Created by kevinpayne on 12/03/16.
 */
(function(){
    angular.module('app.competitionAdmin')
        .directive('activateCompetition', toggleActive);

        toggleActive.$inject = ['viewstatus', 'activeCompetition'];
    function toggleActive(viewstatus, activeCompetition){
        return function(scope, el, attr){

            function clickHandler(e) {

                var model = scope.$eval(attr.activateCompetition);
                scope.$apply(function () {

                    viewstatus.startLoading();
                    model.$setActive(function () {
                        model.Active = !model.Active;
                        if(model.Active) {
                            viewstatus.create(model.Name + ' is now the active competition');
                        }else{
                            viewstatus.create(model.Name + ' is no longer the active competition');
                        }

                        activeCompetition.get().$active()['finally'](viewstatus.stopLoading);


                    }, function () {

                        viewstatus.create('Failed to set ' + model.Name + ' active');
                        viewstatus.stopLoading();
                    });
                });
            }

            el.on('click', clickHandler);

            scope.$on('$destroy', function(){
               el.off('click', clickHandler);
            });
        };
    }
}());