
/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    angular.module('app')
        .service('activeEntry', ActiveEntry);

    ActiveEntry.$inject = ['webAPI', 'entryPosition', 'Store', 'scoreboardTicker', 'judgeStatus', 'teamGymTolerances', 'piecesTariffService', 'tgCoP'];

    function ActiveEntry(webAPI, entryPosition, Store, messaging, judgeStatus, teamGymTolerances, piecesTariffService, cop) {
        var self = this;
        var value, entryScore, cache, exercises;
        var judgingCache = new Store('judgeUICache');

        exercises = {};

        this.status = function () {
            return judgeStatus.status();
        };
        this.get = function () {
            return value;
        };
        this.getExercise = function () {
            return exercises[cache.ExerciseId];
        };
        this.getScore = function () {
            return entryScore;
        };
        this.setScore = function (score) {
            setScore(score);
        };
        this.set = function (entry, score, numberOfJudges) {
            value = entry;
            cache = score;

            if (!exercises[score.ExerciseId]) {
                if (entry.Exercises) {
                    exercises[score.ExerciseId] = angular.copy(getExerciseById(entry, score.ExerciseId));
                    setSchema(score, numberOfJudges);
                } else {
                    exercises[score.ExerciseId] = webAPI.exercise.get({ id: score.ExerciseId },
                        function (e) { setSchema(score, numberOfJudges); return e; });
                }
            } else {
                setSchema(score, numberOfJudges);
            }
        };

        function getExerciseById(entry, exId, defaultValue) {
            return entry.Exercises.find(function (ex) {
                return ex.Id === exId;
            }) || defaultValue || null;
        }

        function setSchema(score, numberOfJudges) {
            var discipline = getDiscipline();
            var exercise = self.getExercise();
            var schema = {};
            var schemaAsArray = [];
            angular.forEach(discipline.Category.Scoring, function (s) {
                schema[s.Key] = s;
            });

            angular.forEach(exercise.Scoring, function (s) {
                schema[s.Key] = s;
            });

            if (numberOfJudges) {
                Object.keys(schema).forEach(function (key) {
                    if (key !== 'Pen') {
                        if (numberOfJudges[key] > 0) {
                            schema[key].NumberOfJudges = numberOfJudges[key];
                        } else {
                            delete schema[key];
                        }
                    }
                });
                if (!schema.E) {
                    delete schema.Pen;
                }
            }
            Object.keys(schema).forEach(function (key) {
                schemaAsArray.push(schema[key]);
            });

            self.schemaAsArray = schemaAsArray.sort(function (a, b) { return a.Order - b.Order; });
            self.schema = schema;

            setScore(angular.copy(score));

        }

        function getDiscipline(d) {
            return d || value && (value.Discipline || value.Group.Discipline) || {};
        }

        function isTeamGym(d) {
            return getDiscipline(d).Category.Description.toLowerCase() === 'teamgym';
        }

        this.setForWithdraw = function (score) {

            var result = angular.copy(score);

            result.DidNotCompete = true;
            result.HasScore = true;
            result.A = 0;
            result.E = 0;
            result.D = 0;
            result.Pen = 0;
            result.A1 = 0;
            result.E1 = 0;
            result.D1 = 0;
            result.Pen1 = 0;
            result.A2 = 0;
            result.E2 = 0;
            result.D2 = 0;
            result.Pen2 = 0;
            result.S1 = 0;
            result.S2 = 0;
            result.Score = 0;
            result.Judging = null;

            angular.forEach(result.JudgeScores, function (j) {
                j.score = j.pass1 = j.pass2 = j.pass3 = 0;
            });
            return result;

        };
        this.clear = function () {
            value = null;
            entryScore = null;
            cache = null;
        };

        this.update = function () {
            angular.copy(entryScore, cache);
        };

        this.updateForm = function (form) {
            angular.forEach(form.$error, function (e) {
                angular.forEach(e, function (control) { control.$setDirty(); });
            });
        };


        this.addScore = addScore;
        this.notifyAddRow = notifyAddRow;
        this.removeScore = removeScore;
        this.total = calculateTotal;
        this.judgeTotal = calculateJudgeScore;
        this.updateDeductions = updateDeductions;
        this.passTotal = calculatePassScore;
        this.schema = {};
        this.updateDeduction = updateDeduction;
        this.updateScore = updateScore;
        this.maxScore = maxScore;
        this.getJudgeScores = getScoresByType;
        this.getTariffs = getTariffs;
        this.getTariffSheetForExercise = getTariffSheetForExercise;


        function getScoresByType(key, scoreModel) {
            return (scoreModel || entryScore).JudgeScores.filter(function (e) { return e.type === key && e._deleted !== true; });
        }
        function addScore(schema, key, silent, value) {
            if (!entryScore.JudgeScores) {
                entryScore.JudgeScores = [];
            }

            var s = self.createScore(schema, key);
            if (value) {
                s.score = value;
                if (schema.Deduct) {
                    updateDeduction(s, schema, entryScore);
                }
            }
            entryScore.JudgeScores.push(s);
            saveUI(schema, key);


            //todo: decide wheather to notify.
            if (!silent) {
                notifyAddRow(entryScore.Id, key, s);
            }
            return s;

        }

        function notifyAddRow(id, key, s) {

            var scoresForType = getScoresByType(key);
            var indexOfScore = scoresForType.indexOf(s);


            messaging.invoke('judgeAddScoreRow', {
                type: key,
                scoreId: id,
                index: indexOfScore,
                expectedRowCount: scoresForType.length
            }).then(function (data) {
                s.identifier = data.score.identifier;
                //todo: compare local scores to remote scores.
                return data;
            });

        }

        function saveUI(schema, key) {
            var numberOfJudges = getScoresByType(key).length;

            var modelId = [self.getExercise().Id, key || schema.Key].join('_');
            var model = judgingCache.find({ id: modelId });
            if (model === null) {
                model = {
                    id: modelId
                };
            }
            model.value = numberOfJudges;
            judgingCache.update(model);
        }
        function removeScore(key, model, idx) {
            var index = entryScore.JudgeScores.indexOf(model);
            if (index > -1) {
                if (model.judgingid) {
                    model._deleted = true;
                } else {
                    entryScore.JudgeScores.splice(index, 1);
                }
                if (idx !== -1) {
                    messaging.invoke('judgeRemoveScoreRow',
                        {
                            type: model.type,
                            scoreId: entryScore.Id,
                            identifier: model.identifier,
                            index: idx,
                            judgingid: model.judgingid,
                        }).then(function (data) {
                            //todo: retrospectively remove score?
                            // check UI has the correct amount of scores
                            return data;
                        });
                }
            }
            saveUI(undefined, key);
        }

        function calculateComponent(schema, score, component) {
            var val = 0;
            var key;

            angular.forEach(schema, function (p) {
                key = p.Key;
                if (component > 0) {
                    key += component;
                }

                if (p.IncludeInTotal) {
                    if (p.Key === 'Pen' && score[key] > 0) {
                        score[key] = score[key] * -1;
                    }
                    val += parseFloat(score[key]) || 0;
                }
            });

            //// rule: deductions cannot remove difficulty score
            //// todo: need to check same for Acro, TeamGym and Tumbling.
            // if(schema.hasOwnProperty('D')) {
            //     if (schema.D.IncludeInTotal) {
            //         if (val < score[key]) {
            //             val = score[key];
            //         }
            //     }
            // }
            if (val < 0) {
                return 0;
            }
            return val;
        }

        function copyComponent(schema, score, component) {
            angular.forEach(schema, function (s) {
                score[s.Key] = score[s.Key + component];
            });
            score.Score = score['S' + component];
        }

        function calculateTotal(schema, score) {
            schema = schema || self.schema;
            score = score || entryScore;

            if (score.Passes > 1) {

                score.S1 = entryPosition.precision(calculateComponent(schema, score, 1));
                score.S2 = entryPosition.precision(calculateComponent(schema, score, 2));

                if (score.Passes === 2) {
                    score.Score = entryPosition.precision((score.S1 + score.S2) / 2);
                    angular.forEach(schema, function (s) {
                        score[s.Key] = entryPosition.precision((score[s.Key + '1'] + score[s.Key + '2']) / 2);
                    });

                } else {

                    if (score.S2 > score.S1) {
                        copyComponent(schema, score, 2);
                    } else {
                        if (score.S1 === score.S2) {
                            copyComponent(schema, score, (score.D1 < score.D2) ? 1 : 2);
                        } else {
                            copyComponent(schema, score, 1);
                        }
                    }

                }

            } else {
                var rounding;
                // if (isTeamGym()) {
                //     rounding = 'floor';
                // }
                score.Score = entryPosition.precision(calculateComponent(schema, score, 0), undefined, rounding);
            }


            return score.Score;
        }

        //redundant or check it works
        function calculatePassScore(schema, score, judgeScore, discipline) {
            var total = 0, i, passes = schema.Passes, mathMethod = 'round';
            if (!schema.Deduct) {
                mathMethod = 'floor';
            }

            for (i = 1; i <= passes; i++) {
                total += (judgeScore['pass' + i] || 0);
            }

            judgeScore.score = Math[mathMethod](entryPosition.precision(total / passes) * 10) / 10;
            calculateJudgeScore(schema, score, schema.Key, discipline);

        }

        function getTariffs() {
            var exercise = self.getExercise();

            var tariffs = self.get().Tariffs.filter(
                function (t) {
                    return t.ExerciseId === exercise.Id && t.Code;
                }
            ).sort(function (a, b) { return a.ExecutionOrder - b.ExecutionOrder; });

            return tariffs;

        }
        function getTariffSheetForExercise() {
            var exercise = self.getExercise();

            var entry = self.get();
            var tariffs;

            if (exercise.ShortName === 'FX') {

                tariffs = entry.Formations.reduce(function (o, f, i) {
                    o.push(Object.assign({},
                        f,
                        {
                            tariffs: entry.Tariffs.filter(function (t) {
                                return t.ExerciseId === exercise.Id && t.RunNumber === f.RunNumber;
                            }).sort(function (t1, t2) {
                                return t1.ExecutionOrder - t2.ExecutionOrder;
                            })
                        }));
                    return o;
                }, []).sort(function (a, b) { return a.RunNumber - b.RunNumber; });
            } else {
                tariffs = entry.Tariffs.map(function (t) {
                    return Object.assign({}, t, {
                        elements: piecesTariffService.convertCodeToElements(t,
                            exercise.ShortName === 'TR',
                            cop[entry.CoP || entry.Group.CoP || entry.Group.Discipline.CoP][exercise.ShortName].D
                        )
                    });
                });
            }
            return tariffs;
        }


        function calculateJudgeScore(schema, score, key, discipline) {
            // make this a service method
            schema = schema || self.schema[key];
            score = score || entryScore;
            discipline = getDiscipline(discipline);
            var _isTeamGym = isTeamGym(discipline);

            var total = 0, l;

            var scores = getScoresByType(key, score);

            if (schema.UseTolerances && _isTeamGym) {
                // judgeScores, numberOfJudges, key, exercise
                var checkScores = teamGymTolerances.checkScores(scores, schema.NumberOfJudges, schema.Key, self.getExercise().ShortName);
                if (checkScores) {
                    if (!score.tolerances) {
                        score.tolerances = {};
                    }
                    score.tolerances[key] = checkScores;
                    score[key] = checkScores.baseScore;

                } else {
                    if (score.tolerances) {
                        score.tolerances[key] = {};
                    }
                    scores.forEach(function (s) { s.isCounted = false; });
                    score[key] = 0;
                }
            } else {
                if (schema.UseTariff) {
                    var credit = teamGymTolerances.calculateTariffScores(schema, getTariffs(), scores);
                    score[key] = credit.creditedTotal;
                }
                else {
                    angular.forEach(scores, function (j) {
                        var temp = j.score;
                        if (temp === undefined) {
                            temp = 0;
                        }
                        //if (j.score) {
                        //if (schema.Deduct && j.deduct) {
                        //    total += (schema.StartValue - Math.abs(temp));
                        //} else {
                        total += parseFloat(temp);
                        //}

                        // }
                    });

                    if (total) {
                        l = (schema.Average) ? scores.length : 1;
                        var dp;
                        var rounding;
                        if (_isTeamGym) {
                            dp = l === 1 ? 3 : l === 3 ? 1 : 2;
                            rounding = 'floor';
                        }

                        score[key] = entryPosition.precision(total / (l || 1), dp, rounding);
                        if (discipline.ShortName.toLowerCase() === 'acro' && schema.Key === 'E') {
                            score[key] = entryPosition.precision(score[key] * 2);
                        }

                    } else {
                        // if (schema.Deduct) {
                        //     score[schema.Key] = schema.StartValue;
                        // } else {
                        score[key] = 0;
                        // }
                    }

                }

            }
            calculateTotal(null, score);
        }

        function updateDeductions(judgeScores, score, Discipline) {
            var schema = Discipline.Category.Scoring.filter(function (s) { return s.Key === 'E'; })[0];
            if (schema) {
                angular.forEach(judgeScores, function (judgeScore) {
                    updateScore(judgeScore, schema, score);
                    calculateJudgeScore(schema, score, schema.Key, Discipline);
                });
            }
        }

        function maxScore(schema, totals) {
            var startValue = schema.Max;
            if (schema.ParentScoreKey) {
                startValue = totals.StartValue;
            }
            return startValue;
        }

        function updateDeduction($event, schema, scoreModel) {
            // make this a service method
            var startValue = maxScore(schema, scoreModel);

            if ($event._score === undefined && $event.score === undefined) {
                return;
            }
            if ($event.score === undefined) {
                $event._score = undefined;
                return;
            }
            var score = Math.min($event.score, startValue) || 0;
            var dp;
            var rounding;
            if (isTeamGym()) {
                var scores = getScoresByType(schema.Key, scoreModel);
                rounding = 'floor';
                if (scores.length > 1) {
                    dp = 1;
                    scores.forEach(function (s) {
                        if (s !== $event) {
                            if (s.score !== undefined) {
                                if (s.score !== entryPosition.precision(s.score, dp, rounding)) {
                                    s.score = entryPosition.precision(s.score, dp, rounding);
                                    s._score = entryPosition.precision(startValue - s.score, dp);
                                }
                            }

                        }
                    });
                } else {
                    if (schema.Key !== 'E') {
                        dp = 1;
                    }
                }
            }
            score = entryPosition.precision(score, dp, rounding);
            $event.score = score;
            $event._score = entryPosition.precision(startValue - score, dp);
        }


        function updateScore($event, schema, score) {
            var startValue = maxScore(schema, score);
            var dp;
            var rounding;
            if (isTeamGym()) {
                var scores = getScoresByType(schema.Key, score);
                rounding = 'floor';
                if (scores.length > 1) {
                    dp = 1;
                    scores.forEach(function (s) {
                        if (s !== $event) {
                            if (s.score !== undefined) {
                                if (s.score !== entryPosition.precision(s.score, dp, rounding)) {
                                    s.score = entryPosition.precision(s.score, dp, rounding);
                                    s._score = entryPosition.precision(startValue - s.score, dp);
                                }
                            }
                        }
                    });
                }
                else {
                    if (schema.Key !== 'E') {
                        dp = 1;
                    }
                }

            }
            if ($event._score === undefined) {
                $event.score = undefined;
                return;
            }
            var deduction = entryPosition.precision(Math.min($event._score, startValue) || 0, dp, rounding);
            $event._score = deduction;
            $event.score = entryPosition.precision(startValue - deduction, dp);
        }

        function setScore(score) {
            var keys = {};
            var exercise = self.getExercise();
            var schema = self.schema;
            var discipline = getDiscipline();

            function findByJudgeNumber(scoresByType, judgeNumber) {
                return scoresByType.find(function (s) {
                    return s.judgeNumber === judgeNumber;
                });
            }

            angular.forEach(score.JudgeScores, function (s) {
                keys[s.type] = s;
                var scoreSchema = schema[s.type.replace(/[1-2]/, '')];
                if (scoreSchema.Deduct) {
                    updateDeduction(s, scoreSchema, score);
                }
            });

            angular.forEach(schema, function (s) {

                var key1 = s.Key;
                var key2 = s.Key + '2';
                var cacheModel;
                var scoreCount;
                var tmp;

                if (s.Active && s.Key !== 'Pen') {

                    if (s.Passes > 1 || s.HasMulti) {

                        if (!s.UseTariff) {
                            if (exercise.Passes > 1) {
                                key1 += '1';
                            }
                            if (!keys.hasOwnProperty(key1)) {
                                tmp = buildScoreAndAddToJudgeScores(s, key1);
                                tmp.judgeNumber = 1;
                            }
                            if (s.NumberOfJudges) {


                                scoresByType = getScoresByType(key1, score);

                                if (scoresByType.length < s.NumberOfJudges) {
                                    scoreCount = 1;
                                    while (scoreCount <= s.NumberOfJudges) {
                                        if (!findByJudgeNumber(scoresByType, scoreCount)) {
                                            tmp = buildScoreAndAddToJudgeScores(s, key1);
                                            tmp.judgeNumber = scoreCount;
                                        }
                                        scoreCount++;
                                    }
                                }


                            } else {
                                cacheModel = judgingCache.find({ id: [exercise.Id, key1].join('_') });
                                if (cacheModel) {
                                    scoreCount = getScoresByType(key1, score).length;
                                    while (scoreCount < cacheModel.value) {
                                        tmp = buildScoreAndAddToJudgeScores(s, key1);
                                        scoreCount++;
                                        tmp.judgeNumber = scoreCount;
                                    }
                                }
                            }
                            if (exercise.Passes > 1) {
                                if (!keys.hasOwnProperty(key2)) {
                                    tmp = buildScoreAndAddToJudgeScores(s, key2, true);
                                    tmp.judgeNumber = 1;
                                }
                                cacheModel = judgingCache.find({ id: [exercise.Id, key2].join('_') });
                                if (cacheModel) {
                                    scoreCount = getScoresByType(key2, score).length;
                                    while (scoreCount < cacheModel.value) {
                                        tmp = buildScoreAndAddToJudgeScores(s, key2);
                                        scoreCount++;
                                        tmp.judgeNumber = scoreCount;
                                    }
                                }
                            }
                        }

                        calculateJudgeScore(s, score, key1, discipline);
                        if (exercise.Passes > 1) {
                            calculateJudgeScore(s, score, key2, discipline);
                        }
                    }
                }
            });
            entryScore = score;
            if (entryScore.DidNotCompete) {
                entryScore.HasScore = false;
                entryScore.DidNotCompete = false;
            }


            function buildScoreAndAddToJudgeScores(schema, key, setScore) {
                var tmp_score = self.createScore(schema, key);
                if (setScore) {
                    tmp_score.score = score[key];
                }
                score.JudgeScores.push(tmp_score);
                return tmp_score;
            }


        }

    }

    ActiveEntry.prototype.createScore = function (schema, key) {
        return {
            score: undefined,
            _score: undefined,
            exerciseId: this.getExercise().Id,
            entryId: this.get().Id,
            type: key || schema.Key,
            pass1: undefined,
            pass2: undefined,
            pass3: undefined,
            isCounted: true
            // deduct: schema.Deduct
        };
    };


}());