/**
 * Created by kevinpayne on 05/03/15.
 */
(function(app){
    'use strict';
    angular.module('app')
        .directive('login', login)
        .directive('logOut', logOut)
        .directive('showLoggedIn', showLoggedIn);


    login.$inject = ['webAPI', 'user' ];
    function login(webAPI, user){


        return {

            // todo: change user service and those using it to be:
            // {data: new user, tea: tea object}
            // toto:


            link: function (scope, el, attr) {
                function login() {
                    scope.viewstatus.startLoading();
                    user.$authorize(function (data) {
                       // $window.sessionStorage.setItem('token',  [data.id, data.key].join(':'));

                        scope.$eval(attr.login);
                        return data;
                    }, scope.viewstatus.displayWebApiError)['finally'](scope.viewstatus.stopLoading);

                }

                el.on('submit', login);
                scope.$on('$destroy', function(){
                    el.off('submit', login);
                });

            }
        };

    }
    showLoggedIn.$inject = ['user'];
    function showLoggedIn(user) {
        return function (scope, el) {
            check();
            scope.$watch(function () {
                return user.name;
            }, function () {
                check();
            });
            scope.$on('$routeChangeSuccess', check);
            function check() {
                if (!user.name) {
                    el.hide();
                } else {

                    el.show();

                }
            }
        };
    }

    logOut.$inject = ['$templateCache', 'webAPI', 'routes', '$location', 'user'];
    function logOut($templateCache, webAPI, routes, $location, user){
        return function(scope, el){

            check();

            el.on('click', function () {
                webAPI.user.logout().$promise.then(function(){
                    $templateCache.remove('/templates/dashboard');
                    $location.url(routes.buildRoute('login'));
                    window.sessionStorage.removeItem('token');
                    window.localStorage.setItem('getToken', 'logout');
                    user.clear();
                    check();
                });
            });
            el.on('$destroy', function () {
                el.off('click');
            });
            scope.$on('$routeChangeSuccess', check);

            function check() {
                if (!user.name) {
                    el.hide();
                } else {

                    el.show();

                }
            }

        };
    }


}());