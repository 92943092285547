/**
 * Created by kevinpayne on 27/03/15.
 */
/**
 * Created by kevinpayne on 28/01/15.
 */
/**
 * Created by kevinpayne on 28/01/15.
 */
(function (app) {
    'use strict';

    angular.module("app")
        .directive('calcAveragePass', ['entryPosition', function (entryPosition) {
        return function (scope, el, attr) {
            var model = scope.$eval(attr.calcAveragePass);
            scope.$watch(function () {
                var sum = (parseFloat(model.pass1, 10) + parseFloat(model.pass2, 10) + parseFloat(model.pass3, 10));
                if (sum) {
                    return entryPosition.precision( sum / 3);
                }
                return 0;

            }, function (newvalue) {
                model.score = newvalue;
            });

        };

    }]);

}());
