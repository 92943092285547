/**
 * Created by kevinpayne on 29/05/15.
 */
(function(){

    angular.module('app')
        .directive('listGroupStatus',listGroupStatus);

    function listGroupStatus(){
        return function(scope, el, attr){
            var element = el.get(0);
            scope.$watch(attr.listGroupStatus, function(newvalue){
                element.className = element.className.replace(/ list-group-item-\w+/,'');
                switch(newvalue){
                    case 'scoring':
                        element.className += ' list-group-item-warning';
                        break;
                    case 'go':
                        element.className += ' list-group-item-success';
                        break;
                    case 'stop':
                        element.className += ' list-group-item-danger';
                        break;
                }
            });
        };
    }
}());