/**
 * Created by kevinpayne on 23/02/15.
 */
(function(app){
    app.service('scoreCache',['Store', function(Store){
        var localCache = {};

        function getCache(eventId) {
            localCache[eventId] = localCache[eventId] || new Store('scores-' + eventId);

            return localCache[eventId];

        }

        return {


            pending: function (score) {

                this.done(angular.extend({},score,{_pending: true}));
            },
            done: function(score){
                var entry, cache;
                cache = getCache(score.EventId);
                entry = cache.find({id: score.EntryNumber});
                if (!entry) {
                    entry = {id: score.EntryNumber, exercises: {}};
                }
                entry.exercises[score.ExerciseId] = score;
                cache.update(entry);
            },
            removeEntry: function(score) {
                var cache, entry;
                cache = getCache(score.EventId);
                entry = cache.find({id: score.EntryNumber});
                if(entry) {
                    //todo: destroy exercise score or whole entry??
                    cache.destroy(entry);
                }

            },
            removeScore: function(score, exercise){
                var cache = getCache(exercise.EventId);
                delete score.exercises[exercise.ExerciseId];
              if(  angular.equals({}, score.exercises)){
                  cache.destroy(score);
              }else{
                  cache.update(score);
              }

            },
            findAllPendingScores: function(eventId){
                return getCache(eventId).findAll();
            }

        };


    }]);
}(angular.module('app')));