/**
 * Created by kevinpayne on 27/03/15.
 */
(function () {
    'use strict';
    angular.module('app')
        .controller('WithdrawController', WithdrawController)
        .controller('WithdrawnEntriesController', WithdrawnEntriesController);

    WithdrawController.$inject = ['webAPI', 'search'];

    function WithdrawController(webAPI, search) {
        var vm = this;
        vm.entry = search.entry;
    }
    WithdrawnEntriesController.$inject = ['entries', 'scoreboardTicker', '$scope', '$route', 'activeRotation'];
    function WithdrawnEntriesController(entries, messaging, $scope, $route, activeRotation) {
        var vm = this;
        vm.entries = entries;
        vm.refresh = refresh;
        vm.rounds = {};
        vm.activeRotation = activeRotation;

        angular.forEach(entries, function (entry) {
            if (!vm.rounds.hasOwnProperty(entry.Scores[0].round)) {
                vm.rounds[entry.Scores[0].round] = [];
            }
            vm.rounds[entry.Scores[0].round].push(entry);
        });

        var withdrawn = messaging.on('withdrawn', function (data) {
            var found = false;
            angular.forEach(entries, function (e) {
                if (e.Id === data.id) {
                    e.Withdrawn = data.value;
                    found = true;
                }
            });
            if (!found) {
                vm.needsUpdate = true;
            }
        });

        $scope.$on('$destroy', function () {
            messaging.off('withdrawn', withdrawn);
        });
        function refresh() {
            $route.reload();
        }
    }


}());