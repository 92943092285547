/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    angular
        .module('app')
        .directive('judgePanel', judgePanel)
        .directive('tariffsheet', tariffsheet)
        .directive('tumblesheet', tumbleSheet)
        //.directive('judgeAddTariffScore', judgeAddTariffScore)
        .directive('judgeAddSingleScore', judgeAddSingleScore)
        .directive('judgeAddMultiScore', judgeAddMultiScore)
        .directive('judgeAddSinglePassScore', judgeAddSinglePassScore)
        .directive('judgeAddMultiPassScore', judgeAddMultiPassScore)
        .directive('judgeStartValue', judgeStartValue)
        .directive('judgeWatcher', judgeWatcher)
        .directive('judgeSnitch', judgeSnitch)
        .directive('judgeDisableScroll', judgeDisableScroll)
        .controller('tariffController', tariffController)
        .controller('JudgePanelController', JudgePanelController);

    judgePanel.$inject = ['routes'];
    function judgePanel(routes) {
        return {
            controller: 'JudgePanelController',
            controllerAs: 'jp',
            templateUrl: routes.templateDirectory('judge-panel')
        };
    }
    tariffsheet.$inject = ['routes'];
    function tariffsheet(routes) {
        return {
            scope: {
                formations: '='
            },
            templateUrl: routes.templateDirectory('judging/tariff-sheet')
        };
    }
    tumbleSheet.$inject = ['routes'];
    function tumbleSheet(routes) {
        return {
            scope: {
                tariffs: '=',
                isMicro: '='
            },
            templateUrl: routes.templateDirectory('judging/tumble-sheet')
        };
    }


    JudgePanelController.$inject = ['$scope', 'activeEntry', 'viewstatus', 'judgeStatus', 'activeJudges', 'user'];
    function JudgePanelController($scope, activeEntry, viewstatus, judgeStatus, activeJudges, user) {
        var jp = this;
        jp.msgInput = '';

        $scope.entry = activeEntry.get();
        $scope.exercise = activeEntry.getExercise();
        $scope.score = activeEntry.getScore();
        $scope.close = close;
        $scope.formations = activeEntry.getTariffSheetForExercise();
        jp.judges = activeJudges.getList();
        $scope.send = function (msgInput) {
            if (msgInput) {
                activeJudges.sendMessage(msgInput);
                jp.msgInput = '';
            }
        };
        $scope.user = user;
        if (user.keyFields) {

            $scope.judgeFields = (user.keyFields).split(',');
        } else {
            $scope.judgeFields = [];
        }
        $scope.showMessages = false;
        function getFieldMatchingKey(key) {
            return function (element) {
                return element.indexOf(key) === 0;
            };
        }
        if ($scope.judgeFields.length > 0) {
            activeEntry.schemaAsArray.forEach(function (schema) {
                if ($scope.showMessages) return;
                if (schema.HasMulti) {
                    if ($scope.judgeFields.filter(getFieldMatchingKey(schema.Key)).length < schema.NumberOfJudges) {
                        $scope.showMessages = true;
                    }
                }
            });
        }

        $scope.$watch('exercise.Name', function (newvalue) {
            if (newvalue) {
                setTitle();
            }
        });

        $scope.$watch(function () { return jp.judges.messages.length; }, function (newValue) {
            if (newValue > 0) {
                $scope.showMessages = true;
            }
            setTimeout(function () {
                var element = angular.element('#scrollToMessage');
                if (element.length) {
                    element[0].scrollIntoView(false);
                }
            });
        });

        $scope.$on('$destroy', function () {
            activeEntry.clear();
            judgeStatus.clear();
            activeJudges.clear();
        });

        function setTitle() {
            viewstatus.title = "Judge " + $scope.exercise.Name + ": [" + $scope.entry.EntryNumber + "] " + ($scope.entry.TeamName || $scope.entry.EntryTitle);

        }

        function close() {
            activeEntry.clear();
            judgeStatus.clear();
            activeJudges.clear();
        }
    }

    judgeAddSingleScore.$inject = ['routes'];
    function judgeAddSingleScore(routes) {
        return {
            templateUrl: routes.templateDirectory('judge-add-single-score')
        };
    }

    tariffController.$inject = ['$scope', '$modalInstance', 'tariffs', 'judgeScores', 'user', 'tariff', '$filter', 'scoreboardTicker'];
    function tariffController($scope, $modalInstance, tariffs, judgeScores, user, tariffCredit, $filter, ws) {
        $scope.headJudge = user.headJudge;
        $scope.judgeField = (user.keyFields || '').split(',').filter(function (f) { return f.indexOf('D') === 0; })[0];

        $scope.tariffs = tariffs;
        $scope.tariffCredit = tariffCredit;
        $scope.expectedD = tariffs.reduce(function (a, v) { return a + v.Score; }, 0);
        $scope.scoreSubmitted = false;
        $scope.saveTariffScores = sendDScores;

        $scope.close = function () {
            $modalInstance.close();
        };

        var list = [0, 2, 5];
        var judges = {
            D1: { submitted: false },
            D2: { submitted: false },
            D3: { submitted: false },
            D4: { submitted: false }
        };

        tariffs.reduce(function (o, v) {
            var i;
            for (i = 1; i < 5; i++) {
                var key = 'D' + i;
                o[key][v.TariffId] = {
                    score: undefined,
                    type: key,
                    tariffid: v.TariffId,
                    scoreid: judgeScores.scoreId
                };
            }
            return o;
        }, judges);

        judgeScores.scores.forEach(function (s) {
            judges[s.type][s.tariffid].score = s.score;
            judges[s.type][s.tariffid].judgingId = s.judgingId;
        });

        $scope.judges = judges;
        $scope.canSetDScore = false;

        ws.on('judgeTariffSheet', updateJudges);

        function updateScores(tariff) {
            calculateScores();
        }

        function sendDScores() {
            var data = {
                scoreId: judgeScores.scoreId,
                type: $scope.judgeField[0],
                judgeNumber: $scope.judgeField[1],//todo: check this
                scores: tariffs.map(function (t) {

                    return judges[$scope.judgeField][t.TariffId];

                })
            };
            ws.invoke('judgeTariffSheet', data);
            $scope.scoreSubmitted = true;
        }

        function updateJudges(d) {
            d.scores
                .filter(function (s) { return s.type !== 'D' && s.type.indexOf('D') === 0; })
                .forEach(function (s) {
                    judges[s.type].submitted = true;
                    judges[s.type][s.tariffid] = s;
                });

        }

        function calculateScores() {

            if (judges.submitted) {
                $scope.creditedTotal = 0;
                $scope.credits = tariffs.reduce(function (o, t) {
                    var credit = $filter('tgDCreditValue')(judges, t.TariffId);
                    $scope.creditedTotal += credit * t.Score;
                    o[t.TariffId] = {
                        credit: credit,
                        score: credit * t.Score
                    };
                    return o;
                }, {});

            }
        }
    }


    judgeAddMultiScore.$inject = ['routes'];
    function judgeAddMultiScore(routes) {
        return {
            templateUrl: routes.templateDirectory('judge-add-multi-score-v2')
        };
    }

    judgeStartValue.$inject = ['routes'];
    function judgeStartValue(routes) {
        return {
            templateUrl: routes.templateDirectory('judge-sv')
        };
    }

    judgeAddSinglePassScore.$inject = ['routes'];
    function judgeAddSinglePassScore(routes) {
        return {
            templateUrl: routes.templateDirectory('judge-add-single-pass-score')
        };
    }
    judgeAddMultiPassScore.$inject = ['routes'];
    function judgeAddMultiPassScore(routes) {
        return {
            templateUrl: routes.templateDirectory('judge-add-multi-pass-score')
        };
    }

    function judgeDisableScroll() {
        return {
            link: function (scope, e, attr) {


                function judgeEnter($event) {
                    $($event.target).on('wheel.disableScroll', function (e) { e.preventDefault(); });
                }
                function judgeExit($event) {
                    $($event.target).off('wheel.disableScroll');
                }


                e.on('focus.judgeWatcher', judgeEnter);
                e.on('blur.judgeWatcher', judgeExit);

                scope.$on('$destroy', function () {
                    e.off('focus.judgeWatcher', judgeEnter);
                    e.off('blur.judgeWatcher', judgeExit);
                });
            }
        };
    }


    judgeWatcher.$inject = ['activeJudges', 'activeEntry', 'user'];
    function judgeWatcher(activeJudges, activeEntry, user) {
        var dataSet = activeJudges.getList();

        return {
            scope: {
                judgeWatcher: '='
            },
            require: 'ngModel',
            link: function (scope, e, attr, ngModel) {

                scope.$watch(function () {
                    return dataSet.list.filter(function (j) {
                        return dataSet.data[j].field === attr.name;
                    })[0];
                }, function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue) {
                        attr.$set('style', 'border: 1px solid ' + dataSet.data[newValue].color);
                    } else {
                        attr.$set('style', '');
                    }
                });

                function judgeEnter($event) {
                    $($event.target).on('wheel.disableScroll', function (e) { e.preventDefault(); });
                    activeJudges.focus($event);
                }
                function judgeExit($event) {
                    $($event.target).off('wheel.disableScroll');
                    activeJudges.blur($event);
                }
                var lastValue;
                function getValue($event) {
                    var value = $event.target.valueAsNumber;
                    if (!value && value !== 0) {
                        value = $event.target.value; //shouldn't happen
                        if (isNaN(value)) value = 0;
                    }
                    return value;
                }
                function keyChange($event) {
                    var value = getValue($event);
                    if (lastValue !== value) {
                        lastValue = value;
                        var values = scope.judgeWatcher;

                        var data = {
                            key: values.key,
                            index: values.index,
                            value: value,
                            field: $event.target.name
                        };
                        activeJudges.change(data);
                    }
                }
                function judgeChange($event) {
                    var value = getValue($event);
                    if (lastValue !== value) {
                        lastValue = value;
                        var values = scope.judgeWatcher;
                        var judgeScore;
                        if (values.score) {
                            judgeScore = Object.assign({}, values.score);
                            delete judgeScore.$$hashKey;
                        }

                        var data = {

                            judgeScore: judgeScore,
                            index: values.index,
                            value: value,
                            field: $event.target.name
                        };
                        if (judgeScore) {
                            activeJudges.judgeChange(data, function (d) {
                                if (d === -1) {
                                    //removed?
                                } else {
                                    if (!values.score.identifier) {
                                        values.score.identifier = d;
                                    }
                                }
                            });
                        }
                    }
                }
                e.on('focus.judgeWatcher', judgeEnter);
                e.on('blur.judgeWatcher', judgeExit);
                if (scope.judgeWatcher.score) {
                    e.on('keyup.judgeWatcher', judgeChange);
                }
                if (scope.judgeWatcher.key) {
                    e.on('keyup.judgeWatcher', keyChange);
                }

                // var subscriber = activeJudges.subscribeToChanges(function (data) {
                //     if (data.id === activeEntry.getScore().Id) {
                //         if (data.field === attr.name) {
                //             // ngModel.$setViewValue(data.value);
                //             var score = scope.judgeWatcher.score;
                //             if (score) {
                //                 if (!score.identifier) {
                //                     score.identifier = data.identifier;
                //                 }
                //             }
                //             e.val(data.value);
                //             e.triggerHandler('input');
                //         }
                //     }
                // });
                scope.$on('$destroy', function () {
                    e.off('focus.judgeWatcher', judgeEnter);
                    e.off('blur.judgeWatcher', judgeExit);
                    e.off('keyup.judgeWatcher');
                    // subscriber();
                });
            }

        };

    }

    judgeSnitch.$inject = ['activeJudges', 'activeEntry'];
    function judgeSnitch(activeJudges, activeEntry) {


        return {
            require: 'form',
            link: function (scope, e, attr, ngForm) {
                scope.$watch(function () { return ngForm.$dirty; }, function (newValue, oldValue) {
                    if (newValue === true) {
                        setTimeout(function () {
                            var changedFields = [];
                            var allowedKeys = activeEntry.schemaAsArray.map(function (s) { return s.Key; });

                            for (var key in ngForm) {
                                if (key.startsWith('$'))
                                    continue;
                                if (ngForm[key].$dirty && allowedKeys.indexOf(key[0]) > -1) {
                                    changedFields.push(key);
                                }
                            }

                            activeJudges.snitch(activeEntry.getScore(), changedFields);
                        });

                    }

                });
            }
        };
    }


}());