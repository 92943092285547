(function (app) {
    'use strict';
    app.service('Store', [
        function () {

            // Generate four random hex digits.
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }

            // Generate a pseudo-GUID by concatenating random hexadecimal.
            function guid() {
                return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
            }

            var store = window.Store = function (name) {
                this.name = name;
                var _store = this.localStorage().getItem(this.name);
                this.records = (_store && _store.split(",")) || [];
            };

            angular.extend(store.prototype, {

                // Save the current state of the **Store** to *localStorage*.
                save: function () {
                    this.localStorage().setItem(this.name, this.records.join(","));
                },
                clearDown: function () {
                    var self = this;
                    _.each(this.records, function (r) {
                        self.localStorage().removeItem(self.name + "-" + r);
                    });
                    this.records.length = 0;
                    this.save();
                },
                // Add a model, giving it a (hopefully)-unique GUID, if it doesn't already
                // have an id of it's own.
                create: function (model) {
                    if (!model.id) {
                        model.id = guid();
                    }
                    this.localStorage().setItem(this.name + "-" + model.id, JSON.stringify(model));
                    this.records.push(model.id.toString());
                    this.save();
                    return this.find(model);
                },

                // Update a model by replacing its copy in `this.data`.
                update: function (model) {
                    if (!model.id) {
                        return this.create(model);
                    }
                    this.localStorage().setItem(this.name + "-" + model.id, JSON.stringify(model));
                    if (!_.include(this.records, model.id.toString()))
                        this.records.push(model.id.toString());
                    this.save();
                    return this.find(model);
                },
                first: function () {
                    if (this.records.length > 0) {
                        return this.jsonData(this.localStorage().getItem(this.name + '-' + this.records[0]));
                    }
                    return undefined;
                },
                // Retrieve a model from `this.data` by id.
                find: function (model) {
                    return this.jsonData(this.localStorage().getItem(this.name + "-" + model.id));
                },

                // Return the array of all models currently in storage.
                findAll: function () {
                    return _(this.records).chain()
                        .map(function (id) {
                            var obj = this.jsonData(this.localStorage().getItem(this.name + "-" + id));
                            obj.id = id;
                            return obj;
                        }, this)
                        .compact()
                        .value();
                },

                // Delete a model from `this.data`, returning it.
                destroy: function (model) {
                    if (!model) {
                        return undefined;
                    }
                    //if (model.isNew())
                    //    return false
                    this.localStorage().removeItem(this.name + "-" + model.id);
                    this.records = _.reject(this.records, function (id) {
                        return id === model.id.toString();
                    });
                    this.save();
                    return model;
                },
                count: function () {
                    return this.records.length;
                },
                local: {
                    getItem: function () {
                    }, setItem: function () {
                    }
                },
                localStorage: function () {
                    if (!window.localStorage) {
                        return this.local;
                    }
                    return localStorage;
                },

                // fix for "illegal access" error on Android when JSON.parse is passed null
                jsonData: function (data) {
                    return data && JSON.parse(data);
                }
            });
            return store;

        }]);


}(angular.module('app.shared')));

