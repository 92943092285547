/**
 * Created by kevinpayne on 23/04/16.
 */
(function () {
    "use strict";
    angular.module('app.competitionAdmin')
        .directive('saveButton', saveButton)
        .directive('deleteButton', deleteButton)
        .controller('buttonController', ButtonController);

    ButtonController.$inject = ['viewstatus', '$window', 'webAPI', '$routeParams'];
    function ButtonController(viewstatus, $window, api, $routeParams) {
        var vm = this, vs = viewstatus;
        vm.save = save;
        vm.remove = remove;
        if (vm.viewstatus) {
            vs = vm.viewstatus;
        }


        function save() {
            var promise, requireSave;
            startLoading();
            if (vm.model.Id) {
                if (vm.model.$update) {
                    promise = vm.model.$update();
                } else {
                    if (vm.resource) {
                        if (api[vm.resource].hasOwnProperty('update')) {
                            promise = api[vm.resource].update(undefined, vm.model).$promise;
                        } else {
                            requireSave = true;
                        }
                    }
                }
            }
            if (!vm.model.Id || requireSave) {
                if (vm.model.$save) {
                    promise = vm.model.$save();
                } else {
                    if (vm.resource) {
                        promise = api[vm.resource].save(undefined, vm.model).$promise;
                    }
                }
            }
            promise.then(function (data) {
                if (vm.confirm) {
                    viewstatus.create(vm.confirm + ' saved successfully', 'success');
                }
                success(data);
                return data;
            }).catch(fail)['finally'](stopLoading);
        }
        function remove() {

            if (vm.model.Id) {
                if (vm.confirm) {
                    if ($window.confirm('Are you sure you want to delete ' + vm.confirm)) {
                        doDelete();
                    }

                } else {
                    doDelete();
                }


            } else {
                success(vm.model);
            }


        }
        function doDelete() {
            startLoading();


            if (vm.model.$delete) {
                vm.model.$delete(success, fail)['finally'](stopLoading);
            } else {
                api[vm.type].delete(vm.model, success, fail).$promise['finally'](stopLoading);
            }

        }

        function success(r) {
            (vm.update || angular.noop)(vm.model, r);
            return r;
        }
        function fail(err) {
            (vm.fail || angular.noop)(vm.model, err);
            vs.displayWebApiError(err);
        }

        function startLoading() {
            vm.loading = true;
            vs.startLoading();
        }
        function stopLoading() {
            vm.loading = false;
            vs.stopLoading();
        }

    }

    function saveButton() {
        return {
            scope: {
                model: '=',
                viewstatus: '=',
                update: '=',
                fail: '='
            },
            controller: 'buttonController',
            controllerAs: 'button',
            bindToController: true,
            link: function (scope, el, attr, ctrl) {
                el.on('click', function () {
                    scope.$apply(ctrl.save);
                });
                scope.$on('$destroy', function () {
                    el.off('click');
                });
            }
        };
    }

    function deleteButton() {
        return {
            scope: {
                model: '=',
                viewstatus: '=',
                update: '=',
                fail: '=',
                confirm: '@',
                eventId: '@',
                resource: '@'
            },
            controller: 'buttonController',
            controllerAs: 'button',
            bindToController: true,
            link: function (scope, el, attr, ctrl) {
                el.on('click', function () {
                    scope.$apply(ctrl.remove);
                });
                scope.$on('$destroy', function () {
                    el.off('click');
                });
            }
        };
    }

}());