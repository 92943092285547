(function (app) {
    app.directive('status', function () {
        return {
            restrict: 'A',
            scope: {
                statuses: '=status'
            },
            controller:['$scope', '$route', function ($scope, $route) {
                $scope.location = $route.current.originalPath;
            }],
            template: '<alert ng-repeat="status in statuses" type="{{status.type}}" close="status.close()" ng-if="statuses.length > 0"  ><span ng-bind="status.msg"></span></alert>'

        };
    });
}(angular.module('app.UI')));