/**
 * Created by kevinpayne on 12/03/16.
 */
(function(){
    "use strict";
    angular.module('app.competitionAdmin')
        .controller('CreateCompetitionController', CreateCompetitionCtrl);

        CreateCompetitionCtrl.$inject = ['competition', 'disciplines'];
    function CreateCompetitionCtrl(competition, disciplines){
        var vm = this;
        vm.model = competition;
        vm.disciplines = disciplines;
    }

}());