/**
 * Created by kevinpayne on 28/01/15.
 */

(function () {
    'use strict';
    //needed: Entry
    //        Score for exercise
    //        Exercise

    angular.module("app")
        .controller('TariffPackController', TariffPackController);


    TariffPackController.$inject = ['webAPI', '$routeParams', 'viewstatus', 'teamGymTolerances', 'exercises', 'rounds', 'piecesTariffService', 'tgCoP'];
    function TariffPackController(webAPI, $routeParams, viewstatus, teamGymTolerances, exercises, rounds, piecesTariffService, cop) {
        var vm = this;
        vm.rounds = rounds;
        vm.exerciseList = exercises;
        vm.entries = [];
        vm.generateReport = generateReport;

        webAPI.groups.forCompetition({ competition: $routeParams.id }).$promise.then(function (g) {
            vm.groups = g.reduce(function (o, a) {
                o[a.Id] = a;
                return o;
            }, {});
        });
        function sortByProperty(p) {
            return function (a, b) {
                return a[p] - b[p];
            };
        }
        function generateReport(withScores) {
            if (!vm.exerciseId) {
                viewstatus.create('Exercise is required');
                return;
            }
            vm.loading = true;
            webAPI.tariffs[withScores ? 'getPackWithScores' : 'getPack']({
                id: $routeParams.id,
                round: vm.round || 'all',
                exercise: vm.exerciseId
            }).$promise.then(function (result) {
                vm.loading = false;
                vm.entries = result.map(function (entry) {


                    var result = {
                        EntryTitle: entry.EntryTitle,
                        CoP: entry.CoP || entry.Discpline.CoP,
                        Discipline: entry.Discipline,
                        GroupId: entry.GroupId,
                        EntryNumber: entry.EntryNumber,
                        Exercise: entry.Scores[0].Exercise,
                        Scores: entry.Scores[0],
                        JudgeScores: (entry.Scores[0].JudgeScores || []).reduce(function (o, v) {
                            if (!v.tariffId) {

                                (o[v.type] = o[v.type] || {})[v.judgeNumber] = v;
                            }
                            return o;
                        }, {}),
                    };
                    if (result.Exercise.ShortName === 'FX') {
                        var tariffs = entry.Tariffs.filter(function (t) {
                            return t.Code;
                        }).sort(sortByProperty('ExecutionOrder'));
                        result.Tariffs = tariffs.map(function (t) {

                            return Object.assign({}, t, {
                                JudgeScores:
                                    (result.Scores.JudgeScores || [])
                                        .filter(function (j) {
                                            return j.tariffid === t.TariffId;
                                        }).reduce(function (o, t) {
                                            o[t.judgeNumber] = t;
                                            return o;
                                        }, {})
                            });

                        });
                        result.TariffScores = teamGymTolerances.calculateTariffScores({ Key: 'D', NumberOfJudges: 4 }, tariffs, result.Scores.JudgeScores || []);
                        result.Formations = entry.Formations
                            .sort(sortByProperty('RunNumber'))
                            .map(function (formation) {
                                return Object.assign({}, formation, {
                                    tariffs: entry.Tariffs.filter(function (t) {
                                        return t.RunNumber == formation.RunNumber;
                                    }).sort(sortByProperty('ExecutionOrder'))
                                });
                            });
                    } else {
                        result.Tariffs = entry.Tariffs.map(function (t) {
                            return Object.assign({}, t, {
                                elements: piecesTariffService
                                    .convertCodeToElements(t,
                                        result.Exercise.ShortName === 'TR',
                                        cop[result.CoP][result.Exercise.ShortName].D)
                            });
                        });
                    }
                    return result;

                });
            });

        }


    }
}());
