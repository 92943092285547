/**
 * Created by kevinpayne on 01/03/16.
 */
(function(){
    angular.module('app')
        .controller('ManageUserController', manageCtrl);

    manageCtrl.$inject = ['users', 'locations', 'viewstatus', 'webAPI', '$modal'];
    function manageCtrl(users, locations, viewstatus, webAPI, $modal){
        var vm = this;
        vm.users = users;
        vm.update = update;
        vm.remove = remove;
        vm.locations = locations;
        vm.setPassword = openSetPassword;

        function remove(u) {
            if (confirm(['Are you sure you want to delete ', u.UserName, '?'].join(''))) {
                viewstatus.startLoading();
                u.$delete(function () {
                    users.splice(users.indexOf(u), 1);
                    viewstatus.create([u.UserName, 'was deleted'].join(' '), 'success');
                }, viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);
            }
        }

        function update(u){
            viewstatus.startLoading();
            u.$save(function(){
                viewstatus.create([u.UserName, 'was updated'].join(' '), 'success');
            }, viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);
        }



        function openSetPassword(u){

            return $modal.open({
                templateUrl: '/templates/users/set-password.html',
                controller: ['$scope', '$modalInstance',  function ($scope, $modalInstance) {
                    $scope.user = u;
                    $scope.password = '';
                    $scope.setPassword = function setPassword(){
                        viewstatus.startLoading();
                        var usr = new webAPI.user({UserId: u.UserId, Password: $scope.password});
                        usr.$password(function(){
                            viewstatus.create([u.UserName, 'password changed'].join(' '), 'success');
                            $modalInstance.close();
                        }, viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);
                    };

                }]
            });

        }

    }

}());