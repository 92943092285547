
/**
 * Created by kevinpayne on 07/04/16.
 */
(function () {
    angular.module('app.competitionAdmin')
        .controller('EditEntriesController', EditEntriesController)
        .controller('EditEntryController', EditEntryController);

    EditEntryController.$inject = ['clubs', 'disciplines', 'competition', '$routeParams', 'webAPI', 'entry', '$location', 'viewstatus'];
    function EditEntryController(clubs, disciplines, competition, $routeParams, api, entry, $location, viewstatus) {
        var vm = this;
        var dMap, gMap, xMap;
        vm.disciplines = disciplines;


        vm.compare = function (expected, actual) {
            return expected.indexOf(actual.toUpperCase()) === 0;
        };

        vm.modelOptions = {
            debounce: {
                default: 250,
                blur: 250
            },
            getterSetter: true
        };



        dMap = {};
        vm.groups = [];
        gMap = {};
        xMap = {};

        vm.model = angular.copy(entry);
        if (entry.DoB) {
            var dateParts = entry.DoB.split('T')[0].split('-');
            vm.model.DoB = {
                d: dateParts[2],
                m: dateParts[1],
                y: +dateParts[0]
            };
        } else {
            vm.model.DoB = {
                m: undefined,
                d: undefined,
                y: undefined
            };
        }
        vm.entry = entry;
        vm.setExercises = setExercises;
        vm.setGroups = setGroups;
        vm.clubs = clubs;
        vm.save = save;
        vm.reset = reset;
        vm.rotations = {};
        vm.competition = competition;
        vm.groupDefined = !!$routeParams.grp;
        vm.entryDeleted = entryDeleted;
 
 
        angular.forEach(disciplines, function (d) {
            dMap[d.Id] = d;
            angular.forEach(d.Groups, function (g) {
                gMap[g.Id] = g;
                if (g.Id === vm.model.GroupId) {
                    vm.initialGroup = g;
                }
            });
        });

        if ($routeParams.dis) {
            vm.DisciplineId = parseInt($routeParams.dis, 10);
            setGroups();
        } else {
            if (vm.model.GroupId) {
                vm.DisciplineId = gMap[vm.model.GroupId].DisciplineId;
                //     api.groups.get({ id: vm.model.GroupId }).$promise.then(function (g) {
                //         vm.DisciplineId = g.DisciplineId;
                setGroups();
                //         return g;
                //     });
            }

        }
        if (vm.model.GroupId) {
            setExercises();

        }
        setRotations();

        // if ($routeParams.grp) {
        //    // vm.group = gMap[parseInt($routeParams.grp, 10)];
        //    // vm.discipline = dMap[vm.group.DisciplineId];
        // } else {
        //     vm.group = {
        //         EventId: parseInt($routeParams.id, 10)
        //     };
        // }

        function setGroups() {
            if (!vm.DisciplineId) {
                vm.groups = [];
                return;
            }
            if (dMap[vm.DisciplineId]) {
                vm.groups = dMap[vm.DisciplineId].Groups;

            } else {
                // vm.groups = dMap[vm.DisciplineId] = api.groups.forCompetitionAndDiscipline({ id: vm.DisciplineId, competition: $routeParams.id });
            }
        }

        function setExercises() {
            if (!vm.model.GroupId) {
                vm.exercises = [];
                return;
            }
            if (xMap[vm.model.GroupId]) {
                vm.exercises = xMap[vm.model.GroupId];
            } else {
                vm.exercises = xMap[vm.model.GroupId] = api.exercise.forGroup({ grp: vm.model.GroupId });
            }
 
        }
        function setRotations() {
            angular.forEach(vm.model.Scores, function (s) {
                vm.rotations[s.ExerciseId] = s;
            });
        }
        function save(form) {
            viewstatus.startLoading();

            var promise;
            var data = {
                Id: vm.model.Id,
                AgeBonus: vm.model.AgeBonus || 0,
                ClubId: vm.model.ClubId,
                CompetitionId: vm.model.CompetitionId,
                EntryNumber: vm.model.EntryNumber,
                EntryTitle: vm.model.EntryTitle,
                TeamName: vm.model.TeamName,
                TeamCount: vm.model.TeamCount,
                GroupId: vm.model.GroupId,
                Guest: vm.model.Guest || false,
                Withdrawn: vm.model.Withdrawn || false,
            };

            data.DoB = [vm.model.DoB.y, vm.model.DoB.m, vm.model.DoB.d].join('-');
            data.Scores = [];
            angular.forEach(vm.exercises, function (ex) {

                data.Scores.push({
                    ExerciseId: ex.Id,
                    Round: vm.rotations[ex.Id].Round,
                    Rotation: vm.rotations[ex.Id].Rotation,
                    StartOrder: vm.rotations[ex.Id].StartOrder
                });
            });
             

            if (entry.Id) {
                promise = api.entries.update({ competition: $routeParams.id }, data).$promise.then(function () {
                    // angular.copy(data, entry);
                    finish();
                });
            } else {
                promise = api.entries.save({ competition: $routeParams.id }, data).$promise.then(function (data) {
                    finish();
                    var path = '/manage-competition/' + data.CompetitionId;
                    if (entry.GroupId) {
                        path += '/group/' + vm.model.GroupId;
                    }
                    $location.path(path).replace();
                });
                //redirect to competition listing or edit view?
            }
            promise.then(function (r) {
                return r;
            }).catch(viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);
        }
        function finish() {
            viewstatus.create(['Entry', vm.model.EntryNumber, 'was saved successfully'].join(' '), 'success');

        }
        function reset() {
            angular.copy(entry, vm.model);
            if (entry.DoB) {
                var dateParts = entry.DoB.split('T')[0].split('-');
                vm.model.DoB = {
                    d: dateParts[2],
                    m: dateParts[1],
                    y: +dateParts[0]
                };
            } else {
                vm.model.DoB = {
                    m: undefined,
                    d: undefined,
                    y: undefined
                };
            }
            setExercises();
            setRotations();
        }

        function entryDeleted() {
            $location.path(['/manage-competition', entry.CompetitionId, 'group', entry.GroupId].join('/')).replace();
        }

    }
    EditEntriesController.$inject = ['entrants', 'SystemDisciplines', 'group', 'competition'];
    function EditEntriesController(entrants, disciplines, group, competition) {
        var vm = this;
        vm.entries = entrants;

        vm.isTeamGym = isTeamGym();

        vm.entryDeleted = entryDeleted;
        vm.group = group;
        vm.competition = competition;

        function isTeamGym() {
            var result = false;
            angular.forEach(disciplines, function (d) {
                if (d.key === group.DisciplineId) {

                    if (d.type === 'TeamGym') {
                        result = true;
                    }
                    vm.discipline = d;
                }

            });

            // if (result === false) {
            //     entry._showGymnasts = false;
            // }
            return result;
        }

        function entryDeleted(entry, response) {
            var idx = entrants.indexOf(entry);
            if (idx > -1) {
                entrants.splice(idx, 1);
            }
        }
    }
}());
/*
TG difficulty requirements

DONE: DS must be marked agains a difficulty element (just one)
DONE: DS can be a Leap or Hop
DONE: Acro starting and ending on one leg can be marked as DS (the ones I found I added to DS allowed elements array)

DONE: F & G cannot be DS so hide radio button. (as mentioned using DS allowed elements array for visibility or radio)
DONE: There is no half for a group element

DONE: there should be a line marking DD (elements completed before 1min 30seconds). For an empty form, its added after the third element. the line cannot be moved above three elements because the rule says there should be three elements before 1:30

Validations to add: Are these required, or is it up to the coach to get this right. Can show warnings?
Must have HB1001
Must have one SB
must have one DB/SB/HB in addition to the previous HB & SB
No element must be the same
Cannot use more than one variants of the same element (e.g. A1001A means cannot have A1001B or "C)
1 f element
1 g element
 


check code for any missed rules

 */