/**
 * Created by kevinpayne on 17/03/15.
 */
(function (app) {
    'use strict';

    angular.module('app')
        .directive('upNext', upNext)
        .directive('justGone', justGone);

    upNext.$inject = justGone.$inject = ['routes'];

    function upNext(routes){
        return {
            scope:{
                upNext:'='
            },
            templateUrl: routes.templateDirectory('watch/up-next')
        };
    }

    function justGone(routes){
        return {
            scope:{
                justGone:'='
            },
            templateUrl: routes.templateDirectory('watch/just-been')
        };
    }

}());