(function (app) {

    app.directive('blurOnEnter', function () {
        return function (scope, el) {
            el.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    e.target.blur();
                }
            });
        };
    });

}(angular.module('app.UI')));
