
/**
 * Created by kevinpayne on 23/02/16.
 */
(function () {

    angular.module('app')
        .controller('ActiveUsersController', ActiveUsersController);

    ActiveUsersController.$inject = ['$scope', 'scoreboardTicker', 'user', 'helpModalService'];
    function ActiveUsersController($scope, ws, user, modal) {
        //  var clientPushHubProxy = signalRHubProxy(signalRHubProxy.defaultServer, 'messagePushHub', { logging: true }); //receives.

        var clientListener = ws.on("Users", updateClients);
        var deviceListener = ws.on("Identities", updateDevices);

        $scope.user = user;
        $scope.clients = [];
        $scope.devices = [];
        $scope.setMessenger = setMessenger;


        $scope.$on('$destroy', function () {
            ws.off('Users', clientListener);
            ws.off('Identities', deviceListener);
        });

        ws.invoke('GetUsers', '');
        ws.invoke('GetIdentities', '');

        function noSelections() {
            var result = _.indexOf(_.values($scope.recipients), true) === -1;
            return result;
        }


        function updateClients(data) {

            $scope.clients = data || [];
        }
        function updateDevices(data) {
            $scope.devices = data || [];
        }

        // function sendMessage(recipients) {
        //     var data;
        //     if (recipients) {
        //         data = angular.extend({ recipients: getRecipients() }, $scope.msg);
        //         ws.invoke('MessageUsers', data);
        //     } else {
        //         ws.invoke('MessageUsers', $scope.msg);
        //     }
        // }
        function setMessenger(client) {
           modal.open([client], client.name);
        }

    }
}());