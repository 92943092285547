(function () {
    'use strict';
    angular.module('app')
        .controller('ReportController', ReportController)
        .controller('ExecutionReportController', ExecutionController)
        .controller('TopScorersReportController', TopScores)
        .controller('PeterTrophyController', PeterTrophyController)
        .controller('ParentReportController', ReportController);

    TopScores.$inject = ['$scope', 'webAPI', 'activeCompetition', 'gymnasts', 'entryPosition'];
    function TopScores($scope, webAPI, activeCompetition, gymnasts, entryPosition) {
        $scope.loading = true;
        $scope.gymnasts = returnGymnasts;

        $scope.groupPositionsOrdering = groupPositionOrdering;
        $scope.scorePositionOrdering = getScore;
        $scope.calculate = calculate;
        $scope.activeCompetition = activeCompetition;
        $scope.isMedal = isMedal;
        $scope.$watch(function () {
            return activeCompetition.data.Id;
        }, function (newValue) {
            if (newValue) {
                webAPI.reports.query({ competition: activeCompetition.data.Id, rnd: 'all' }, function (r) {
                    $scope.loading = false;
                    $scope.report = r;
                });
            }
        });
        function returnGymnasts(entry) {
            if (entry.EntryTitle) {
                return entry.EntryTitle;
            }
            return gymnasts(entry.Gymnasts);
        }

        function groupPositionOrdering(group) {

            return function (a) {
                var pos = group.positions[a.EntryNumber];
                var order = pos.order ||  group.Entries.length;
                var idx = pos.index || 0;
                
                return order + idx;

            };
        }
        function getScore(a) {
            return -$scope.groupPositions[a.EntryNumber].value;
        }

        function calculate(group, discipline) {
            var data;

            data = entryPosition.calculate(group.Entries, discipline, group);
            group.positions = data.groupPositions;

            data = undefined;
        }

        function isMedal(group, entry, medal) {

            if (entry.Guest) {
                return false;
            }
            var entryPos = group.positions[entry.EntryNumber] || {};
            return medal === entryPos.order && entryPos.value > 0;
        }

    }

    ReportController.$inject = ['$scope', 'webAPI', 'reportSettings', 'activeCompetition', 'subs', 'rounds', 'disciplines', 'groups'];
    function ReportController($scope, webAPI, reportSettings, activeCompetition, subs, rounds, disciplines, groups) {
        $scope.reportSettings = reportSettings;
        $scope.breakDiscipline = true;

        $scope.subs = subs;
        $scope.rounds = rounds;
        $scope.disciplinesList = disciplines;
        $scope.groupsList = groups;
        $scope.activeCompetition = activeCompetition;

        $scope.filterByRound = function (m) {
            if ($scope.round) {
                return $scope.rounds[$scope.round].Categories.indexOf(m.Category.CategoryId) > -1;

            } else {
                return true;
            }
        };

        $scope.filterByDiscipline = function (m) {
            if ($scope.disciplineId) {
                return (m.DisciplineId || m.Id) === $scope.disciplineId;
            } else {
                return true;
            }
        };
        $scope.filterByGroup = function (m) {
            if ($scope.groupId) {
                return m.GroupId === $scope.groupId;
            } else {
                return true;
            }
        };

        $scope.$watch('disciplineId', function (newvalue) {
            if (!newvalue) {
                $scope.groupId = newvalue;
            } else {
                $scope.groupId = null;
            }
        });


        $scope.generateReport = function () {

            $scope.loading = true;

            if ($scope.date) {
                webAPI.reports.queryByDate({ date: $scope.date, competition: activeCompetition.data.Id }, function (r) {
                    $scope.loading = false;
                    $scope.report = r;
                });
                return;
            }


            webAPI.reports.query({ id: $scope.disciplineId, competition: activeCompetition.data.Id, grp: $scope.groupId, rnd: $scope.round || 'all' }, function (r) {
                $scope.loading = false;
                $scope.report = r;
            });


            $scope.$on('$destroy', function () {
                reportSettings.hideScores = false;
                reportSettings.hideDEScores = false;
                reportSettings.hideExScores = false;
            });



            //todo: api for all scores
            //todo: add date filter [all, day 1, day 2]
            //todo: find out what other reports are needed.



            //todo: medal/trophy winners report?

        };

        $scope.generateSubReport = function () {
            $scope.loading = true;
            webAPI.reports.queryBySub({
                competition: activeCompetition.data.Id,
                sub: $scope.subComp,
                rnd: $scope.round || 'all'
            },
                function (r) {
                    $scope.loading = false;
                    $scope.report = r;
                });
        };

        $scope.generateParentReport = function () {
            $scope.loading = true;
            webAPI.reports.queryByParent({
                competition: activeCompetition.data.ParentEvent
            },
                function (r) {
                    $scope.loading = false;
                    $scope.report = r;
                });
        };

    }

    ExecutionController.$inject = ['$scope', 'webAPI', 'activeCompetition', 'rounds', 'disciplines', 'groups'];
    function ExecutionController($scope, webAPI, activeCompetition, rounds, disciplines, groups) {

        $scope.rounds = rounds;
        $scope.disciplinesList = disciplines;
        $scope.groupsList = groups;
        $scope.activeCompetition = activeCompetition;

        $scope.filterByRound = function (m) {
            if ($scope.round) {
                return $scope.rounds[$scope.round].Categories.indexOf(m.Category.CategoryId) > -1;

            } else {
                return true;
            }
        };

        $scope.filterByDiscipline = function (m) {
            if ($scope.disciplineId) {
                return (m.DisciplineId || m.Id) === $scope.disciplineId;
            } else {
                return true;
            }
        };
        $scope.filterByGroup = function (m) {
            if ($scope.groupId) {
                return m.GroupId === $scope.groupId;
            } else {
                return true;
            }
        };

        $scope.$watch('disciplineId', function (newvalue) {
            if (!newvalue) {
                $scope.groupId = newvalue;
            } else {
                $scope.groupId = null;
            }
        });


        $scope.generateReport = function () {

            $scope.loading = true;

            webAPI.reports.queryExecutions({ id: $scope.disciplineId, competition: activeCompetition.data.Id, grp: $scope.groupId, rnd: $scope.round || 'all' }, function (r) {
                $scope.loading = false;
                $scope.report = r;
            });
        };
        $scope.generateParentReport = function () {
            $scope.loading = true;
            webAPI.reports.queryExecutionsByParent({
                competition: activeCompetition.data.ParentEvent
            },
                function (r) {
                    $scope.loading = false;
                    $scope.report = r;
                });
        };

    }

    PeterTrophyController.$inject = ['$scope', 'webAPI', 'activeCompetition', 'gymnasts', 'entryPosition'];
    function PeterTrophyController($scope, webAPI, activeCompetition, gymnasts, entryPosition) {
        $scope.loading = true;
        $scope.gymnasts = returnGymnasts;

        $scope.groupPositionsOrdering = groupPositionOrdering;
        $scope.scorePositionOrdering = getScore;
        $scope.calculate = calculate;
        $scope.activeCompetition = activeCompetition;
        $scope.isMedal = isMedal;
        var clubHash = {};
        $scope.clubs = [];

        $scope.$watch(function () {
            return activeCompetition.data.Id;
        }, function (newValue) {
            if (newValue) {
                webAPI.reports.query({ competition: activeCompetition.data.Id, rnd: 'all' }, function (r) {
                    $scope.loading = false;
                    r.forEach(function (d) {
                        d.Groups.forEach(function (g) {
                            calculate(g, d);
                        });
                    });
                    for (var c in clubHash) {
                        $scope.clubs.push(clubHash[c]);
                    }
                });
            }
        });
        function returnGymnasts(entry) {
            if (entry.EntryTitle) {
                return entry.EntryTitle;
            }
            return gymnasts(entry.Gymnasts);
        }

        function groupPositionOrdering(group) {

            return function (a) {
                var pos = group.positions[a.EntryNumber];
                var order = pos.order || group.Entries.length;
                var idx = pos.index || 0;

                return order + idx;
            };
        }
        function getScore(a) {
            return -$scope.groupPositions[a.EntryNumber].value;
        }

        function calculate(group, discipline) {
            var data = entryPosition.calculate(group.Entries, discipline, group);
            group.Entries.map(function (e) {
                if (!clubHash.hasOwnProperty(e.ClubId)) {
                    clubHash[e.ClubId] = {
                        club: e.Club,
                        points: 0
                    };
                }
                if (!e.Guest) {
                    clubHash[e.ClubId].points += getScoreValue(data.groupPositions[e.EntryNumber].order);
                }
            });
        }
        function getScoreValue(v) {
            switch (v) {
                case 1:
                    return 12;
                case 2:
                    return 10;
                case 3:
                    return 8;
                case 4:
                    return 6;
                case 5:
                    return 4;
                case 6:
                    return 2;
                default:
                    return 0;
            }
        }

        function isMedal(group, entry, medal) {

            if (entry.Guest) {
                return false;
            }
            var entryPos = group.positions[entry.EntryNumber] || {};
            return medal === entryPos.order && entryPos.value > 0;
        }
    }

}());