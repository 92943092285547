/**
 * Created by kevinpayne on 29/05/15.
 */
(function(){
   angular.module('app')
       .service('judgeStatus', judge);

    judge.$inject = ['sendStatus'];
    function judge  (sendStatus) {

        var status;
        var statusType = {
                'go': 'go',
                'stop': 'stop',
                'scoring': 'scoring'
            };

        var judgeStatus = {
            statusType: statusType,
            status: function () {
                return status;
            },
            go: function (entry, ex) {
                status = statusType.go;
                send(entry, ex);
            },
            stop: function (entry, ex) {
                status = statusType.stop;
                send(entry, ex);
            },
            scoring: function (entry, ex) {
                status = statusType.scoring;
                send(entry, ex);
            },
            clear: function () {
                status = undefined;
            },
            set: function(v){
                status = v;
            }
        };
        function send(entry, ex){
            if(entry && ex){
                sendStatus(entry, ex, status);
            }
        }

        return judgeStatus;
    }
}());