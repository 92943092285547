(function () {



    var app = angular.module('app', ['ngRoute',
        'app.shared',
        'app.UI',
        'app.API',
        'ui.bootstrap',
        'app.competitionAdmin'
    ]);

    app.run(['$rootScope', '$window', '$timeout', 'viewstatus', 'routes', '$location', 'search','activeEntry', '$templateCache', 'user',
        function ($rootScope, $window, $timeout, viewstatus, routes, $location, search, activeEntry, $templateCache, user) {

            var lastUrl;
            var session = $window.sessionStorage || {removeItem: angular.noop, getItem: angular.noop};
            var local = $window.localStorage || {getItem: noop, setItem: noop};
            angular.element($window).on("storage", function(e){
                var event = e.originalEvent;
                if(event.key === 'getToken'){
                    if(!event.newValue){
                        return;
                    }
                    if(event.newValue === 'logout'){
                        $templateCache.remove('/templates/dashboard');
                        session.removeItem('token');
                        lastUrl = $location.url();
                        $location.url('/login');
                        $rootScope.$apply();
                        return;
                    }
                    if(event.newValue === 'login' && !session.getItem('token')) {
                        local.setItem('getToken', 'get');
                        return;
                    }

                    if(event.newValue === 'get'){
                        local.setItem('getToken', session.getItem('token'));
                    } else{
                        if(event.newValue.indexOf(':') > -1) {
                            session.setItem('token', event.newValue);
                            local.removeItem('getToken');
                            checkLoginPage();
                        }
                    }
                }
            });
            if(!session.getItem('token') || session.getItem('token') === "null"){
                local.setItem('getToken', 'get');
            } else{
                checkLoginPage();
            }

            function checkLoginPage(){
                if($location.url() === '/login'){
                    if(lastUrl && lastUrl !== '/login'){
                        $location.url(lastUrl);
                    } else {
                        $location.url("/");
                    }
                    $rootScope.$apply();
                }
            }


            $rootScope.$on('$routeChangeStart', function (e, curr) {

                if (curr.$$route && curr.$$route.resolve) {
                    // Show a loading message until promises are not resolved
                    viewstatus.startLoading();


                }
                search.clear();
                activeEntry.clear();
                viewstatus.clear();


            });
            $rootScope.$on('$routeChangeSuccess', function (e, curr, prev) {
                // Hide loading message
                viewstatus.stopLoading();
                viewstatus.title = curr.locals.title;
            });

            $rootScope.$on('$routeChangeError', function (e, curr, prev, reason) {
                viewstatus.stopLoading();
                if (reason.status === 401) {
                    //clear user
                    user.clear();
                    user.cache();
                    $location.url(routes.login);
                    return;
                } else {
                    if (reason.status === 404 || reason.status === 403) {
                        $location.url(routes.notfound);
                        return;

                    }
                    viewstatus.displayWebApiError(reason);
                }


            });
            $rootScope.viewstatus = viewstatus;

        }]);

    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

       //do this for hawk: $httpProvider.interceptors.push('requestInterceptor');
    }]);

}());
