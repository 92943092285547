/**
 * Created by kevinpayne on 02/09/16.
 */

//todo: music needs to sort out dropdown order.
//Sussex champs 2017

(function () {
    angular.module('app')
        .controller('ListController', ListArtisticController)
        .controller('MusicListController', MusicListController);


    ListArtisticController.$inject = ['$routeParams', 'webAPI', 'activeCompetition', 'scoreboardTicker', 'viewstatus', 'activeRotation', 'activeEntry', 'exercise', '$scope', 'Store'];
    function ListArtisticController($routeParams, webApi, activeCompetition, messaging, viewstatus, activeRotation, activeEntry, exercise, $scope, Store) {
        var vm = this;
        var statusCache = new Store('statusListCache');
        var entries = [];

        vm.round = undefined;
        vm.rounds = [];
        vm.rotations = [];
        vm.activeRotation = activeRotation;
        vm.highlight = highlightScore;


        vm.activeEntry = activeEntry;
        vm.exerciseName = exercise.Name;
        vm.getRotationEntries = getRotationEntries;
        vm.rotationFilter = undefined;
        vm.roundFilter = undefined;


        var msgHandleJudgeStatus = messaging.on('judgeStatus', handleJudgeStatus);
        var msgEntryScore = messaging.on('entryScore', handleEntryScore);
        var msgCorrection = messaging.on('correction', handleEntryScoreCorrection);
        var onWithdraw = messaging.on('withdrawn', updateEntryWithdrawStatus);
        var onRegistered = messaging.on('registered', updateEntryRegisteredStatus);
        var onApparatus = messaging.on('apparatusInfo', clearApparatusStatuses);

        $scope.$on('$destroy', function () {
            if (msgEntryScore) {
                messaging.off('entryScore', msgEntryScore);
            }
            if (msgCorrection) {
                messaging.off('correction', msgCorrection);
            }
            if (msgHandleJudgeStatus) {
                messaging.off('judgeStatus', msgHandleJudgeStatus);
            }
            if (onWithdraw) {
                messaging.off('withdrawn', onWithdraw);
            }
            if (onRegistered) {
                messaging.off('registered', onRegistered);
            }

            if (onApparatus) {
                messaging.off('apparatusInfo', onApparatus);
            }
        });


        if ($routeParams.category) {
            activeRotation.categoryRotations($routeParams.category.toLowerCase()).then(function (data) {
                vm.rotationInfo = data;
                return data;
            });
        }

        setTitle();

        function getRotationEntries(round) {
            if (round) {

                setTitle(round);

                vm.round = undefined;
                vm.rounds = [];
                vm.rotations = [];

                activeCompetition.get().$promise.then(function (competition) {

                    webApi
                        .entryScore
                        .getList({
                            exercise: exercise.Name,
                            round: round,
                            competition: competition.Id
                        })
                        .$promise
                        .then(function (data) {
                            entries = data;
                            vm.round = {};

                            angular.forEach(data, function (score) {
                                var m = statusCache.find({ id: score.Id });
                                if (m) {
                                    score.highlight = m.highlight;
                                    score.status = m.status;
                                }


                                if (!vm.round.hasOwnProperty(score.Rotation)) {
                                    vm.round[score.Rotation] = [];
                                    vm.rotations.push(score.Rotation);
                                }
                                vm.round[score.Rotation].push(score);
                            });
                            vm.rotationCount = vm.rotations.length;
                            vm.rotations = vm.rotations.sort(function (a, b) {
                                return a - b;
                            });

                            buildDropdown();


                            return data;
                        });

                    return competition;

                });

            }

        }

        function sortByStartOrder(s1, s2) {
            return s1.StartOrder - s2.StartOrder;
        }

        function sortByGroup(s1, s2) {
            if (s1.Entry.Group.Order === s2.Entry.Group.Order) {
                return 0;
            }
            if (s1.Entry.Group.Order > s2.Entry.Group.Order) {
                return 1;
            }
            return -1;
        }
        function sortByEntryNumber(s1, s2) {
            return s1.EntryNumber - s2.EntryNumber;
        }
        function fieldSorterOptimized(fields) {
            var dir = [], i, l = fields.length;
            return function (a, b) {
              for (i = 0; i < l; i++) {
                var o = fields[i](a, b);
                if(o !== 0){
                    return o;
                }
              }
              return 0;
            };
          }


        function buildDropdown() {

            angular.forEach(vm.round, function (rotation, key) {
                var active = [];
                var inactive = [];
                var slice;
                
                angular.forEach(rotation, function (score) {
                    if (score.Entry.Withdrawn) {
                        inactive.push(score);
                    } else {
                        active.push(score);
                    }
                });
                active = active.sort(fieldSorterOptimized([sortByStartOrder, sortByEntryNumber]));

                if (active.length > 2 && active[0] && active[0].Entry.Group.Discipline.DropDown) {
                    if (active.length >= key) {
                        slice = active.splice(0, key - 1);
                    } else {
                        var diff = key - active.length - 1;
                        slice = active.splice(0, diff);
                    }
                    vm.round[key] = [].concat(active, slice, inactive); //.concat(slice, inactive);
                }
                else {
                    vm.round[key] = [].concat(active, inactive); //active.concat(inactive);
                }

                // var slice = active.sort(sorter).splice(0, key - 1);
                //  vm.round[key] = active.concat(inactive); //.sort(sorter));
            });
        }

        function setTitle(s) {
            viewstatus.title = vm.exerciseName + ' List' + (s ? ', Round' + s : '');
        }
        function highlightScore(score) {
            if (score.correction) {
                score.correction = false;
            } else {
                score.highlight = !score.highlight;
            }
            updateCachedScore(score);

        }

        function updateCachedScore(score) {
            var scoreModel = statusCache.find({ id: score.Id }) || { id: score.Id };
            scoreModel.highlight = score.highlight;
            scoreModel.status = score.status;
            if (scoreModel.highlight || scoreModel.status) {
                statusCache.update(scoreModel);
            } else {
                if (!scoreModel.status) {
                    statusCache.destroy(scoreModel);
                }
            }
        }


        function handleJudgeStatus(data) {
            var score = findScore(data.entryId, data.exerciseId);

            if (score) {
                score.status = data.status;
                updateCachedScore(score);
            }

        }
        function clearApparatusStatuses(data) {
            if (!data) {
                angular.forEach(entries, function (e) {
                    if (e.status) {
                        e.status = null;
                        updateCachedScore(e);
                    }
                });
            }
        }

        function findScore(entryId, exId) {
            var score = null;
            angular.forEach(entries, function (entryScore) {
                if (score) {
                    return;
                }
                if (entryScore.EntryId === entryId) {
                    if (entryScore.ExerciseId === exId) {
                        score = entryScore;
                        return;
                    }
                }
            });
            return score;
        }

        function handleEntryScoreCorrection(data) {
            handleEntryScore(data, true);
        }

        function handleEntryScore(data, correction) {

            var score = findScore(data.EntryId, data.Exercise.Id);

            if (score) {

                if (correction) {
                    if (score.Score !== data.Score) {
                        score.correction = true;
                    }
                }

                score.D = data.D;
                score.E = data.E;
                score.A = data.A;
                score.Pen = data.Pen;
                score.Score = data.Score;
                score.DidNotCompete = data.DidNotCompete;
                score.HasScore = data.HasScore;



                if (score.HasScore) {
                    handleJudgeStatus({ entryId: data.EntryId, exerciseName: data.Exercise.Name, exerciseId: data.Exercise.Id, status: '' });
                }
            }


        }
        function updateEntryWithdrawStatus(data) {

            angular.forEach(entries, function (score) {
                var e = score.Entry;
                if (e.Id === data.id) {
                    e.Withdrawn = data.value;
                    e.Registered = true;
                }
            });
            buildDropdown();
        }
        function updateEntryRegisteredStatus(data) {
            angular.forEach(entries, function (score) {
                var e = score.Entry;
                if (e.Id === data.id) {
                    e.Registered = data.value;
                }
            });
        }



    }

    MusicListController.$inject = ['entries', 'activeRotation', 'scoreboardTicker', '$scope', 'Store'];
    function MusicListController(entries, activeRotation, messaging, $scope, Store) {
        var vm = this;
        var rounds = {};
        vm.entries = rounds;
        vm.activeRotation = activeRotation;
        vm.highlight = highlightScore;
        vm.count = countKeys;
        vm.rounds = [];

        var msgHandleJudgeStatus = messaging.on('judgeStatus', handleJudgeStatus);
        var msgEntryScore = messaging.on('entryScore', handleEntryScore);
        var msgCorrection = messaging.on('correction', handleEntryScore);
        var onWithdraw = messaging.on('withdrawn', updateEntryWithdrawStatus);
        var onApparatus = messaging.on('apparatusInfo', clearApparatusStatuses);

        var statusCache = new Store('statusCache');

        angular.forEach(entries, function (score) {
            var m = statusCache.find({ id: score.Id });
            if (m) {
                score.highlight = m.highlight;
                score.status = m.status;
            }

            //if(!entryHash.hasOwnProperty(score.Entry.EntryId)) {
            //    entryHash[score.EntryId] = {Entry: score.Entry, Scores: []};
            //}
            //entryHash[score.EntryId].Scores.push(score);
            var round;
            if (!rounds.hasOwnProperty(score.Round)) {
                rounds[score.Round] = {};
                vm.rounds.push(score.Round);
            }
            round = rounds[score.Round];
            if (!round.hasOwnProperty(score.Rotation)) {
                round[score.Rotation] = [];
            }
            round[score.Rotation].push(score);
        });


        $scope.$on('$destroy', function () {
            if (msgEntryScore) {
                messaging.off('entryScore', msgEntryScore);
            }
            if (msgCorrection) {
                messaging.off('correction', msgCorrection);
            }
            if (msgHandleJudgeStatus) {
                messaging.off('judgeStatus', msgHandleJudgeStatus);
            }
            if (onWithdraw) {
                messaging.off('withdrawn', onWithdraw);
            }

            if (onApparatus) {
                messaging.off('apparatusInfo', onApparatus);
            }

        });

        function highlightScore(score) {
            score.highlight = !score.highlight;
            updateCachedScore(score);

        }

        function updateCachedScore(score) {
            var scoreModel = statusCache.find({ id: score.Id }) || { id: score.Id };
            scoreModel.highlight = score.highlight;
            scoreModel.status = score.status;
            if (scoreModel.highlight || scoreModel.status) {
                statusCache.update(scoreModel);
            } else {
                if (!scoreModel.status) {
                    statusCache.destroy(scoreModel);
                }
            }
        }


        function handleJudgeStatus(data) {
            var score = findScore(data.entryId, data.exerciseName);

            if (score) {
                score.status = data.status;
                updateCachedScore(score);
            }

        }
        function clearApparatusStatuses(data) {
            if (!data) {
                angular.forEach(entries, function (e) {
                    if (e.status) {
                        e.status = null;
                        updateCachedScore(e);
                    }
                });
            }
        }

        function findScore(entryId, exerciseName) {
            var score = null;
            angular.forEach(entries, function (entryScore) {
                if (score) {
                    return;
                }
                if (entryScore.EntryId === entryId) {
                    if (entryScore.Exercise.Name === exerciseName) {
                        score = entryScore;
                        return;
                    }
                }
            });
            return score;
        }

        function handleEntryScore(data) {
            var score = findScore(data.EntryId, data.Exercise.Name);

            if (score) {
                score.D = data.D;
                score.E = data.E;
                score.A = data.A;
                score.Pen = data.Pen;
                score.Score = data.Score;
                score.DidNotCompete = data.DidNotCompete;
                score.HasScore = data.HasScore;

                if (score.HasScore) {
                    handleJudgeStatus({ entryId: data.EntryId, exerciseName: data.Exercise.Name, status: '' });
                }
            }


        }
        function updateEntryWithdrawStatus(data) {

            angular.forEach(entries, function (score) {
                var e = score.Entry;
                if (e.Id === data.id) {
                    e.Withdrawn = data.value;

                }
            });

        }
        function countKeys(round) {
            return Object.keys(round).length;
        }



    }


}());