(function (app) {

    app.service('entryPosition', ['$filter', 'teamPosition', function ($filter, teamPosition) {
        var orderBy = $filter('orderBy'), limitTo = $filter('limitTo'), filter = $filter('filter');
        var orderGuests = true;

        function precision(score, dp, rounding) {
            var scale = Math.pow(10,(dp || 3));
            rounding = "round";// rounding || "round";
            if (!score) return 0;
            return Math[rounding](score * scale) / scale;
            //return (parseFloat(score.toFixed(3)));
        }

        function calculateOrder(scores, splitKey, grp) {
            var lastPosition, lastScore, sameScores, sorting, lastSplit, splitScoreProperty, resetPosition;

            lastPosition = 0;
            lastScore = 0;
            lastSplit = 0;
            resetPosition = true;

            sameScores = 1;

            sorting = ["-value"];
            if (splitKey) {
                sorting.push(splitKey);
                splitScoreProperty = splitKey;
                if (splitKey[0] === "-") {
                    splitScoreProperty = splitKey.substr(1);
                }
            }


            //todo: split same scores on artistic and tumbling

            function setScoreCache(score) {
                lastScore = score.value;
                if (splitKey) {
                    lastSplit = score[splitScoreProperty];
                }



                if (resetPosition) {
                    lastPosition += (sameScores);
                    sameScores = 1;

                }


            }
            sorting.push('key');
            angular.forEach(orderBy(scores, sorting), function (s) {
                if(s.value === 0){
                    s.order= 0;
                    return;
                }
                if (s.guest && !orderGuests) {
                    s.order = 0;
                } else {
                    if (s.value !== lastScore) {
                        setScoreCache(s);
                    } else {
                        if (splitKey && lastSplit !== s[splitScoreProperty]) {

                            setScoreCache(s);
                        } else {
                            if (resetPosition && !grp.ResetPosition) {
                                sameScores++;
                            }


                        }
                    }
                    resetPosition = !s.guest;
                    s.order = lastPosition;
                }
            });
            return scores;
        }

        function sumScoring(score) {
            if (score.Score) {
                return score.Score;
            }
            return 0;
        }



        function calculate(entries, disc, grp) {
            if(disc.Category.Description.toLowerCase() === 'teamgym'){
                return teamPosition.calculate(entries, disc, grp);
            }
            var take, splitscore, splitScoreProperty, rankExercise, ageBonus;
            var exHash = {}, entryHash = {}, groupEntries = [];

            function addEntryScoring(entryOrderModel, s) {
                entryOrderModel.value += sumScoring(s);
                if (splitscore) {
                    entryOrderModel[splitscore] += s[splitScoreProperty];
                }
            }

            // loop through all scores to get a total for the exercises the entrant has completed

            take = disc.FilterMaxScore;
            if (take === -1) {
                take = false;
            }
            splitscore = disc.SplitKey;
            splitScoreProperty = splitscore;
            rankExercise = disc.Category.RankExercise;
            ageBonus = disc.AgeBonus;

            if (splitscore && splitscore[0] === "-") {
                splitScoreProperty = splitscore.substr(1);
            }

            angular.forEach(entries, function (e) {
                var entryOrderModel = { key: e.EntryNumber, value: 0, guest: e.Guest };

                function add(s) {
                    addEntryScoring(entryOrderModel, s);
                    s.isCounted = true;
                }

                if (splitscore) {
                    entryOrderModel[splitScoreProperty] = 0;
                }
                angular.forEach(e.Scores, function (s) {

                    if (rankExercise) {
                        var ex = exHash[s.ExerciseId] || (exHash[s.ExerciseId] = { entries: [] });
                        var exModel = { key: e.EntryNumber, value: precision(sumScoring(s)), guest: e.Guest };
                        if (splitscore) {
                            exModel[splitScoreProperty] = s[splitScoreProperty];
                        }
                        //exercise score
                        ex.entries.push(exModel);
                        ex[e.EntryNumber] = exModel;
                    }
                    //overall score
                    if (take) {
                        s.isCounted = false;
                    } else {
                        if (take === 0) {
                            addEntryScoring(entryOrderModel, s);
                        }
                    }

                });
                if (take) {
                    var taken = limitTo(orderBy(e.Scores, [function (a, b) {
                        return -(a.Score || 0);
                    }, splitscore]), take);
                    angular.forEach(taken, add);
                }
                entryHash[e.EntryNumber] = entryOrderModel;
                if (take !== false) {
                    groupEntries.push(entryOrderModel);


                    if (ageBonus && entryOrderModel.value > 0) {
                        entryOrderModel.value += e.AgeBonus;
                    }
                    entryOrderModel.value = precision(entryOrderModel.value);
                }
            });

            //overall position
            if (take !== false) {
                calculateOrder(groupEntries, null, grp); //kp: was splitscore on AA
            }

            //exercise position
            if (rankExercise) {
                for (var key in exHash) {
                    if (exHash.hasOwnProperty(key)) {
                        calculateOrder(exHash[key].entries, splitscore, grp);
                    }
                }
            }

            return {
                exercisePositions: exHash,
                groupPositions: entryHash
            };


        }




        return {
            precision: precision,
            getPositions: calculateOrder,
            calculate: calculate
        };


    }]);

}(angular.module('app.shared')));