/**
 * Created by kevinpayne on 27/03/15.
 */
(function (app) {

    app.service('reportSettings', [function () {
        return {
            hideScores: false
        };

    }]);



}(angular.module('app.shared')));