(function () {
    'use strict';

    angular.module('app')
        .directive('withdraw', ['viewstatus',
        function (viewstatus) {
            return {

                link: function (scope, el, attr) {
                    function clickHandler() {

                            var entry = scope.$eval(attr.withdraw);
                            var temp = angular.copy(entry);

                            temp.Withdrawn = !temp.Withdrawn;
                            viewstatus.startLoading();
                            temp.$withdraw(function () {
                                entry.Withdrawn = temp.Withdrawn;
                                setClass(entry.Withdrawn);
                                scope.$eval(attr.done, {entry: entry});
                            })['finally'](viewstatus.stopLoading);


                    }

                    function setClass(withdrawn){
                        
                        el[(!withdrawn)?'addClass':'removeClass']('btn-dns');
                    }
                    setClass(scope.$eval(attr.withdraw).Withdrawn);

                    el.on('click.app.withdraw', clickHandler);

                    scope.$on('$destroy', function () {
                        el.off('click.app.withdraw', clickHandler);
                    });
                }
            };

        }]);

}());