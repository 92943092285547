/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    angular.module('app')
    .service('activeCompetition', ActiveCompetition)
    .service('activeRotation', ActiveRotation);
    
    ActiveCompetition.$inject = ['webAPI', '$q'];
    function ActiveCompetition(webAPI, $q) {
        var data, rounds, disciplines, groups, timeline, categoryRounds;



        this.data = data = webAPI.competitions.active();
        this.disciplines = function(options){
            //probably want to transform responses to hash
            var defer = $q.defer(), getDisciplines;
            this.data.$promise.then(function (data) {
                if(options && options.round) {
                    getDisciplines = webAPI.disciplines.forCompetitionAndRound({competition: data.Id, rnd: options.round });
                } else {
                    getDisciplines = webAPI.disciplines.forCompetition({competition: data.Id});
                }
                getDisciplines.$promise.then(defer.resolve, defer.reject);
                return data;
            });


            return defer.promise;
        };
        this.subs = function(){
          return this.data.$promise.then(function(data){
              return webAPI.competitions.subs({id: data.Id}).$promise;
          });
        };

        this.rounds = function(){
            var defer = $q.defer();
            this.data.$promise.then(function(data){
                if(!rounds) {
                    webAPI.rounds.query({id: data.Id}).$promise.then(function (rnds) {
                        if (!rounds) {
                            rounds = {};
                            categoryRounds = {};
                            angular.forEach(rnds, function (rnd) {
                                var round = rounds[rnd.RoundNumber];
                                var categoryRound = categoryRounds[rnd.Category.Name.toLowerCase()] = categoryRounds[rnd.Category.Name.toLowerCase()] || [];

                               if(!round) {
                                   round = rounds[rnd.RoundNumber] = {Categories: []};
                                }
                               
                                round.Name = rnd.Name;
                                round.Categories.push(rnd.CategoryId);

                                categoryRound.push(rnd);


                            });
                            defer.resolve(rounds);
                        }
                        return rnds;
                    }, defer.reject);
                } else{
                    defer.resolve(rounds);
                }

                return data;
            });
            return defer.promise;
        };
        this.categoryRounds = function (category) {
            if (!categoryRounds) {
                this.rounds();
            }
            return function () {
                var cat = category.toLowerCase();
                if(!categoryRounds) {
                    return;
                }
                return categoryRounds[cat];
            };
        };
        this.groups = function(options){
            //probably want to transform responses to hash
            var defer = $q.defer();
            options = options || {};
            this.data.$promise.then(function(data){

                var getGroups;

                if(options.round){
                  getGroups =  webAPI.groups.forCompetitionAndRound({
                        competition: data.Id,
                        rnd: options.round
                    });
                } else {
                    if (options.discipline) {
                    getGroups =    webAPI.groups.forCompetitionAndDiscipline({
                            competition: data.Id,
                            id: options.discipline
                        });
                    } else {
                     getGroups =   webAPI.groups.forCompetition({competition: data.Id});


                    }
                }
                getGroups.$promise.then(defer.resolve, defer.reject);
                return data;
            });
            return defer.promise;
        };
        this.timeline = function(){

            var defer = $q.defer();
            this.data.$promise.then(function(data){
                webAPI.runningOrder.query({id: 'active'}).$promise.then(defer.resolve, defer.reject);
                return data;
            });
            return defer.promise;
        };

        this.get = function () {
            if (!data) {
                return this.data;
            }
            return data;
        };


    }
    
    ActiveRotation.$inject = ['scoreboardTicker', '$q'];
    function ActiveRotation(messaging, $q) {
        messaging.on('rotation', handleMessage);
        messaging.invoke('GetActiveRotation');
        
        var rotation = {}, roundHash = {}, promises = {};
        this.rotations = function(){
            return rotation;
        };
        this.rounds = function(){
            return roundHash;
        };
        this.categoryRotations = function (category) {
            var cat = category.toLowerCase();
            if (!promises[cat]) {
                promises[cat] = $q.defer();
                if (rotation[cat]) {
                    promises[cat].resolve(rotation[cat]);
                }
            }
            return promises[cat].promise;
        };


        function handleMessage(data) {
          
            for(var key in rotation){
                if(rotation.hasOwnProperty(key)) {
                    rotation[key].rounds.length = 0;
                }
            }


            angular.forEach(data, function (r){
                var catName = r.Category.Name.toLowerCase(), cat = rotation[catName];
                if (!cat) {
                    cat = rotation[catName] = { rounds: [], active: {} };
                    if (promises[catName]) {
                            promises[catName].resolve(cat);
                    }
                }

                cat.rounds.push(r);
                roundHash[r.RoundNumber] = r.Name;
                if(r.Active) {
                    cat.active = r;
                }

            });
           
            
        }
    }

}());
