(function () {
    angular
    .module('app')
    .directive('activeRound', activeRound)
    .controller('ActiveRoundCtrl', ActiveRoundCtrl);
    
    activeRound.$inject = ['routes'];
    function activeRound(routes) {
        return {
            scope: {
                category: '=activeRound'
            },
            controller: 'ActiveRoundCtrl',
            controllerAs: 'roundInfo',
            templateUrl: routes.templateDirectory('active-round')
        };
    }
    
    ActiveRoundCtrl.$inject = ['$scope', 'activeRotation'];
    function ActiveRoundCtrl($scope, activeRotation) {
        
        var vm = this;
        activeRotation.categoryRotations($scope.category.toLowerCase()).then(function (data){
            vm.newRound = data;
            return data;
        });
        

        $scope.$watch(function () {
            return vm.newRound && vm.newRound.active;
        }, onRotationChanged, true);
        
        function onRotationChanged(newvalue, oldvalue) {
            if (newvalue && oldvalue) {
                if (newvalue.Name !== oldvalue.Name) {
                    vm.oldRound = oldvalue;
                }
            }
        }
    }

}());