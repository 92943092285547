/**
 * Created by kevinpayne on 13/02/15.
 */
(function (app) {
    'use strict';
    angular.module('app')
        .controller('WatchController', WatchController);

    WatchController.$inject = ['$scope', '$routeParams', '$window', 'scoreboardTicker', 'reportSettings', 'activeCompetition', 'activeRotation', 'report', 'rounds'];

    function WatchController($scope, $routeParams, $window, messaging, reportSettings, activeCompetition, activeRotation, report, rounds) {
        var vm = this;

        vm.reportSettings = reportSettings;
        vm.rounds = rounds;
        vm.selectedRound = $routeParams.rnd;
        var entries = {};


        function setGroupandDisciplines(r) {
            vm.report = r;
            angular.forEach(r, function (discipline) {
                angular.forEach(discipline.Groups, function (group) {
                    angular.forEach(group.Entries, function (entry) {
                        var e = entries[entry.EntId] = entry;
                    });
                });
            });
        }


        function updateScores(data) {
            var groupEntry, scoreToUpdate, key;
            groupEntry = entries[data.EntryId];

            angular.forEach(groupEntry.Scores, function (score) {
                if (score.Id === data.Id) {
                    scoreToUpdate = score;
                    return false;
                }
            });
            if (scoreToUpdate) {
                for (key in scoreToUpdate) {
                    if (scoreToUpdate.hasOwnProperty(key)) {
                        scoreToUpdate[key] = data[key];
                    }
                }
            } else {
                delete data.Entry;
                delete data.Exercise;
                groupEntry.Scores.push(data);
            }


        }

        if (report) {
            var msgEntryScore = messaging.on('entryScore', updateScores);
            var msgCorrection = messaging.on('correction', updateScores);
            var msgRefresh = messaging.on('refreshRequest', refresh);
            var msgSJ = messaging.on('sj', sj);

            $scope.$on('$destroy', function () {
                if (msgEntryScore) { messaging.off('entryScore', msgEntryScore); }
                if (msgCorrection) { messaging.off('correction', msgCorrection); }
                if (msgRefresh) { messaging.off('refreshRequest', msgRefresh); }
                if (msgSJ) { messaging.off('sj', msgSJ); }

                reportSettings.hideScores = false;
                reportSettings.hideDEScores = false;
                reportSettings.hideExScores = false;

            });
            setGroupandDisciplines(report);
        }


        function refresh(data) {
            if (!data) {
                $window.location.reload();
            }
        }
        function sj(data) {
            var groupEntry;
            groupEntry = entries[data.id];
            if (groupEntry) {
                groupEntry.SJ = data.value;
            }
        }
    }

}());