(function () {
    'use strict';
    var authUrl, reportUrl, subReportUrl, parentReportUrl, parentExecutionReportUrl, entriesUrl, competition, id, executionReportUrl;

    angular.module('app.API', ['ngResource', 'app.UI', 'app.shared'])
        .factory('webAPI', api);



    id = ':id';
    competition = ':competition';
    authUrl = urlMaker('authentication');
    reportUrl = urlMaker('reports/event', competition, ':rnd');
    executionReportUrl = urlMaker('reports/event', competition, 'execution/:rnd');
    subReportUrl = urlMaker('reports/event', competition, 'sub-event/:sub/:rnd');

    parentReportUrl = urlMaker('reports/event/parent', competition, ':rnd');
    parentExecutionReportUrl = urlMaker('reports/event/parent', competition, 'execution/:rnd');
    entriesUrl = urlMaker('entries');

    function apiBaseUrl() {
        return '/api/' + Array.prototype.slice.call(arguments).join('/');
    }

    function urlMaker() {
        var arr = Array.prototype.slice.call(arguments);
        return function () {
            return apiBaseUrl.apply(null, arr.concat.apply(arr, arguments));
        };
    }
    api.$inject = ['$resource', 'gymnasts', '$window', 'scoreboardTicker', 'helperService', '$http'];
    function api($resource, gymnasts, $window, messaging, helperService, $http) {

        var entryInterceptor, result, scoreEntryInterceptor, roundsInterceptor;
        var loginInterceptor, registerInterceptor, logoutInterceptor, eventGroupInterceptor;

        loginInterceptor = {
            response: function (data) {
                var r = data.resource;
                r.overrideLocation = r.role === 'admin';
                loadUserCache(r);
                checkRole(r);
                cacheUser(r);
                messaging.invoke('UserLogin');

                return data;
            }
        };

        registerInterceptor = {
            response: function (data) {
                var r = data.resource;
                r.overrideLocation = r.role === 'admin';
                addTea(r);
                checkRole(r);
                cacheUser(r);
                messaging.invoke('UserLogin');
                return data;
            }
        };


        logoutInterceptor = {
            response: function (data) {
                clearUserCache();
                messaging.invoke('UserLogout');
                helperService.destroy();
                return data;
            }
        };

        eventGroupInterceptor = {
            response: function (data) {
                setDate(data.resource.EventGroup);

                if (!data.resource.EventId) {
                    data.resource.EventId = data.resource.EventGroup.EventId;
                }
                return data.resource;
            }

        };

        function checkRole(user) {
            if (user.role === 'admin') {
                helperService.init();
            }
        }
        function setEntry(entry) {
            if (!(entry instanceof result.entries)) {
                entry = new result.entries(entry);
            }
            if (entry.Scores) {
                angular.forEach(entry.Scores, function (score, i) {
                    if (!(score instanceof result.entryScore)) {
                        entry.Scores[i] = new result.entryScore(score);

                    }
                });
            }
            if (entry.Exercises) {
                angular.forEach(entry.Exercises, function (ex, i) {
                    if (!(ex instanceof result.exercise)) {
                        entry.Exercises[i] = new result.exercise(ex);
                    }
                });
            }


            return entry;
        }


        function setDate(model) {

            model.EventDate = new Date(model.EventDate);
            return model;
        }

        entryInterceptor = {
            response: function (data) {
                if (!(data.resource instanceof result.entries)) {
                    angular.forEach(data.resource, function (r) {
                        r = setEntry(r);
                    });
                } else {
                    data.resource = setEntry(data.resource);
                }
                return data.resource;
            }

        };
        roundsInterceptor = {
            response: function (data) {
                if (!(data.resource instanceof result.rounds)) {
                    data.resource.forEach(function (r) {
                        if (r.Date) {
                            r.Date = new Date(r.Date);
                        }
                    });
                }

                return data.resource;
            }
        };

        scoreEntryInterceptor = {
            response: function (data) {

                angular.forEach(data.resource, function (r) {
                    r.Entry = setEntry(r.Entry);
                });

                return data.resource;
            }
        };

        result = {
            disciplines: $resource(apiBaseUrl('disciplines', id), { id: '@DisciplineId' }, {
                forCompetition: {
                    isArray: true,
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'disciplines', id),
                    params: { id: '@DisciplineId', competition: '@CompetitionId' }
                },

                forCompetitionAndRound: {
                    isArray: true,
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'round/:rnd'),
                    params: { id: '@DisciplineId', competition: '@CompetitionId', rnd: '@Round' }
                },
                forCompetitionWithGroups: {
                    isArray: true,
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'disciplines-with-groups', id)
                },
                withCompetitionGroups: {
                    isArray: true,
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'all-disciplines-with-groups', id)
                }
            }),
            auth: function (user, pass) {

            },
            authorize: $resource(authUrl('authorize'), {}, {
                status: { method: 'GET', url: authUrl('status') },
                minrole: { method: 'GET', url: authUrl('minrole/:role') }
            }),
            user: $resource(authUrl('user', id), { id: '@UserId' }, {
                authorize: { method: 'POST', url: authUrl('authorize'), interceptor: loginInterceptor },
                register: { method: 'POST', url: authUrl('register'), interceptor: registerInterceptor },
                logout: { method: 'GET', url: authUrl('logout'), interceptor: logoutInterceptor },
                password: { method: 'PUT', url: authUrl('user', id, 'set-password') }
            }),
            reports: $resource(
                reportUrl(id, ':grp/:ex'), {
                competition: '@CompetitionId',
                id: '@DisciplineId',
                grp: '@GroupId',
                rnd: 'all'
            }, {
                get: { isArray: true, method: 'GET' },
                queryExecutions: {
                    isArray: true, method: 'GET',
                    url: executionReportUrl(id, ':grp')
                },
                queryByDate: {
                    isArray: true,
                    method: 'get',
                    url: reportUrl('/date/:date')
                },
                queryByGroup: {
                    isArray: true,
                    method: 'get',
                    url: reportUrl(id, ':group')
                },
                queryBySub: {
                    url: subReportUrl(id, ':grp/:ex'),
                    isArray: true,
                    method: 'get',
                    params: { rnd: 'all' }

                },
                queryByParent: {
                    url: parentReportUrl(id),
                    isArray: true,
                    method: 'get'

                },
                queryExecutionsByParent: {
                    url: parentExecutionReportUrl(id),
                    isArray: true,
                    method: 'get'
                }
            }),
            entries: $resource(apiBaseUrl("competition", competition, "entries", id),
                { id: '@EntryNumber', competition: "@CompetitionId" }, {
                'update': { method: 'PUT' },
                'withdraw': {
                    method: 'PUT',
                    url: apiBaseUrl('entries/withdraw', id),
                    params: { id: '@Id', competition: null },
                    transformRequest: function (entry) {
                        return JSON.stringify({ value: entry.Withdrawn });
                    }
                },
                'sj': {
                    method: 'PUT',
                    url: apiBaseUrl('entries/sj', id),
                    params: { id: '@Id', competition: null },
                    transformRequest: function (entry) {
                        return JSON.stringify({ value: entry.SJ });
                    }
                },
                'register': {
                    method: 'PUT',
                    url: apiBaseUrl('entries/register', id),
                    params: { id: '@Id', competition: null },
                    transformRequest: function (entry) {
                        return JSON.stringify({ value: entry.Registered });
                    }
                },
                'get': {
                    interceptor: entryInterceptor
                },
                'getForTariffSheets': {
                    method: 'GET',
                    url: apiBaseUrl("tariffs/tariffsheets", competition, ":key/entries"),
                    interceptor: entryInterceptor,
                    isArray: true
                },
                'getWithoutScores': {
                    method: 'GET',
                    url: apiBaseUrl("competition", competition, "entry-meta", id),
                    interceptor: entryInterceptor
                },
                'getForActiveRotationAndExercise': {
                    method: 'GET',
                    url: apiBaseUrl('competition/:EventId/rotation-exercise-entries/:categoryName/:discipline/:exerciseName'),
                    interceptor: entryInterceptor,
                    isArray: true

                },
                'getForRoundRotationAndExercise': {
                    method: 'GET',
                    url: apiBaseUrl('competition/:EventId/rotation-exercise-entries/:categoryName/:discipline/:exerciseName/:round/:rotation'),
                    interceptor: entryInterceptor,
                    isArray: true
                },
                withdrawn: {
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'withdrawn-entries'),
                    interceptor: entryInterceptor,
                    isArray: true
                },
                forGroup: {
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, "entries-for-group", id),
                    interceptor: entryInterceptor,
                    isArray: true
                },
                'forDiscipline': {
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'entries-for-discipline', id),
                    interceptor: entryInterceptor,
                    isArray: true
                }

            }),
            tariffs: $resource(apiBaseUrl('tariffs/:id'), {}, {
                getForExercise: {
                    url: apiBaseUrl('tariffs/:id/:exercise'),
                    method: 'GET'
                },
                updateClubEntry: {
                    method: 'PUT',
                    url: apiBaseUrl('tariffs/tariffsheets/:competition/:key/entries/:id')
                },
                getPack: {
                    method: 'GET',
                    isArray: true,
                    url: apiBaseUrl('tariffs/:id/for-exercise/:exercise/and-round/:round')
                },
                getPackWithScores: {
                    method: 'GET',
                    isArray: true,
                    url: apiBaseUrl('tariffs/:id/for-exercise/:exercise/and-round/:round/with-scores')
                }
            }),
            exercise: $resource(apiBaseUrl("exercise", id), { id: '@Id' }, {
                'validateName': { method: 'GET', url: apiBaseUrl('exercise/valid-name/:ex'), params: { ex: '@Name' } },
                'forGroup': { method: 'GET', url: apiBaseUrl('exercise/for-group/:grp'), isArray: true },
                'forSet': { method: 'GET', url: apiBaseUrl('exercise/for-set/:set'), isArray: true },
                'forCompetitionAndDiscipline': {
                    method: 'GET',
                    url: apiBaseUrl('exercise/for-competition/:id/d/:discipline'),
                    isArray: true
                }
            }),
            groups: $resource(apiBaseUrl("groups", id), { id: '@Id', competition: '@EventId' }, {
                'delete': { method: 'DELETE', url: apiBaseUrl('competition', competition, "groups", id) },
                'entries': { method: 'GET', url: apiBaseUrl('competition', competition, "groups", id, "entries") },
                'exercises': { method: 'GET', url: apiBaseUrl("groups", id, "exercises") },
                'withDiscipline': { method: 'GET', url: apiBaseUrl("groups", id, "with-discipline") },
                'withEventGroup': { method: 'GET', url: apiBaseUrl('competition', competition, "groups", id, "with-eventgroup"), interceptor: eventGroupInterceptor },
                'forDiscipline': { method: 'GET', url: apiBaseUrl('groups/for-discipline', id), isArray: true },
                'forCompetitionAndDiscipline': {
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'groups/for-discipline', id),
                    isArray: true
                },
                'forCompetitionAndRound': {
                    method: 'GET',
                    url: apiBaseUrl('competition', competition, 'round', id, 'groups'),
                    isArray: true
                },
                'forCompetition': {
                    method: 'GET', url: apiBaseUrl('competition', competition, 'groups'), isArray: true
                },
                'update': { method: 'PUT', url: apiBaseUrl("competition", competition, "groups", id) },
                'save': { method: 'POST', url: apiBaseUrl("competition", competition, "groups") }
            }),
            entryScores: $resource(apiBaseUrl("entries", id, "scores"), { id: '@EntryNumber' },
                {
                    saveScores: { method: 'POST', isArray: true }
                }
            ),
            entryScore: $resource(apiBaseUrl("entries", id, "score/:scoreId"), { id: '@EntryNumber', scoreId: '@Id' },
                {
                    update: { method: 'PUT' },
                    save: { method: 'POST' },
                    getMusicList: {
                        method: 'GET',
                        url: apiBaseUrl('competition', competition, 'music-rotation'),
                        interceptor: scoreEntryInterceptor,
                        isArray: true,
                        params: { id: null, scoreId: null }
                    },
                    getList: {
                        method: 'GET',
                        url: apiBaseUrl('competition', competition, 'apparatus-list/:exercise/:round'),
                        interceptor: scoreEntryInterceptor,
                        isArray: true,
                        params: { id: null, scoreId: null }
                    },
                    lockScores: {
                        method: 'PUT',
                        url: apiBaseUrl('entries/lock-scores'),
                        isArray: true
                    },
                    unlockScore: {
                        method: 'PUT',
                        url: apiBaseUrl('entries/unlock-score')
                    }
                }
            ),
            gymnasts: $resource(apiBaseUrl('gymnasts', id), { id: '@Id' }),
            slides: $resource(apiBaseUrl('adverts', id)),
            competitions: $resource(apiBaseUrl('competition/:id'), { id: '@Id' }, {
                getFromName: { method: 'GET', url: apiBaseUrl('competition/named/:name') },
                update: { method: 'PUT' },
                active: { method: 'GET', url: apiBaseUrl('competition/active') },
                setActive: { method: 'PUT', url: apiBaseUrl('competition/active') },
                subs: { method: 'GET', url: apiBaseUrl('competition/:id/sub-event'), cached: true, isArray: true }
            }),
            rounds: $resource(apiBaseUrl('competition/:id/rounds'), { id: '@EventId' }, {
                query: {
                    interceptor: roundsInterceptor,
                    isArray: true
                },
                savemultiple: {
                    url: apiBaseUrl('competition/:id/rounds'),
                    method: 'POST',
                    isArray: true,
                    params: { id: '@EventId' },
                    interceptor: roundsInterceptor
                }
            }),
            runningOrder: $resource(apiBaseUrl('competition/:id/runningorder'), {}),
            competitionJudging: $resource(apiBaseUrl('competition', competition, 'judging')),
            clubs: $resource(apiBaseUrl('clubs')),
            judges: $resource(apiBaseUrl('judges/:EventId/:RoundNumber/:ExerciseId')),
            dataImport: {
                query: function (id) {
                    return $http({
                        url: apiBaseUrl('data-import'),
                        method: "GET"
                    });
                },
                post: function (data) {
                    return $http({
                        url: apiBaseUrl('data-import'),
                        headers: { "Content-Type": undefined },
                        data: data,
                        method: "POST"
                    });
                },
                createFV: function (event) {
                    return $http({
                        url: apiBaseUrl('data-import/import-fv'),
                        data: {
                            eventId: event.Id,
                            createGroups: true
                        },
                        method: "PUT"
                    });
                },
                createTG: function (event, options) {
                    return $http({
                        url: apiBaseUrl('data-import/import-teamgym'),
                        data: Object.assign( {
                            eventId: event.Id
                        }, options),
                        method: "PUT"
                    });
                },
                createRegional: function (event, options) {
                    return $http({
                        url: apiBaseUrl('data-import/import-regionals'),
                        data: Object.assign( {
                            eventId: event.Id
                        }, options),
                        method: "PUT"
                    });
                },
                createGrades: function (event, options) {
                    return $http({
                        url: apiBaseUrl('data-import/import-grades'),
                        data: Object.assign( {
                            eventId: event.Id
                        }, options),
                        method: "PUT"
                    });
                },
                createMag: function (event, options) {
                    return $http({
                        url: apiBaseUrl('data-import/import-mag'),
                        data: Object.assign( {
                            eventId: event.Id
                        }, options),
                        method: "PUT"
                    });
                },
                createPegasusOpen: function (event, options) {
                    return $http({
                        url: apiBaseUrl('data-import/import-peg'),
                        data: Object.assign( {
                            eventId: event.Id
                        }, options),
                        method: "PUT"
                    });
                }

            }
        };

        result.entries.prototype.$gymnasts = function () {
            if (this.EntryTitle) {
                return this.EntryTitle;
            }
            return gymnasts(this.Gymnasts);
        };

        result.entryScore.prototype.isNew = function () {
            return !this.Id;
        };

        result.user.prototype.cache = function () {
            cacheUser(this);
            return this;
        };
        result.user.prototype.loadCache = function () {
            loadUserCache(this);
            return this;
        };

        result.user.prototype.clear = function () {
            var self = this;
            angular.forEach(this, function (data, key) {
                self[key] = null;
            });

            this.tea = undefined;
            addTea(this);
        };
        result.user.prototype.reset = function (model) {
            var self = this;
            self.clear();
            angular.forEach(model, function (data, key) {
                self[key] = data;
            });
        };

        result.gymnasts.prototype.$name = function () {
            return this.FirstName + ' ' + this.LastName;
        };


        function loadUserCache(obj) {

            var cache = JSON.parse($window.localStorage.getItem('user'));
            if (cache && (!obj.username || cache.username === obj.username)) {
                obj.username = cache.username;
                obj.name = cache.name;
                obj.tea = cache.tea;
                obj.id = cache.id;
                obj.headJudge = cache.headJudge;
                obj.keyFields = cache.keyFields;
                obj.location = cache.location;
                obj.overrideLocation = cache.overrideLocation;
            }
            addTea(obj);
        }
        function addTea(user) {
            if (!user.tea) {
                user.tea = {
                    requests: []
                };
            }
            if (!user.tea.name) {
                user.tea.name = user.name;
            }
            if (!user.tea.panel) {
                user.tea.panel = user.location;
            }
        }
        function cacheUser(user) {
            $window.localStorage.setItem('user', JSON.stringify(getUserInfo(user)));

        }

        function getUserInfo(user) {
            return {
                name: user.name,
                location: user.location,
                tea: user.tea,
                id: user.id,
                headJudge: user.headJudge,
                keyFields: user.keyFields,
                overrideLocation: user.role === 'admin'
            };
        }
        function clearUserCache() {
            $window.localStorage.removeItem('user');
        }




        return result;
    }



}());