
/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    var requirements = [
        { text: "Circular Formation", code: 1, shortText: "CF" },
        { text: 'Large Formation', code: 2, shortText: "LF" },
        { text: 'Small Formation', code: 3, shortText: "SF" },
        { text: 'Rhythmic Sequence Start', code: 4, shortText: "RS→" },
        { text: 'Rhythmic Sequence End', code: 5, shortText: "RS←" },
        { text: 'Planes Backwards', code: 6, shortText: "↑" },
        { text: 'Planes Sidewards', code: 7, shortText: "↔" },
    ];

    var tumbleElements = [
        { group: 'Fwd', text: 'Cartwheel', code: 'X', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'cartwheel' },
        { group: 'Fwd', text: 'Handspring', code: 'H', score: 0.2, isBaseElement: false, isPredefined: true, icon: 'handspring' },
        { group: 'Fwd', text: 'Flyspring', code: 'FS', score: 0.2, isBaseElement: false, isPredefined: true, icon: 'flyspring' },
        { group: 'Fwd', text: 'Round off', code: 'R', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'roundoff' },
        { group: 'Bwd', text: 'Flick Flack', code: 'F', score: 0.2, isBaseElement: false, isPredefined: true, icon: 'flic' },
        { group: 'Bwd', text: 'Whipback', code: 'W', score: 0.3, isBaseElement: false, isPredefined: true, icon: 'whip' },

        { group: 'Fwd', text: 'Tucked Salto', code: 'O', score: 0.2, isBaseElement: true, rotation: 1, twists: 0, icon: 'tuck' },
        { group: 'Fwd', text: 'Piked Salto', code: '>', score: 0.3, isBaseElement: true, rotation: 1, twists: 0, icon: 'pike' },
        { group: 'Fwd', text: 'Straight Salto', code: '\\', score: 0.4, isBaseElement: true, rotation: 1, twists: 0, icon: 'straight' },
        { group: 'Fwd', text: 'Double Salto Tucked', code: 'OO', score: 1.2, isBaseElement: true, rotation: 2, twists: 0, icon: 'double' },
        { group: 'Fwd', text: 'Double Salto Piked', code: '>>', score: 1.4, isBaseElement: true, rotation: 2, twists: 0, icon: 'dbl-pike' },
        { group: 'Fwd', text: 'Double Salto Straight', code: '\\\\', score: 1.6, isBaseElement: true, rotation: 2, twists: 0, icon: 'dbl-straight' },
        { group: 'Bwd', text: 'Tucked Salto', code: 'o', score: 0.2, isBaseElement: true, rotation: 1, twists: 0, icon: 'bwd-tuck' },
        { group: 'Bwd', text: 'Piked Salto', code: '<', score: 0.2, isBaseElement: true, rotation: 1, twists: 0, icon: 'bwd-pike' },
        { group: 'Bwd', text: 'Straight Salto', code: '/', score: 0.3, isBaseElement: true, rotation: 1, twists: 0, icon: 'bwd-straight' },
        { group: 'Bwd', text: 'Double Salto Tucked', code: 'oo', score: 0.8, isBaseElement: true, rotation: 2, twists: 0, icon: 'bwd-double' },
        { group: 'Bwd', text: 'Double Salto Piked', code: '<<', score: 0.9, isBaseElement: true, rotation: 2, twists: 0, icon: 'bwd-dbl-pike' },
        { group: 'Bwd', text: 'Double Salto Straight', code: '//', score: 1.1, isBaseElement: true, rotation: 2, twists: 0, icon: 'bwd-dbl-str8' },
        { group: 'Bwd', text: 'Triple Salto Tucked', code: 'ooo', score: 1.6, isBaseElement: true, rotation: 3, twists: 0, icon: 'bwd-triple' },
        { group: 'Bwd', text: 'Triple Salto Piked', code: '<<<', score: 1.9, isBaseElement: true, rotation: 3, twists: 0, icon: 'bwd-tpl-pike' }
    ];

    var primaryTumbleElementsBG = [
        { group: 'Fwd', text: 'Forward roll', code: 'C', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'fwd-roll' },
        { group: 'Fwd', text: 'Fast walkover', code: 'K', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A406' },
        { group: 'Fwd', text: 'One-handed Cartwheel', code: 'x', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A213A' },
        { group: 'Fwd', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Fwd', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Fwd', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Fwd', text: 'Stretched Jump 180 turn', code: 'I', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'j180' },
        { group: 'Bwd', text: 'Backward roll', code: 'c', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'bwd-roll' }
    ];

    var primaryTrampetteElementsBG = [
        { group: 'Trampette', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Trampette', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Trampette', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Trampette', text: 'Pike Jump', code: 'V', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jpike' },
        { group: 'Vault', text: 'Squat on Jump off', code: 'Q', score: 0.1, isBaseElement: false, isPredefined: true, vault: 'squaton', icon: 'jstretch' }
    ];

    var bgL5Tumble = [
        { group: 'Fwd', text: 'One-handed Cartwheel', code: 'x', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A213A' },
        { group: 'Fwd', text: 'Forward roll', code: 'C', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'fwd-roll' },
        { group: 'Fwd', text: 'Fast walkover', code: 'K', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A406' },
        { group: 'Fwd', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Fwd', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Fwd', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Fwd', text: 'Stretched Jump 180 turn', code: 'I', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'j180' },
        { group: 'Bwd', text: 'Backward roll', code: 'c', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'bwd-roll' }
    ];


    var bgL5Trampette = [
        { group: 'Trampette', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Trampette', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Trampette', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Trampette', text: 'Pike Jump', code: 'V', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jpike' },
        { group: 'Vault', text: 'Squat on Jump off', code: 'Q', score: 0.1, isBaseElement: false, isPredefined: true, vault: 'squaton', icon: 'jstretch' },
        { group: 'Vault', text: 'Straddle Vault', code: '^', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'straddlevault' },
        //{ group: 'Vault', isBaseElement: true, text: 'Handstand Flatback', code: 'h', score: 0.2, rotation: 0, icon: 'vaulton' },
        { group: 'Vault', isBaseElement: true, isPredefined: true, text: '¼ on - ¼ off', code: 'R', score: 0.25, vault: [0.5, 'vaulton'], icon: ['vaultoff', 0.5] },
        { group: 'Vault', isBaseElement: true, text: 'Handspring', code: 'H', score: 0.25, rotation: 1, vault: 'vaulton', icon: 'vaultoff' }
    ];

    var bgL6Tumble = [
        { group: 'Fwd', text: 'One-handed Cartwheel', code: 'x', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A213A' },
        { group: 'Fwd', text: 'Forward roll', code: 'C', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'fwd-roll' },
        { group: 'Fwd', text: 'Fast walkover', code: 'K', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A406' },
        { group: 'Fwd', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Fwd', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Fwd', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Fwd', text: 'Stretched Jump 180 turn', code: 'I', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'j180' },
        { group: 'Bwd', text: 'Backward roll', code: 'c', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'bwd-roll' },
        { group: 'Bwd', text: 'Backward roll to handstand ', code: 'ç', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A410' },
        { group: 'Fwd', text: 'Handstand to forward roll', code: 'Ç', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'A205' },
    ];

    var bgL6Trampette = [
        { group: 'Trampette', text: 'Tuck Jump', code: 'N', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jtuck' },
        { group: 'Trampette', text: 'Star Jump', code: '*', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'star' },
        { group: 'Trampette', text: 'Straddle Jump', code: 'A', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jstraddle' },
        { group: 'Trampette', text: 'Pike Jump', code: 'V', score: 0.05, isBaseElement: false, isPredefined: true, icon: 'jpike' },
        { group: 'Vault', text: 'Squat on Jump off', code: 'Q', score: 0.05, isBaseElement: false, isPredefined: true, vault: 'squaton', icon: 'jstretch' },
    ];

    var trampetteElements = [
        { isBaseElement: true, isPredefined: true, group: 'Vault', text: '¼ on - ¼ off', code: 'R', score: 0.3, vault: [0.5, 'vaulton'], icon: ['vaultoff', 0.5] },
        { isBaseElement: true, group: 'Vault', text: 'Handspring', code: 'H', score: 0.4, rotation: 1, twists: 0, vault: 'vaulton', icon: 'vaultoff' },
        { isBaseElement: true, group: 'Vault', text: 'Handspring ½ on', code: '1H', score: 0.4, rotation: 1, twists: 0, vault: [1, 'vaulton'], icon: 'vaultoff' },
        { isBaseElement: true, group: 'Vault', text: 'Handspring Salto Tucked', code: 'HO', score: 0.8, rotation: 2, twists: 0, vault: 'vaulton', icon: 'tuck' },
        { isBaseElement: true, group: 'Vault', text: 'Handspring Salto Piked', code: 'H>', score: 0.9, rotation: 2, twists: 0, vault: 'vaulton', icon: 'pike' },
        { isBaseElement: true, group: 'Vault', text: 'Handspring Salto Straight', code: 'H\\', score: 1, rotation: 2, twists: 0, vault: 'vaulton', icon: 'straight' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Tucked', code: 'TO', score: 0.8, rotation: 2, twists: 0, icon: 'tsu' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Piked', code: 'T<', score: 0.9, rotation: 2, twists: 0, vault: 'tsu', icon: 'piked' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Straight', code: 'T/', score: 1, rotation: 2, twists: 0, vault: 'tsu', icon: 'str8' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Double Tucked', code: 'TOO', score: 1.6, rotation: 3, twists: 0, vault: 'tsu', icon: 'bwd-double' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Double Piked', code: 'T<<', score: 1.8, rotation: 3, twists: 0, vault: 'tsu', icon: 'bwd-dbl-pike' },
        { isBaseElement: true, group: 'VaultBwd', text: 'Tsukahara Double Straight', code: 'T//', score: 2, rotation: 3, twists: 0, vault: 'tsu', icon: 'bwd-dbl-str8' },
        { isBaseElement: true, group: 'Vault', title: 'Handspring Double Tucked', text: 'Handspring Double Tucked ½', code: 'HOO1', score: 1.7, rotation: 3, twists: 1, vault: 'vaulton', icon: ['double'] },
        { isBaseElement: true, group: 'Vault', title: 'Handspring Double Piked', text: 'Handspring Double Piked ½', code: 'H>>1', score: 1.9, rotation: 3, twists: 1, vault: 'vaulton', icon: ['dbl-pike'] },
        { isBaseElement: true, group: 'Vault', title: 'Handspring Double Straight', text: 'Handspring Double Straight ½', code: 'H\\\\1', score: 2.1, rotation: 3, twists: 1, vault: 'vaulton', icon: ['dbl_straight'] },


        { isBaseElement: true, group: 'Trampette', text: 'Tucked Salto', code: 'O', score: 0.1, rotation: 1, twists: 0, icon: 'tuck' },
        { isBaseElement: true, group: 'Trampette', text: 'Piked Salto', code: '>', score: 0.1, rotation: 1, twists: 0, icon: 'pike' },
        { isBaseElement: true, group: 'Trampette', text: 'Straight Salto', code: '\\', score: 0.2, rotation: 1, twists: 0, icon: 'straight' },

        { isBaseElement: true, group: 'Trampette', text: 'Double Salto Tucked', code: 'OO', score: 0.6, rotation: 2, twists: 0, icon: 'double' },
        { isBaseElement: true, group: 'Trampette', text: 'Double Salto Piked', code: '>>', score: 0.7, rotation: 2, twists: 0, icon: 'dbl-pike' },
        { isBaseElement: true, group: 'Trampette', text: 'Double Salto Straight', code: '\\\\', score: 0.8, rotation: 2, twists: 0, icon: 'dbl-straight' },

        { isBaseElement: true, group: 'Trampette', title: 'Triple Salto Tucked', text: 'Triple Salto Tucked ½', code: 'OOO1', score: 1.5, rotation: 3, twists: 1, icon: 'triple', spread: 'tuck' },
        { isBaseElement: true, group: 'Trampette', title: 'Triple Salto Piked', text: 'Triple Salto Piked ½', code: '>>>1', score: 1.7, rotation: 3, twists: 1, icon: 'tpl-pike', spread: 'pike' },
        { isBaseElement: true, group: 'Trampette', title: 'Triple Salto Straight', text: 'Triple Salto Straight ½', code: '\\\\\\1', score: 1.9, rotation: 3, twists: 1, icon: 'tpl-straight', spread: 'straight' }

    ];


    var disabilityL1Tumble = [
        { group: 'Fwd', text: 'Stretch Jump ¼ turn', code: '¢', score: 0.1, isBaseElement: false, isPredefined: true, icon: 'j90' },
        { group: 'Fwd', text: 'Front Salto Jump Lunge', code: "@", score: 0.3, isBaseElement: false, isPredefined: true, icon: 'saltojumplunge' }
    ];




    var availableTariffs = {
        'J': [
            { code: '401' },
            { code: '601' },
            { code: '801' },
            { code: '802' },
            { code: '1002' },
            { code: '603' },
            { code: '803' },
            { code: '1003' },
            { code: '804' },
            { code: '1004' },
            { code: '1005' },
            { code: '206' },
            { code: '406' },
            { code: '1006' },
            { code: '207' },
            { code: '407' },
            { code: '807' },
            { code: '408' },
            { code: '1008' },
            { code: '409' },
            { code: '809' },
            { code: '1009A' },
            { code: '1009B' },
            { code: '210' },
            { code: '410' },
            { code: '1010' },
            { code: '411' },
            { code: '611' },
            { code: '811' },
            { code: '612' },
            { code: '812A' },
            { code: '812B' },
            { code: '613' },
            { code: '813A' },
            { code: '813B' },
            { code: '614' },
            { code: '814' },
            { code: '415', ds: true },
            { code: '615', ds: true },
            { code: '616', ds: true },
            { code: '816', ds: true },
            { code: '1016', ds: true },
            { code: '617', ds: true },
            { code: '418', ds: true },
            { code: '818', ds: true },
            { code: '1018', ds: true },
            { code: '1019A', ds: true },
            { code: '1019B', ds: true },
            { code: '420', ds: true },
            { code: '820', ds: true },
            { code: '621', ds: true },
            { code: '821', ds: true },
            { code: '222', ds: true },
            { code: '422', ds: true },
            { code: '822', ds: true },
            { code: '1022', ds: true },
            { code: '623', ds: true },
            { code: '823', ds: true },
            { code: '1023', ds: true },
            { code: '624' },
            { code: '824' },
            { code: '1024' },
            { code: '625' },
            { code: '825' },
            { code: '626' },
            { code: '826' },
            { code: '227' },
            { code: '427' },
            { code: '1027', ds: true },
            { code: '828', ds: true },
            { code: '1028', ds: true },
            { code: '1029', ds: true },
        ],
        'F': [
            { code: '401' },
            { code: '801' },
            { code: '802A' },
            { code: '802B' },
            { code: '1003' },
            { code: '1004' },
            { code: '405' },
            { code: '605' },
            { code: '805' },
            { code: '1005' }
        ],
        'G': [
            { code: '601' },
            { code: '1001' },
            { code: '802' },
            { code: '1002' }
        ],
        'HB': [
            { code: '201' },
            { code: '601' },
            { code: '1001' },
            { code: '202' },
            { code: '402' },
            { code: '802' },
            { code: '403' },
            { code: '603' },
            { code: '1003' },
            { code: '204' },
            { code: '404' },
            { code: '604' },
            { code: '804' },
            { code: '405' },
            { code: '805' },
            { code: '1005' }
        ],
        'DB': [
            { code: '201' },
            { code: '601' },
            { code: '801' },
            { code: '1001' },
            { code: '202' },
            { code: '602' },
            { code: '802' },
            { code: '1002' },
            { code: '403' },
            { code: '803' },
            { code: '1003' },
            { code: '604' },
            { code: '1004' },
            { code: '805' },
            { code: '1006' },
            { code: '1007' },
            { code: '1008' },
            { code: '409' },
            { code: '609' },
            { code: '610' },
            { code: '1010' },
            { code: '811' },
            { code: '1011' },
            { code: '612' },
            { code: '812' },
            { code: '1012' },
            { code: '1013' }
        ],
        'SB': [
            { code: '601' },
            { code: '801' },
            { code: '1001' },
            { code: '402' },
            { code: '802' },
            { code: '1002' },
            { code: '603' },
            { code: '803' },
            { code: '204' },
            { code: '404' },
            { code: '604' },
            { code: '804' },
            { code: '1004' },
            { code: '605' },
            { code: '805' },
            { code: '406' },
            { code: '806' },
            { code: '1006' },
            { code: '807' },
            { code: '1007' }
        ],
        'A': [
            { code: '601' },
            { code: '801' },
            { code: '1001' },
            { code: '802' },
            { code: '1002' },
            { code: '603A' },
            { code: '603B' },
            { code: '803A' },
            { code: '803B' },
            { code: '604A', ds: true },
            { code: '604B', ds: false },
            { code: '1004', ds: true },
            { code: '205' },
            { code: '605' },
            { code: '1005' },
            { code: '406', ds: true },
            { code: '1006' },
            { code: '607A' },
            { code: '607B' },
            { code: '607C' },
            { code: '1007A' },
            { code: '1007B' },
            { code: '608' },
            { code: '808' },
            { code: '1008', ds: true },
            { code: '209' },
            { code: '409' },
            { code: '609' },
            { code: '809', ds: true },
            { code: '410' },
            { code: '810' },
            { code: '1010' },
            { code: '411', ds: true },
            { code: '611' },
            { code: '1011' },
            { code: '1012', ds: true },
            { code: '213A', ds: true },
            { code: '213B', ds: true },
            { code: '1013', ds: true },
            { code: '614' },
            { code: '1014' },
            { code: '1015' }
        ]
    };

    var filterIndexOfFour = function (j) {
        return j.code.indexOf('4') === 0 || j.code.indexOf('2') === 0;
    };

    var BGLevel6to5 = {
        'J': [
            { code: '402' },
            { code: '201' },

            { code: '215' },
            { code: '216' },
            { code: '416' }]
            .concat(availableTariffs.J.filter(filterIndexOfFour)),
        'A': [
            { code: '210' },
            { code: '215' }].concat(availableTariffs.A.filter(filterIndexOfFour)),
        'SB': [].concat(availableTariffs.SB.filter(filterIndexOfFour)),
        'DB': [].concat(availableTariffs.DB.filter(filterIndexOfFour)),
        'HB': [].concat(availableTariffs.HB.filter(filterIndexOfFour)),
        'F': availableTariffs.F.map(function (f) { return Object.assign({}, f, { maxScore: 0.4 }); })
    };

    var cop = {
        'Edition2022': {
            FX: {
                D: availableTariffs
            },
            TU: {
                D: tumbleElements
            },
            TR: {
                D: trampetteElements
            }
        },
        BGL5: {
            FX: {
                D: BGLevel6to5,
                C: requirements.filter(function (r) { return r.code !== 4 && r.code !== 5; })
            },
            TU: {
                D: [].concat(bgL5Tumble, tumbleElements.filter(function (t) { return t.score <= 0.25; }).map(function (e) { return Object.assign({}, e, { twists: undefined }); })),
            },
            TR: {
                D: [].concat(bgL5Trampette, trampetteElements.filter(function (t) { return t.score <= 0.25; }).map(function (e) { return Object.assign({}, e, { twists: undefined }); }))
            }
        },
        BGL6: {
            TU: {
                D: [].concat(bgL6Tumble, tumbleElements.filter(function (t) { return t.score <= 0.1; }).map(function (e) { return Object.assign({}, e, { twists: undefined }); })),
            },
            TR: {
                D: bgL6Trampette
            }
        },
        BGPrimaries: {
            FX: {
                D: availableTariffs
            },
            TU: {
                D: [].concat(primaryTumbleElementsBG, tumbleElements)
            },
            TR: {
                D: [].concat(primaryTrampetteElementsBG, trampetteElements)
            }
        },
        BGDisabilityL1: {
            FX: {
                D: availableTariffs
            },
            TU: {
                D: [].concat(disabilityL1Tumble, tumbleElements)
            },
            TR: {
                D: trampetteElements
            }
        }


    };

    tumbleList.$inject = ['routes'];
    function tumbleList(routes) {
        return {
            scope: { tariffs: '=', run: '=', isMicro: '=' },
            templateUrl: routes.templateDirectory('judging/tumble-list')
        };
    }
    tumbleIcon.$inject = ['routes'];
    function tumbleIcon(routes) {
        return {
            scope: { data: '=', twists: '=', size: '=' },
            templateUrl: routes.templateDirectory('judging/tumble-icon')
        };
    }

    judgeTariffTable.$inject = ['routes'];
    function judgeTariffTable(routes) {
        return {
            scope: {
                judgeField: '=',
                tariffs: '=',
                judges: '=',
                judgeScores: '=',
                credits: '=',
                justJudge: '='
            },
            require: '^form',
            link: function (scope, el, attr, ctrl) {
                scope.scoresheet = ctrl;
            },
            templateUrl: routes.templateDirectory('judging/tariff-table'),
            controller: ['$scope', 'tariff', 'activeEntry', 'teamGymTolerances', function ($scope, tariffCredit, activeEntry, teamGymTolerances) {


                var score, parentScore;
                var key = $scope.judgeField.substr(0, 1);

                function findByJudgeNumber(s) {
                    return s.type + s.judgeNumber === $scope.judgeField;
                }

                function findByType(s) {
                    return s.type === key;
                }

                $scope.tariffCredit = tariffCredit;
                $scope.expectedD = $scope.tariffs.reduce(function (a, v) { return a + v.Score; }, 0);
                $scope.updateTariffScores = function () {
                    if (!$scope.justJudge && $scope.tariffs && $scope.judges.submitted) {

                        var judgeScores = activeEntry.getScore().JudgeScores;

                        var scores = $scope.judgeScores[$scope.judgeField];

                        var parentScores = judgeScores.filter(findByJudgeNumber).reduce(function (o, v) {
                            o[v.tariffid] = v;
                            return o;
                        }, {});

                        for (var j in scores) {
                            score = scores[j];
                            if (parentScores.hasOwnProperty(score.tariffid)) {
                                parentScores[score.tariffid].score = score.score;
                            }
                        }


                        // var credit = teamGymTolerances.calculateTariffScores(activeEntry.schema[scores[0].type], $scope.tariffs, scores);
                        // Object.assign($scope.credits, credit);

                        activeEntry.judgeTotal(undefined, undefined, key);
                        $scope.credits = teamGymTolerances.calculateTariffScores(activeEntry.schema[key], $scope.tariffs, activeEntry.getScore().JudgeScores.filter(findByType));
                    }
                };

            }]
        };
    }

    allJudgesTariffTable.$inject = ['routes'];
    function allJudgesTariffTable(routes) {
        return {
            scope: {
                tariffs: '=',
                judges: '=',
                judgeScores: '=',
                credits: '=',
                originalScores: '='
            },
            templateUrl: routes.templateDirectory('judging/all-judge-tariff-table'),
            controller: ['$scope', 'tariff', 'activeEntry', 'teamGymTolerances', function ($scope, tariffCredit, activeEntry, teamGymTolerances) {


                var score, parentScore;
                function findMatchToScore(s) {
                    return s.tariffid === score.tariffid;
                }
                function findByJudgeNumber(key) {
                    return function (s) {
                        return s.type + s.judgeNumber === key;
                    };
                }

                function findByType(s) {
                    return s.type === 'D';
                }

                $scope.scoreOptions = ['N', 'H', 'F'];
                $scope.tariffCredit = tariffCredit;
                $scope.expectedD = $scope.tariffs.reduce(function (a, v) { return a + v.Score; }, 0);
                $scope.updateTariffScores = function (key) {
                    if ($scope.tariffs) {

                        var judgeScores = activeEntry.getScore().JudgeScores;

                        var scores = $scope.judgeScores[$scope.judgeField];

                        var parentScores = judgeScores.filter(findByJudgeNumber(key)).reduce(function (o, v) {
                            o[v.tariffid] = v;
                            return o;
                        }, {});

                        for (var j in scores) {
                            score = scores[j];
                            if (parentScores.hasOwnProperty(score.tariffid)) {
                                parentScores[score.tariffid].score = score.score;
                            }
                        }



                        // var credit = teamGymTolerances.calculateTariffScores(activeEntry.schema[scores[0].type], $scope.tariffs, scores);
                        // Object.assign($scope.credits, credit);

                        activeEntry.judgeTotal(undefined, undefined, 'D');
                        $scope.credits = teamGymTolerances.calculateTariffScores(activeEntry.schema.D, $scope.tariffs, activeEntry.getScore().JudgeScores.filter(findByType));
                    }
                };

            }]
        };
    }

    var twistBonus = [
        0, // dummy
        0.1, //single salto
        0.1, // double salto
        0.2, // triple salto
    ];

    var tripleFullTwistBonus = 0.1;


    var tumbleCodePattern = /(?<code>[¢@*CcçÇKxNV^AWXRHI]|FS?|O+|o+|\/+|\\+|>+|<+)+(?<twists>\d)?/g;
    var trampetteCodePattern = /(?<code>[*NVA^]|O+|\/+|\\+|>+|<+)+(?<twists>\d)?/g;


    var trampetteVaultCodePattern = /Q|1H|H|T|R/;


    function createCodeElements(t, isTrampette, cop) {
        var elements = [];

        if (t.Code) {
            t.Code.split(' ').forEach(function (code, i) {
                var data;
                var baseCode;
                var matches;

                var twists = [0, 0];
                var codeParts = [];
                var vault;

                if (isTrampette) {
                    vault = code.match(trampetteVaultCodePattern);
                    matches = code.matchAll(trampetteCodePattern);
                } else {
                    matches = code.matchAll(tumbleCodePattern);
                }

                if (matches) {
                    var matchCollection = Array.from(matches);
                    var matchCount = matchCollection.length;

                    matchCollection.forEach(function (match, i, a) {
                        if (match.groups.twists) {
                            if (matchCount === 1) {
                                twists[0] = 0;
                                twists[1] = +match.groups.twists;
                            } else {
                                twists[i] = +match.groups.twists;
                            }
                        } else {
                            twists[i] = 0;
                        }
                        codeParts.push(match.groups.code);
                    });

                } else {

                    if (vault) {
                        // its a vault with no somi;
                        var somiCode = code.replace(vault, '');
                        if (somiCode.length > 0) {
                            twists = [0, parseInt(somiCode, 10)];
                        }
                    }

                }


                baseCode = codeParts.join('');

                if (isTrampette) {
                    var twistCount = twists.reduce(function (v, o) {
                        return v + o;
                    }, 0);
                    if (twistCount && twistCount >= 1) {
                        data = cop.find(function (t) {
                            return (t.isBaseElement || t.isPredefined) &&
                                t.code === (vault || '') +
                                getPiecesCode(baseCode, [0, 1]);
                        });
                    }
                }
                if (!data) {
                    data = cop.find(function (t) {
                        return (t.isBaseElement || t.isPredefined) && t.code === (vault || '') + baseCode;
                    });
                }


                if (data) {
                    elements.push({
                        data: data,
                        code: code,
                        twistCount: data.rotation ? twists : null //might be undefined?
                    });
                }

            });
        }
        return elements;
    }
    function piecesTariffService() {
        return {
            convertCodeToElements: createCodeElements
        };
    }

    function getPiecesCode(code, twists) {
        if (!twists) {
            return code;
        }

        var codeElements = code.replace(/\d?$/, '').split('');
        var len = codeElements.length;
        var twistLen = twists.length;

        if (len === 0) {
            //if len is zero there is no somi, but its still twistable
            return twists[1] || '';
        }

        if (len === 1) {
            return codeElements.join('') + (twists[1] || '');
        }
        if (len === twistLen) {
            return codeElements.map(function (v, i) { return v + (twists[i] || ''); }).join('');
        }
        if (len > twistLen) {
            //triple not spread
            return codeElements[0] + (twists[0] || '') + codeElements.slice(1).join('') + (twists[1] || '');
        }

    }

    angular.module('app')
        .directive('judgeTariffTable', judgeTariffTable)
        .directive('allJudgesTariffTable', allJudgesTariffTable)
        .directive('tumbleList', tumbleList)
        .directive('tumbleIcon', tumbleIcon)
        .service('piecesTariffService', piecesTariffService)
        .constant('tgCoP', cop)
        .constant('tgTariffCodes', availableTariffs)
        .constant('requirements', requirements);

}());