(function () {
    'use strict';

    angular.module('app')
        .controller('TimerController', MessageController)
        .filter('secondsToDateTime', [function () {
            return function (seconds) {
                return new Date(seconds);

            };
        }]);

    MessageController.$inject = ['$scope', 'scoreboardTicker', '$interval'];
    function MessageController($scope, ws, $interval) {
        //  var clientPushHubProxy = signalRHubProxy(signalRHubProxy.defaultServer, 'messagePushHub', { logging: true }); //receives.
         
        var onIdentities = ws.on("Identities", updateClients);
        var msgCountdown = ws.on('countdown', rotationWarmUp);
        var msgHideCountdown = ws.on('hidecountdown', cancelRotationWarmup);

        var defaultTitles = [
            { title: '(no title)', minutes: 5 },
            { title: 'warm up', minutes: undefined },
            { title: 'orientation', minutes: undefined },
            { title: 'apparatus warm up', minutes: undefined }
        ];

        var timerConfig = new Store('timerConfig');
        Object.assign(defaultTitles, timerConfig.first());

        var vm = this;
        vm.msg = { time: {}, title: '' };
        vm.recipients = {};
        vm.clients = [];

        vm.noSelections = noSelections;
        vm.sendMessage = sendMessage;
        vm.setTime = setTime;
        vm.setTimeIfValue = setTimeIfValue;
        vm.saveDefaultMinutes = saveDefaultMinutes;

        setTime(true);
       

        vm.defaultTitles = defaultTitles;
        $scope.$on('$destroy', function () {
            ws.off('GetIdentities', onIdentities);
            if (msgCountdown) { ws.off('countdown', msgCountdown); }
            if (msgHideCountdown) { ws.off('hidecountdown', msgHideCountdown); }
            if (int) {
                $interval.cancel(int);
            }
        });
        ws.invoke('GetIdentities', '');


        function getRecipients() {
            var recipients = [], key;

            for (key in vm.recipients) {
                if (vm.recipients.hasOwnProperty(key)) {
                    if (vm.recipients[key] === true) {
                        recipients.push(key);
                    }
                }
            }
            return recipients;
        }

        function setTime(setDefault) {
            if (setDefault) {
                Object.assign(vm.msg.time, {
                  hours: 0,
                  minutes: 5,
                  seconds: 0
                });
              }
              var time = (vm.msg.time.hours * 3600000) + (vm.msg.time.minutes * 60000) + (vm.msg.time.seconds * 1000);
              vm.time = new Date(time);
              vm.totalSeconds = time;
              vm.pending = true;
        }

       function setTimeIfValue(minutes) {
            if (minutes) {
              Object.assign(vm.msg.time, {
                hours: 0,
                minutes: minutes,
                seconds: 0
              });
              setTime();
            }
          }
        ///param: defaultTitle: { title: string; minutes: number; }
        function  saveDefaultMinutes(defaultTitle) {
            if(defaultTitle.title === vm.msg.title){
              setTimeIfValue(defaultTitle.minutes);
            }
            timerConfig.update(Object.assign(timerConfig.first() || {},defaultTitles));
          }


        function noSelections() {
            
            var result = Object.values(vm.recipients).indexOf(true) === -1;
            return result;
        }

        function sendMessage(recipients) {
            var data;
            vm.showCancel = true;
            if (recipients) {
                data = angular.extend({ recipients: getRecipients() }, vm.msg);
                vm.cancelCountdown = function () {
                    ws.invoke('HideCountdown', data);
                };
                ws.invoke('ShowCountdown', data);
            } else {
                vm.cancelCountdown = function () {
                    ws.invoke('HideCountdown', {});
                };
                ws.invoke('ShowCountdown', vm.msg);
            }
        }

        function updateClients(data) {
            var result = [];
            var oldData = vm.recipients; //boolean values
            vm.recipients = {};
            angular.forEach(data, function (d) {
                if (d !== 'tea-printer') {
                    result.push(d);
                    vm.recipients[d] = oldData[d] || false;
                }
            });
            vm.clients = result;
        }
        var int;
        function cancelRotationWarmup() {
            if (int) {
                $interval.cancel(int);
            }

            var time = (vm.msg.time.hours * 3600000) + (vm.msg.time.minutes * 60000) + (vm.msg.time.seconds * 1000);
            vm.time1 = new Date(time);
            vm.totalSeconds1 = time;
            vm.showCancel = false;
            vm.pending = true;

        }
        function rotationWarmUp(data) {
            vm.msg1 = data;
            vm.pending = false;
            if (int) {
                $interval.cancel(int);
            }
            var startTime;
            var interval = 1000;

            vm.totalSeconds1 = (data.time.hours * 3600000) + (data.time.minutes * 60000) + (data.time.seconds * 1000);
            vm.timerTitle1 = data.title;
            startTime = new Date().setMilliseconds(0) + vm.totalSeconds1;
            int = $interval(doInterval, interval);
            vm.time1 = vm.totalSeconds1;
            function doInterval() {
                var time = startTime - new Date().setMilliseconds(0);
                if (time <= 0) {
                    time = 0;
                    $interval.cancel(int);
                    
                }
                vm.time1 = new Date(time);

            }


        }

    }


}());