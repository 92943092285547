/**
 * Created by kevinpayne on 12/03/16.
 */
(function () {
    angular.module('app.competitionAdmin', ['ngRoute', 'app.API'])
        .value('competitionAdminTemplates', templateDirectory).config(routing);

    function templateDirectory(template, dynamic) {
        return '/templates/competitionAdmin/' + template + ((!dynamic) ? '.html' : '');
    }

    routing.$inject = ['$routeProvider'];
    function routing($routeProvider) {
        $routeProvider 
            .when('/manage-competition', {
                controller: 'CompetitionHomeController',
                controllerAs: 'view',
                templateUrl: templateDirectory('list-competitions'),
                resolve: {
                    allow: requireAuthorization,
                    competitions: getCompetitions
                }
            })
            .when('/manage-competition/import-data', {
                controller: 'DataImportController',
                controllerAs: 'view',
                templateUrl: templateDirectory('upload-csv'),
                resolve :{
                    allow: requireAuthorization,
                    competition: function() {return undefined;}
                }
            })
            .when('/manage-competition/import-data/:id', {
                controller: 'DataImportController',
                controllerAs: 'view',
                templateUrl: templateDirectory('upload-csv'),
                resolve :{
                    allow: requireAuthorization,
                    competition: getCompetition
                }
            })
            .when('/manage-competition/create', {
                controller: 'EditCompetitionController',
                controllerAs: 'view',
                templateUrl: templateDirectory('competition-form'),
                resolve: {
                    allow: requireAuthorization,
                    competition: ['webAPI', function (webAPI) {
                        return new webAPI.competitions();
                    }],
                    disciplines: function () { return []; },
                    rounds: function(){return []; }
                }
            })
            .when('/manage-competition/:id', {
                controller: 'EditCompetitionController',
                controllerAs: 'view',
                templateUrl: templateDirectory('competition-form'),
                resolve: {
                    allow: requireAuthorization,
                    competition: getCompetition,
                    disciplines: getDisciplinesWithGroups,
                    rounds: getRounds
                }
            })
            .when('/manage-competition/:id/new-group', {
                controller: 'NewGroupController',
                controllerAs: 'view',
                templateUrl: templateDirectory('list-entries'),
                //template: '<div group-form group="view.group" competition="view.competition"></div>',
                resolve: {
                    allow: requireAuthorization,
                    competition: getCompetition
                }
            })
            .when('/manage-competition/:id/group/:grp', {
                controller: 'EditEntriesController',
                controllerAs: 'view',
                templateUrl: templateDirectory('list-entries'),
                resolve: {
                    allow: requireAuthorization,
                    entrants: getEntrantsForGroup,
                    group: getGroup,
                    competition: getCompetition
                }
            })
            .when('/manage-competition/:id/discipline/:dis', {
                controller: 'EditEntriesController',
                controllerAs: 'view',
                templateUrl: templateDirectory('list-entries'),
                resolve: {
                    allow: requireAuthorization,
                    clubs: getClubs,
                    entrants: getEntrantsForDiscipline,
                    disciplines: getDisciplinesWithGroups
                }
            }).when('/manage-competition/:id/create-entry', {
                controller: 'EditEntryController',
                controllerAs: 'view',
                templateUrl: templateDirectory('edit-entry'),
                resolve: {
                    allow: requireAuthorization,
                    clubs: getClubs,
                    entry: createEntry,
                    disciplines: getDisciplinesWithGroups,
                    competition: getCompetition
                }
            }).when('/manage-competition/:id/create-entry/for-group/:grp', {
                controller: 'EditEntryController',
                controllerAs: 'view',
                templateUrl: templateDirectory('edit-entry'),
                resolve: {
                    allow: requireAuthorization,
                    clubs: getClubs,
                    entry: createEntry,
                    disciplines: getDisciplinesWithGroups,
                    competition: getCompetition
                    //  group: getGroup
                }
            })
            .when('/manage-competition/:id/create-entry/for-discipline/:dis', {
                controller: 'EditEntryController',
                controllerAs: 'view',
                templateUrl: templateDirectory('edit-entry'),
                resolve: {
                    allow: requireAuthorization,
                    clubs: getClubs,
                    entry: createEntry,
                    disciplines: getDisciplinesWithGroups,
                    competition: getCompetition
                    // group: function () { }
                }
            })
            .when('/manage-competition/:id/entry/:entry', {
                controller: 'EditEntryController',
                controllerAs: 'view',
                templateUrl: templateDirectory('edit-entry'),
                resolve: {
                    allow: requireAuthorization,
                    clubs: getClubs,
                    entry: getEntry,
                    disciplines: getDisciplinesWithGroups,
                    competition: getCompetition
                    // group: function () { }
                }
            })
            ;

        getEntrantsForGroup.$inject = ['webAPI', '$route', '$q'];
        function getEntrantsForGroup(api, $route, $q) {

            return api.entries.forGroup({
                competition: $route.current.params.id,
                id: $route.current.params.grp
            }).$promise;
        }
        getEntrantsForDiscipline.$inject = ['webAPI', '$route', '$q'];
        function getEntrantsForDiscipline(api, $route, $q) {

            return api.entries.forDiscipline({
                competition: $route.current.params.id,
                id: $route.current.params.dis
            }).$promise;
        }

        getCompetitions.$inject = ['webAPI'];
        function getCompetitions(api) {
            return api.competitions.query().$promise;
        }

        getCompetition.$inject = ['webAPI', '$route'];
        function getCompetition(api, $route) {
            return api.competitions.get({ id: $route.current.params.id }).$promise;
        }

        getRounds.$inject = ['webAPI', '$route'];
        function getRounds(api, $route) {
            return api.rounds.query({ id: $route.current.params.id }).$promise;
        }

        getDisciplinesWithGroups.$inject = ['webAPI', '$route'];
        function getDisciplinesWithGroups(api, $route) {
            return api.disciplines.forCompetitionWithGroups({ competition: $route.current.params.id }).$promise;
        }

        getDisciplines.$inject = ['webAPI', '$route'];
        function getDisciplines(api, $route) {
            return api.disciplines.forCompetition({ competition: $route.current.params.id }).$promise;
        }

        getGroup.$inject = ['webAPI', '$route'];
        function getGroup(api, $route) {
            return api.groups.withEventGroup({ id: $route.current.params.grp, competition: $route.current.params.id }).$promise;
        }

        getClubs.$inject = ['webAPI'];
        function getClubs(api) {
            return api.clubs.query().$promise;
        }

        createEntry.$inject = ['$route', 'webAPI'];
        function createEntry($route, api) {
            var entry = new api.entries();
            entry.CompetitionId = parseInt($route.current.params.id, 10);
            if ($route.current.params.grp) {
                entry.GroupId = parseInt($route.current.params.grp, 10);
            }
            entry.ClubId = undefined;
            return entry;
        }
        getEntry.$inject = ['webAPI', '$route'];
        function getEntry(api, $route) {
            return api.entries.getWithoutScores({
                competition: parseInt($route.current.params.id, 10),
                id: parseInt($route.current.params.entry, 10)
            }).$promise;
        }
        requireAuthorization.$inject = ['isAuthorized'];
        function requireAuthorization(allow) {
            return allow(['?']);
        }
    }

}());