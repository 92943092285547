/**
 * Created by kevinpayne on 18/01/15.
 */
(function (app) {
    app.service('user', UserService);
    app.service('requestInterceptor', ['$window', '$q', '$injector', function ($window, $q, $injector) {
        function makeid(len) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < len; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        function calcMac(token, artifacts) {
            var items;
            items = ['hawk.1.header', artifacts.ts, artifacts.nonce, artifacts.method, artifacts.path, artifacts.host, artifacts.port, artifacts.hash, artifacts.ext, ''].join('\n');

            return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(items, token[1]));
        }

        function createAuth(config, time) {

            var artifacts = {}, token = $window.sessionStorage.getItem('token'), sendToken = false;

            if (!token || token === 'null') {
                return config;
            }

            if (config.url.indexOf('/templates') > -1 && config.url.indexOf('.html') === -1) {
                sendToken = true;
            }


            if (config.url.indexOf('/api') === -1 && !sendToken) {
                return config;
            }
            token = token.split(':');
            artifacts.port = $window.location.port;
            artifacts.host = $window.location.hostname;
            artifacts.method = config.method;
            artifacts.path = config.url;
            artifacts.hash = undefined;
            artifacts.ext = undefined;
            artifacts.id = token[0];
            artifacts.ts = Math.floor(time / 1000);
            artifacts.nonce = makeid(6);

            mac = calcMac(token, artifacts);
            config.headers.Authorization = 'Hawk id="' + artifacts.id + '", ts="' + artifacts.ts + '", nonce="' + artifacts.nonce + '", mac="' + mac + '"';
            return config;
        }


        return {

            request: function (config) {
                return createAuth(config, Date.now());
            }
            //, //todo: this doesn't work, it creates a loop. Probably means the math is wrong way round?
            //responseError: function (response) {
            //    if (response.status === 500 && response.data && response.data.message === "Stale timestamp") {
            //        var servertime = new Date(response.headers('Date')).getTime(), now = Date.now();
            //        if (!isNaN(servertime)) {
            //
            //            var $http = $injector.get('$http');
            //            return $http(createAuth(response.config, (now - (now - servertime))));
            //        }
            //
            //    }
            //    return $q.reject(response);
            //}
        };

    }]);

    app.service('authorize', ['$modal', function ($modal) {
        return function () {
            return $modal.open({
                templateUrl: '/templates/pass.html',
                controller: ['$scope', '$modalInstance', 'user', function ($scope, $modalInstance, user) {
                    $scope.user = user;
                    $scope.loginSuccess = $modalInstance.close;
                }]
            });
        };
    }]);

    app.service('isAuthorized', ['webAPI', '$q', 'routes', function (webAPI, $q, routes) {
        return function (accessType) {
            var defer = $q.defer();
            if (accessType && accessType[0] !== '*') {
                if (accessType[0] === '?') {
                    webAPI.authorize.status(function () {
                        defer.resolve(true);
                    }, function () {
                        defer.reject({ status: 401 });
                    });
                } else {

                    if (accessType.indexOf('admin') > -1 ||
                        accessType.indexOf('door') > -1 ||
                        accessType.indexOf('judge') > -1 ||
                        accessType.indexOf('host') > -1) {
                        webAPI.authorize.minrole({ role: accessType.join('+') }, function () {
                            defer.resolve(true);
                        }, function (err) {
                            defer.reject({ status: err.status });
                        });
                    } else {
                        defer.reject({ status: 401 });
                    }
                }
            }
            else {
                defer.resolve(true);
            }

            return defer.promise;
        };
    }]);


    UserService.$inject = ['webAPI'];

    function UserService(webAPI) {

        var user = new webAPI.user().loadCache();
        return user;

    }


}(angular.module('app')));