
/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    angular.module('app')
        .directive('judgeForm', judgeForm)
        .directive('scoreWhenDirty', scoring)
        .controller('JudgeFormController', JudgeFormController);

   

    scoring.$inject = ['judgeStatus', 'activeEntry', 'activeJudges', 'user'];
    function scoring(judgeStatus, activeEntry, activeJudges, user) {

        function updateStatus(newvalue) {
            var entry, score, exercise;
            if (newvalue) {

                score = activeEntry.getScore();

                if (score.HasScore || !user.headJudge) {
                    return;
                }
                entry = activeEntry.get();
                exercise = activeEntry.getExercise();
                if (judgeStatus.status() && judgeStatus.status() !== judgeStatus.statusType.scoring) {
                    judgeStatus.scoring(entry, exercise);
                }

            }
        }

        return function (scope, el) {
            scope.$watch('scoresheet.$dirty', updateStatus);
        };

    }

    judgeForm.$inject = ['$routeParams', 'routes'];
    function judgeForm($routeParams, routes) {
        return {
            controller: JudgeFormController,
            templateUrl: function (el, attr) {
                var url = routes.templateDirectory('judge');
                var category = $routeParams.category && $routeParams.category.toLowerCase() || attr.category;


                return url;
            }
        };
    }

    JudgeFormController.$inject = ['$scope', 'activeEntry', 'judgeStatus', 'activeJudges'];
    function JudgeFormController($scope, activeEntry, judgeStatus, activeJudges) {


        var entry = activeEntry.get();
        var score = activeEntry.getScore();
        var exercise = activeEntry.getExercise();

        $scope.activeEntry = activeEntry;
        $scope.saveComplete = saveComplete;
        $scope.cancelScore = revertScore;
        $scope.activeJudges = activeJudges;
        $scope.judgeEnter = activeJudges.focus;
        $scope.judgeExit = activeJudges.blur;

        if (!$scope.Discipline) {
            $scope.Discipline = entry.Discipline || entry.Group.Discipline;
        }

        if (exercise.$promise) {
            exercise.$promise.then(function (e) {
                setUpSchema();
                return e;
            });
        } else {
            setUpSchema();
        }


        function setPasses(exercise, score) {

            if ((score.Passes || 1) < exercise.Passes) {
                score.Passes = exercise.Passes;
            }

            return new Array(exercise.Passes > 1 ? 2 : 1);

        }

        function setUpSchema() {
            var schema = activeEntry.schema;
            var viewSchema = [];
            var exercise = activeEntry.getExercise();
            var score = activeEntry.getScore();

            angular.forEach(schema, function (s) {
                viewSchema.push(s);
            });

            $scope.score = score;
            $scope.exercisePasses = setPasses(exercise, score);
            $scope.scoringSchema = viewSchema;
        }

        function saveComplete() {
            activeEntry.update();
            activeEntry.clear();
            judgeStatus.clear();
            activeJudges.clear();
        }

        function revertScore() {
            var score = activeEntry.getScore();
            activeJudges.revert(score.Id, false);
        }

    }

}());