(function (app) {
    'use strict';

    // Specify SignalR server URL here for supporting CORS
    
  
    //app.value('signalRServer', 'http://myserver.com:7778');

    app.service('scoreboardTicker', ['socketIOProxy', function(messaging){

        return messaging('scoreboardTicker');

    }]); 


    app.factory('socketIOProxy',['$rootScope', 'signalRServer', '$q', 'Store', function($rootScope, signalRServer, $q, Store){

        function wrapCallback(callback){
            return function(result){
                $rootScope.$apply(function () {
                    if (callback) {
                        callback(result);
                    }
                });

            };

        }

        function socketIOProxyFactory(hubName, startOptions){
                var connection = io.connect(signalRServer + hubName), id = new Store('ws_identity');
          
                function setId(value) {
                    var model = id.first();
                    if (!model) {
                        id.create({ value: value });
                    } else {
                        model.value = value;
                        id.update(model);
                    }
                    identify();
                }
                function getId() {
                    var model = id.first();
                    if (!model) {
                        return '';
                    }
                    return model.value;
                }
                function identify() {
                    var ws_id = getId();
                    if (ws_id) {
                        connection.emit('Identify',ws_id);
                    }
                }
                connection.on('connect', identify);



                return {
                    setIdentity: setId ,
                    getIdentity: getId,
                on: function(eventName, callback){
                    var cb = wrapCallback(callback);
                    connection.on(eventName, cb);
                    return cb;
                },
                off: function(eventName, cb) {
                    connection.off(eventName, cb);
                },
                invoke: function(methodName, data){
                    var promise = $q.defer();
                    connection.emit(methodName, data, function(result){
                        promise.resolve(result);
                    });

                    return promise.promise;

                },
                connection: connection

            };
        }
        return socketIOProxyFactory;

    }]);


    app.factory('signalRHubProxy', ['$rootScope', 'signalRServer', '$q',
        function ($rootScope, signalRServer, $q) {

            function wrapCallback(callback){
                return function(result){
                    $rootScope.$apply(function () {
                        if (callback) {
                            callback(result);
                        }
                    });

                };

            }

            function signalRHubProxyFactory(serverUrl, hubName, startOptions) {
               var connection = $.hubConnection(signalRServer);
               var proxy = connection.createHubProxy(hubName);
               connection.start(startOptions).done(function () { });
                return {
                    on: function (eventName, callback) {
                        var cb = wrapCallback(callback);
                        proxy.on(eventName, cb);
                        return cb;
                    },
                    off: function (eventName, callback) {
                        proxy.off(eventName, callback);
                    },
                    invoke: function (methodName, data) {
                        var promise = $q.defer();
                        proxy.invoke(methodName, data)
                            .done(function (result) {
                                promise.resolve(result);
                                //$rootScope.$apply(function () {
                                //    if (callback) {
                                //        callback(result);
                                //    }
                                //});
                            });

                        return promise.promise;
                    },
                    connection: connection
                };
            }

            return signalRHubProxyFactory;
        }]);


}(angular.module('app.shared')));