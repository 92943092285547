/**
 * Created by kevinpayne on 08/07/15.
 */
(function (app) {
	'use strict';
	
	angular.module('app')
	.controller('IdentifyController', IdentifyController);
	
	
	IdentifyController.$inject = ['scoreboardTicker', '$routeParams', '$location'];
	function IdentifyController(ws, $routeParams, $location) {
		var vm = this;
		vm.deviceid = vm.identity = ws.getIdentity();
		vm.set = setId;
		
		if ($routeParams.identity) {
			vm.identity = $routeParams.identity;
			setId();
			if ($routeParams.next) {
				$location.path($routeParams.next);
			}
		}			
		
		function setId() {
			ws.setIdentity(vm.identity);
			vm.deviceid = vm.identity;
		}
	}


}());