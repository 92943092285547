/**
 * Created by kevinpayne on 12/03/16.
 */
(function () {
    "use strict";
    angular.module('app.competitionAdmin')
        .controller('EditCompetitionController', EditCompetitionController);

    EditCompetitionController.$inject = ['competition', 'disciplines', 'viewstatus', '$location', 'SystemCategories', 'rounds', 'webAPI'];
    function EditCompetitionController(competition, disciplines, viewstatus, $location, categories, eventRounds, webAPI) {
        var vm = this;
        competition.Start = new Date(competition.Start);
        competition.End = new Date(competition.End);
        vm.model = angular.copy(competition);
        vm.disciplines = disciplines;
        vm.modelUpdated = update;
        vm.save = saveCompetition;
        vm.categories = categories;
        vm.rounds = createRounds();
        vm.addRound = addRound;
        vm.saveRound = saveRounds;
        vm.cancelRounds = cancelRounds;

        function createRounds() {
            return eventRounds.map(function (r) {
                return Object.assign({}, r);
            });
        }

        function cancelRounds() {
            vm.rounds = createRounds();
            vm.editRounds = false;
        }
        function saveCompetition() {
            viewstatus.startLoading();
            competition.$save().then(function (d) {
                viewstatus.create('Competition saved successfully', 'success');
                return d;
            }).catch(viewstatus.displayWebApiError)['finally'](viewstatus.stopLoading);
        }

        function update(model, r) {
            viewstatus.create('Competition saved successfully', 'success');
            if (!competition.Id) {
                $location.path('/manage-competition/' + r.EventId).replace();
            }
        }
        function addRound() {
            var lastRound = {
                EventId: competition.Id,
                RoundNumber: 0
            };

            if (vm.rounds.length > 0) {
                lastRound = vm.rounds[vm.rounds.length - 1];
            }

            vm.rounds.push(Object.assign({}, lastRound, { Name: undefined, RoundNumber: lastRound.RoundNumber + 1, Active: false, RoundId: Date.now() * -1 }));
        }
        function saveRounds() {
            viewstatus.startLoading();
            webAPI.rounds.savemultiple({
                EventId: competition.Id,
                data: vm.rounds.map(function (r) {
                    var data = {
                        NextRound: r.RoundNumber + 1,
                        RoundNumber: r.RoundNumber,
                        Name: r.Name,
                        Date: r.Date,
                        EventId: r.EventId,
                        Active: r.Active,
                        ActiveRotation: r.ActiveRotation,
                        CategoryId: r.CategoryId,
                        MaxRotations: r.MaxRotations
                    };
                    if (r.RoundId > 0) {
                        data.RoundId = r.RoundId;
                    }
                    return data;

                })
            }).$promise.then(function (result) {
                eventRounds = result;
                cancelRounds();
            })['finally'](function () {
                viewstatus.stopLoading();
            });

        }
    }

}());