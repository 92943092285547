/**
 * Created by work on 14/02/16.
 */
(function(){
   "use strict";
    angular.module('app')
        .controller('TeaController', TeaController);

    var notification;

    TeaController.$inject = ['scoreboardTicker', '$scope', 'viewstatus', 'user', 'locations'];
    function TeaController(messaging, $scope, viewstatus, user, locations) {
        var vm = this, online = false;

        vm.request = requestTea;
        vm.model = user.tea;
        vm.locations = locations;


        var m = messaging.on('teasmaidActive', function (data) {
            if (!data) {
                viewstatus.create('Request printer is offline', 'warning');
            } else {
                if (!online) {
                    viewstatus.create('Request printer is online', 'success');
                    online = true;
                }
            }
        });
        $scope.$on('$destroy', function () {
            messaging.off('teasmaidActive', m);
        });
        messaging.invoke('queryTeasmaid');


        function requestTea() {
            user.tea.time = new Date().toLocaleTimeString();
            user.cache();
            messaging.invoke('getTea', user.tea).then(function () {
                viewstatus.create('Printing request', 'success');
            });
        }
    }
}());