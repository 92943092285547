(function (app) {
    var templateDirectory = function (template, dynamic) { return '/templates/' + template + ((!dynamic) ? '.html' : ''); };
    var template = '/templates/display/index.html';

    app.constant('routes', {
        root: '/',
        scorekeeper: '/scorekeeper',
        entrant: '/entrant',
        entrantInfo: '/entry-info',
        artisticJudging: '/judge/:category/:discipline/:exercise',
        judging: '/judge/:category/:exercise',
        tumble: '/judge/:category/tumbling',
        acro: '/judge/:category/acrobatics',

        listArtistic: '/list/:category/:discipline/:exercise',
        listTumble: '/list/:category/tumbling',
        listAcro: '/list/:category/acrobatics',
        list: '/list/:category/:exercise',
        musiclist: '/music/list',
        registrationList: '/registration/list',
        judgeEntrantSearch: '/judge',
        report: '/report',
        tariffSheets: '/report/:id/tariff-sheets/:discipline',
        topScores: '/top-scores',
        peter: '/peter-trophy',

        executionsReport: '/execution-report',

        totalScores: '/total-scores',
        message: '/message',
        withdraw: '/withdraw-entry',
        withdrawn: '/withdrawn-entries',
        login: '/login',
        register: '/create-:role',
        watchApparatus: '/watch-apparatus',
        watchScores: '/watch-scores',
        watchRoundScores: '/watch-scores/:rnd',
        runningInfo: '/running-order',
        pendingScores: '/pending-scores',
        requestTea: '/tea',
        listUsers: '/active-users',
        identify: '/identify',
        identifyUser: '/identify/:identity',
        identifyUserRedirect: '/identify/:identity/:next',
        notfound: '/not-found',
        manageusers: '/manage-users',
        timer: '/timer',
        clubTariffSheets: '/tariff-sheets/:competition/:key',

        buildRoute: function (name, model) {
            var url;
            if (!this.hasOwnProperty(name)) {
                return this.root;
            }
            url = this[name];
            if (model) {
                url = url.replace(/:([^\/]*|$)/g, function (i, v) {
                    if (model.hasOwnProperty(v)) {
                        return model[v];
                    }


                    return '';
                });
            }
            return url;
        },
        templateDirectory: templateDirectory

    });





    app.config(['$routeProvider', 'routes', function ($routeProvider, routes) {

        var identify = {
            controller: 'IdentifyController',
            templateUrl: templateDirectory('identify'),
            controllerAs: 'view',
            resolve: {
                allow: requireAdminAuthorization,
                title: returnTitle("Identify Device")
            }
        };

        $routeProvider
            .when(routes.root, {
                templateUrl: templateDirectory('dashboard', true),
                controller: 'DashboardController',
                title: "test",
                resolve: {

                    title: returnTitle("Scoreboard Dashboard"),
                    categories: getApparatus
                }
            })
            .when(routes.login, {
                templateUrl: templateDirectory('login'),
                controller: 'AuthorizeController',
                resolve: {
                    allow: openAccess,
                    title: returnTitle("Scoreboard: Login")
                }
            })
            .when(routes.register, {
                templateUrl: templateDirectory('users/register'),
                controller: 'RegisterController',
                controllerAs: 'view',
                resolve: {
                    allow: openAccess,
                    title: returnTitle("Scoreboard: Register"),
                    locations: getLocations
                }
            })
            .when(routes.tumble, {
                templateUrl: templateDirectory('judging'),
                controller: 'JudgingController',
                controllerAs: 'judging',
                resolve: {
                    allow: requireJudgeAuthorization,
                    exercise: function () {
                        return { Name: 'Tumbling' };
                    },
                    title: returnTitle('Judge Tumbling')
                }
            })
            .when(routes.acro, {
                templateUrl: templateDirectory('judging'),
                controller: 'JudgingController',
                controllerAs: 'judging',
                resolve: {
                    allow: requireJudgeAuthorization,
                    exercise: function () {
                        return { Name: 'Acrobatics' };
                    },
                    title: returnTitle('Judge Acrobatics')
                }
            })
            .when(routes.artisticJudging, {
                templateUrl: templateDirectory('judging'),
                controller: 'JudgingController',
                controllerAs: 'judging',
                resolve: {
                    allow: requireJudgeAuthorization,
                    exercise: ['webAPI', '$route', function (api, $route) {
                        return api.exercise.validateName({ ex: $route.current.params.exercise }).$promise;
                    }],
                    title: returnTitle('Judge Artistic')
                }

            })
            .when(routes.tariffSheets, {
                templateUrl: templateDirectory('judging/tariff-pack'),
                controller: 'TariffPackController',
                controllerAs: 'report',
                resolve: {
                    allow: requireJudgeAuthorization,
                    title: returnTitle('Tariff Sheets'),
                    exercises: ['webAPI', '$route', function (api, $route) {
                        return api.exercise.forCompetitionAndDiscipline({
                             id: $route.current.params.id ,
                             discipline: $route.current.params.discipline
                            }).$promise;
                    }],
                    rounds: ['webAPI', '$route', function (api, $route) {
                        return api.rounds.query({
                             id: $route.current.params.id 
                            }).$promise;
                    }],
                }
            })
            .when(routes.judging, {
                templateUrl: templateDirectory('judging'),
                controller: 'JudgingController',
                controllerAs: 'judging',
                resolve: {
                    allow: requireJudgeAuthorization,
                    exercise: ['webAPI', '$route', function (api, $route) {
                        return api.exercise.validateName({ ex: $route.current.params.exercise }).$promise;
                    }],
                    title: returnTitle('Judge')
                }
            })
            .when(routes.registrationList, {
                templateUrl: templateDirectory('running-info/registrationlist'),
                controller: 'RegistrationListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireRegistrationAuthorization,
                    entries: ['webAPI', 'activeCompetition', '$q', function (webAPI, activeCompetition, $q) {
                        var defer = $q.defer();
                        activeCompetition.data.$promise.then(function (data) {
                            webAPI.entries
                                .query({ competition: data.Id, id: null })
                                .$promise.then(function (entries) {
                                    defer.resolve(entries);
                                    return entries;
                                }, defer.reject);
                            return data;
                        });

                        return defer.promise;
                    }]
                }
            })
            .when(routes.musiclist, {
                templateUrl: templateDirectory('running-info/musiclist'),
                controller: 'MusicListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireAuthorization,
                    entries: ['webAPI', 'activeCompetition', '$q', function (webAPI, activeCompetition, $q) {
                        var defer = $q.defer();
                        activeCompetition.data.$promise.then(function (data) {
                            webAPI.entryScore
                                .getMusicList({ competition: data.Id })
                                .$promise.then(function (entries) {
                                    defer.resolve(entries);
                                    return entries;
                                }, defer.reject);
                            return data;
                        });

                        return defer.promise;
                    }]
                }
            })
            .when(routes.listAcro, {
                templateUrl: templateDirectory('running-info/list'),
                controller: 'ListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireAuthorization,
                    exercise: function () {
                        return { Name: 'Acrobatics' };
                    },
                    title: returnTitle('List Acrobatics')
                }
            })
            .when(routes.listTumble, {
                templateUrl: templateDirectory('running-info/list'),
                controller: 'ListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireAuthorization,
                    exercise: function () {
                        return { Name: 'Tumbling' };
                    },
                    title: returnTitle('List Tumbling')
                }
            })
            .when(routes.listArtistic, {
                templateUrl: templateDirectory('running-info/list'),
                controller: 'ListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireAuthorization,
                    exercise: ['webAPI', '$route', function (api, $route) {
                        return api.exercise.validateName({ ex: $route.current.params.exercise }).$promise;
                    }],
                    title: returnTitle('List Artistic')
                }
            })
            .when(routes.list, {
                templateUrl: templateDirectory('running-info/list'),
                controller: 'ListController',
                controllerAs: 'rotation',
                resolve: {
                    allow: requireAuthorization,
                    exercise: ['webAPI', '$route', function (api, $route) {
                        return api.exercise.validateName({ ex: $route.current.params.exercise }).$promise;
                    }],
                    title: returnTitle('List')
                }
            })

            .when(routes.judgeEntrantSearch, {
                templateUrl: templateDirectory('judgingsearch'),
                controller: 'JudgingController',
                controllerAs: 'judging',
                resolve: {
                    allow: requireJudgeAuthorization,
                    exercise: function () {
                        return '';
                    },
                    title: returnTitle("Entry Search")
                }
            })
            .when(routes.entrant, {
                templateUrl: templateDirectory('entry'),
                controller: 'EntryScoreController',
                label: "Entrant Search",
                resolve: {
                    allow: requireJudgeAuthorization,
                    title: returnTitle("Entry Information")
                }
            })
            .when(routes.entrantInfo, {
                templateUrl: templateDirectory('entry-info'),
                controller: 'EntryScoreController',
                label: "Entrant Search",
                resolve: {
                    allow: openAccess,
                    title: returnTitle("Entry Information")
                }
            })
            .when(routes.scorekeeper, {
                templateUrl: templateDirectory('entryscores'),
                controller: 'EntryScoreController',
                label: "Score Admin",
                resolve: {
                    allow: requireJudgeAuthorization,
                    title: returnTitle('Add Entry Scores')
                }
            })
            .when(routes.watchScores, {
                controller: 'WatchController',
                controllerAs: 'view',
                templateUrl: templateDirectory('watcher'),
                resolve: {
                    allow: openAccess,
                    rounds: ['activeCompetition', function (comp) {
                        return comp.rounds();
                    }],
                    report: function () { return null; },
                    title: returnTitle('Live Scores')
                }
            })
            .when(routes.watchRoundScores, {
                controller: 'WatchController',
                controllerAs: 'view',
                templateUrl: templateDirectory('watcher'),
                resolve: {
                    allow: openAccess,
                    rounds: ['activeCompetition', function (comp) {
                        return comp.rounds();
                    }],
                    report: ['webAPI', 'activeCompetition', '$route', '$q',
                        function (webAPI, activeCompetition, $route, $q) {
                            var defer = $q.defer();
                            if ($route.current.params.rnd) {
                                activeCompetition.data.$promise.then(function (data) {
                                    webAPI.reports.query({
                                        competition: data.Id,
                                        rnd: $route.current.params.rnd
                                    }).$promise.then(defer.resolve, defer.reject);
                                });
                            } else {
                                defer.resolve(null);
                            }
                            return defer.promise;
                        }],
                    title: returnTitle('Live Scores')
                }
            })
            .when(routes.watchApparatus, {
                templateUrl: templateDirectory('exercise-info'),
                controller: 'WatchApparatusController',
                resolve: {
                    allow: openAccess,
                    title: returnTitle('Live Apparatus Info')
                }
            })
            .when(routes.runningInfo, {
                templateUrl: templateDirectory('timeline'),
                controller: 'RunningInfoController',
                controllerAs: 'view',
                resolve: {
                    allow: openAccess,
                    timeline: ['activeCompetition', function (comp) {
                        return comp.timeline();
                    }],
                    title: returnTitle('Running Order')
                }
            })
            .when(routes.withdrawn, {
                templateUrl: templateDirectory('withdrawn-entries'),
                controller: 'WithdrawnEntriesController',
                controllerAs: 'view',
                resolve: {
                    allow: openAccess,
                    entries: ['webAPI', 'activeCompetition', '$q', function (webAPI, activeCompetition, $q) {
                        var defer = $q.defer();
                        activeCompetition.data.$promise.then(function (data) {
                            webAPI.entries.withdrawn({ competition: data.Id }).$promise.then(function (withdrawals) {
                                defer.resolve(withdrawals);
                                return withdrawals;
                            }, defer.reject);
                            return data;
                        });

                        return defer.promise;
                    }],
                    title: returnTitle('Withdrawn Entries')
                }
            })
            .when(routes.report, {
                templateUrl: templateDirectory('report'),
                controller: 'ReportController',
                label: "Reports",
                resolve: {
                    allow: requireHostAuthorization,
                    subs: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.subs();
                    }],
                    rounds: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.rounds();
                    }],
                    groups: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.groups();
                    }],
                    disciplines: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.disciplines();
                    }],
                    title: returnTitle('Reports')
                }
            })
            .when(routes.executionsReport, {
                templateUrl: templateDirectory('execution-report'),
                controller: 'ExecutionReportController',
                label: "Execution Reports",
                resolve: {
                    allow: requireHostAuthorization,
                    rounds: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.rounds();
                    }],
                    groups: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.groups();
                    }],
                    disciplines: ['activeCompetition', function (activeCompetition) {
                        return activeCompetition.disciplines();
                    }],
                    title: returnTitle('Execution Reports')
                }
            })
            .when(routes.topScores, {
                templateUrl: templateDirectory('topscores'),
                controller: 'TopScorersReportController',
                label: 'Top Scores',
                resolve: {
                    title: returnTitle('Top Scorers')
                }
            })
            .when(routes.peter, {
                templateUrl: templateDirectory('petertrophy'),
                controller: 'PeterTrophyController',
                label: 'Peter Trophy',
                resolve: {
                    title: returnTitle('Peter Trophy')
                }
            })
            .when(routes.totalScores, {
                templateUrl: templateDirectory('totalscores'),
                controller: 'TopScorersReportController',
                label: 'Total Scores',
                resolve: {
                    title: returnTitle('Total Scorers')
                }
            })
            .when(routes.message, {
                templateUrl: templateDirectory('message'),
                controller: 'MessageController',
                resolve: {
                    allow: requireAdminAuthorization,
                    title: returnTitle('Message Displays')
                }
            })
            .when(routes.withdraw, {
                templateUrl: templateDirectory('withdraw-entry'),
                controller: 'WithdrawController',
                controllerAs: 'withdraw',
                resolve: {
                    allow: requireAuthorization,
                    title: returnTitle('Withdraw Entry')
                }
            })
            .when(routes.identify, identify)
            .when(routes.identifyUser, identify)
            .when(routes.identifyUserRedirect, identify)
            .when(routes.listUsers, {
                templateUrl: templateDirectory('users/logged-in-users'),
                controller: 'ActiveUsersController',
                resolve: {
                    title: returnTitle('Active Users'),
                    allow: requireAdminAuthorization
                }
            })
            .when(routes.pendingScores, {
                templateUrl: templateDirectory('pending-scores'),
                controller: 'PendingScoresController',
                controllerAs: 'view',
                resolve: {
                    allow: requireJudgeAuthorization,
                    competition: getCompetition,
                    title: returnTitle('Save Pending Scores')
                }
            })
            .when(routes.requestTea, {
                templateUrl: templateDirectory('tea/request'),
                controller: 'TeaController',
                controllerAs: 'view',
                resolve: {
                    allow: requireAuthorization,
                    title: returnTitle('Tea Request'),
                    locations: getLocations
                }
            })
            .when('/calc', {
                templateUrl: templateDirectory('calc'),
                controller: 'CalculatorController'
            })
            .when(routes.notfound, {
                templateUrl: templateDirectory('not-found'),
                controller: function () { },
                resolve: {
                    allow: openAccess
                }
            })
            .when(routes.manageusers, {
                templateUrl: templateDirectory('users/manage-users'),
                controller: 'ManageUserController',
                controllerAs: 'view',
                resolve: {
                    allow: requireAdminAuthorization,
                    users: getUsers,
                    locations: getLocations
                }
            })
            .when(routes.timer, {
                templateUrl: templateDirectory('running-info/timer'),
                controller: 'TimerController',
                controllerAs: 'view',
                resolve: {
                    allow: requireHostAuthorization,
                    title: returnTitle("Warm up timer")
                }
            }).when(routes.clubTariffSheets, {
                templateUrl: templateDirectory('competitionAdmin/club-tariff-sheets'),
                controller: 'ClubTariffSheetsController',
                controllerAs: 'view',
                resolve: {
                    data: ['webAPI', '$route', '$q', function (api, $route, $q) {
                        var defer = $q.defer();
                        api.competitions.getFromName({ name: $route.current.params.competition }).$promise.then(
                            function (competition) {
                                api.entries.getForTariffSheets({ competition: competition.Id, key: $route.current.params.key })
                                    .$promise.then(function (entries) {
                                        defer.resolve({
                                            competition: competition,
                                            entries: entries,
                                            key: $route.current.params.key
                                        });
                                        return entries;
                                    });
                                return competition;
                            }, function () {
                                defer.reject();
                            }
                        );

                        return defer.promise;
                    }]
                }
            })


            //    .when(routes.notFound, { templateUrl: templateDirectory('page-not-found.html') })
            .otherwise({ redirectTo: routes.root });

    }]);

    getCompetition.$inject = ['activeCompetition'];
    function getCompetition(activeCompetition) {
        return activeCompetition.data.$promise;
    }

    getApparatus.$inject = ['webAPI', 'activeCompetition', '$q'];
    function getApparatus(webAPI, activeCompetition, $q) {
        var defer = $q.defer();
        activeCompetition.data.$promise.then(function (r) {
            webAPI.competitionJudging.query({ competition: r.Id }, function (a) {
                var categories = {};
                angular.forEach(a, function (d) {
                    if (!categories.hasOwnProperty(d.Category)) {
                        categories[d.Category] = [];
                    }
                    categories[d.Category].push(d);
                });
                defer.resolve(categories);
            }, defer.reject);
            return r;
        }, defer.reject);
        return defer.promise;
    }

    getLocations.$inject = ['webAPI', 'activeCompetition', '$q'];
    function getLocations(webAPI, activeCompetition, $q) {
        return getApparatus(webAPI, activeCompetition, $q).then(
            function (locations) {
                var tmpLocations = {};
                var result = [];
                angular.forEach(locations, function (location) {
                    angular.forEach(location, function (ex) {
                        tmpLocations[ex.Name] = ex.Name;
                    });
                });
                angular.forEach(tmpLocations, function (s) {
                    result.push(s);
                });

                Array.prototype.push.apply(result, ['Warm Up Area', 'Registration', 'Audience Entrance', 'Control Desk', 'Other']);
                return result;
            });
    }
    getUsers.$inject = ['webAPI'];
    function getUsers(webAPI) {
        return webAPI.user.query().$promise;
    }

    requireAuthorization.$inject = ['isAuthorized'];
    function requireAuthorization(allow) {
        return allow(['?']);
    }
    requireJudgeAuthorization.$inject = ['isAuthorized'];
    function requireJudgeAuthorization(allow) {
        return allow(['judge']);
    }
    requireAdminAuthorization.$inject = ['isAuthorized'];
    function requireAdminAuthorization(allow) {
        return allow(['admin']);
    }
    requireHostAuthorization.$inject = ['isAuthorized'];
    function requireHostAuthorization(allow) {
        return allow(['host']);
    }
    requireRegistrationAuthorization.$inject = ['isAuthorized'];
    function requireRegistrationAuthorization(allow) {
        return allow(['door']);
    }

    function openAccess() {
        return true;
    }
    function returnTitle(s) {
        return function () {
            return s;
        };
    }

}(angular.module('app')));