
/**
 * Created by kevinpayne on 07/04/16.
 */
(function () {
    angular.module('app.competitionAdmin')
        .controller('ClubTariffSheetsController', ClubTariffSheetsController);

    ClubTariffSheetsController.$inject = ['data', '$routeParams', 'webAPI', '$location', 'viewstatus', 'tgTariffCodes', 'tgCoP'];
    function ClubTariffSheetsController(data, $routeParams, api, $location, viewstatus, availableTariffs, tgCoP) {
        var vm = this;

        function isDS(t) { return t.ds; }
        function map(code) { return function (t) { return code + t.code; }; }

        vm.entries = data.entries.sort(function (a, b) { return a.EntryNumber - b.EntryNumber; });
        vm.competition = data.competition;
        vm.updateForm = updateForm;


        vm.DSElements = Array.prototype.concat.apply([], ['A', 'J']
            .map(function (key) {
                return availableTariffs[key].filter(isDS).map(map(key));
            }));

        vm.availableTariffs = Array.prototype.concat.apply([], Object.keys(availableTariffs).map(function (key) {
            return availableTariffs[key].map(function (v) {
                return { code: key + v.code, ds: v.ds };
            }); // map(key));
        }));
        // vm.tgBG5To6TariffCodes = Array.prototype.concat.apply([], Object.keys(tgBG5To6TariffCodes).map(function (key) {
        //     return tgBG5To6TariffCodes[key].map(function (v) {
        //         return { code: key + v.code };
        //     }); // map(key));
        // }));


        vm.getTariffs = function () {
            // return vm.isBGL5 ? vm.tgBG5To6TariffCodes : vm.availableTariffs;
            return vm.availableTariffs;
        };

        vm.compare = function (expected, actual) {
            return expected.indexOf(actual.toUpperCase()) === 0;
        };

        vm.modelOptions = {
            debounce: {
                default: 250,
                blur: 250
            },
            getterSetter: true
        };


        function editEntry($event, entry, form) {

            $event.preventDefault();
            $event.stopPropagation();
            form.$setPristine();
            if (entry === vm.entry) {
                vm.entry = undefined;
                vm.model = undefined;
            } else {
                vm.model = angular.copy(entry);
                vm.entry = entry;
                setTariffs(entry);
            }
        }



        vm.editEntry = editEntry;

        vm.save = save;
        vm.reset = reset;


        vm.addTariff = addTariff;
        vm.deleteTariff = deleteTariff;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.sumTariff = sumTariff;
        vm.selectTariff = selectTariff;
        vm.isBGL5 = false;

        vm.setTariffSheet = function () {
            vm.model.tariffSheet = createBlankSheet();
            setDDIndex();
            sumTariff();
        };

        vm.setDS = setDS;
        function selectTariff(tariff) { vm.selectedTariff = tariff; setDDIndex(); }


        function save() {
            viewstatus.startLoading();

            var saveModel = {
                Id: vm.entry.Id,
                ClubId: vm.entry.ClubId,
                CompetitionId: vm.entry.CompetitionId,
                EntryNumber: vm.entry.EntryNumber,
                data: data.key
            };

            var validSheet = true;
            if (vm.model.tariffSheet) {
                saveModel.TariffSheet = vm.model.tariffSheet.reduce(function (o, t, i, a) {
                    if (!validSheet) {
                        return o;
                    }
                    if (t.Code !== 'DD') {
                        if (t._Code === undefined) {

                            validSheet = false;

                        }

                        o.push(Object.assign({}, t, {
                            ExecutionOrder: i + 1,
                            ExerciseId: t.ExerciseId || vm.entry.Exercises[0].Id,
                            IsDS: t.TariffId === vm.model.selectedDS,
                            IsDD: false,
                            TariffId: t.TariffId > 0 ? t.TariffId : undefined,
                            Code: t.Code
                        }));
                    } else {
                        o[i - 1].IsDD = true;
                    }

                    return o;
                }, []);
            }
            if (!validSheet) {
                viewstatus.stopLoading();
                viewstatus.create('There are tariff sheet errors');
                return;
            }

            var promise = api.tariffs.updateClubEntry({ competition: data.competition.Id, key: data.key }, saveModel).$promise;

            promise.then(function (data) {
                // angular.copy(data, entry);
                vm.entry.Tariffs = data.Tariffs;
                vm.model.Tariffs = data.Tariffs;
                setTariffs(vm.entry);
                finish();
            }, viewstatus.displayWebApiError);
            promise['finally'](viewstatus.stopLoading);

        }
        function finish() {
            viewstatus.create(['Entry', vm.model.EntryNumber, 'was saved successfully'].join(' '), 'success');

        }
        function reset(form) {
            form.$setPristine();
            angular.copy(vm.entry, vm.model);
            setTariffs(vm.entry);

        }

        function updateForm(form) {
            angular.forEach(form.$error, function (e) {
                angular.forEach(e, function (control) { control.$setDirty(); });
            });
        }

        function setTariffs(entry) {
            var floorExercise = entry.Exercises[0];

            if (entry.Tariffs && entry.Tariffs.length > 0) {
                vm.model.tariffSheet = angular.copy(entry.Tariffs)
                    .filter(function (t) { return t.ExerciseId === floorExercise.Id; })
                    .sort(function (a, b) { return a.ExecutionOrder - b.ExecutionOrder; });
                vm.model.tariffSheet.forEach(function (t) {
                    if (t.IsDS) {
                        vm.model.selectedDS = t.TariffId;
                    }
                    if (!vm.isBGL5) {
                        t._Code = vm.availableTariffs.find(function (T) { return T.code === t.Code; });
                    }
                    if (!t._Code) {
                        t._Code = vm.tgBG5To6TariffCodes.find(function (T) { return T.code === t.Code; });
                        if (t._Code) {
                            vm.isBGL5 = true;
                        }
                    }
                });
                vm.model.tariffSheet.splice(vm.model.tariffSheet.findIndex(function (t) {
                    return t.IsDD;
                }) + 1, 0, { Code: 'DD' });
            } else {

                vm.model.tariffSheet = createBlankSheet();
            }

            sumTariff();
            setDDIndex();
        }


        function createBlankSheet() {
            if (vm.isBGL5) {

                return [
                    { TariffId: -2 },
                    { TariffId: -3 },
                    { TariffId: -4 },
                    { TariffId: -5 },
                    { TariffId: -6 },
                    { TariffId: -7 },
                    { TariffId: -8 }
                ];
            }

            return [
                { TariffId: -2 },
                { TariffId: -3 },
                { TariffId: -4 },
                { TariffId: -5 },
                { TariffId: -6 },
                { TariffId: -7 },
                { TariffId: -8 },
                { Code: 'DD' },
                { TariffId: -9 },
                { TariffId: -10 },
                { TariffId: -11 }

            ];
        }

        var pattern = /(([A,F,G,J])|([S,H,D]B))([2,4,6,8]|10)/i;

        function setDS(tariff, item) {
            if (!item.ds) {
                tariff.IsDS = false;
                if (vm.model.selectedDS === tariff.TariffId) {
                    vm.model.selectedDS = undefined;
                }
            }
            tariff.Code = item.code;
            tariff.Score = tariff.Code.match(pattern)[4] / 10;
        }

        function sumTariff() {

            vm.tariffTotal = vm.model.tariffSheet.reduce(function (p, c, i, a) {
                if (c.Code && c._Code) {
                    return p + c.Score;
                }
                else {
                    c.Score = 0; // reset score for tariff item, in case it has one.
                    return p;
                }
            }, 0);
        }

        function addTariff() {
            selectTariff({
                TariffId: Date.now() * -1
            });
            vm.model.tariffSheet.push(vm.selectedTariff);
        }
        function deleteTariff(tariff) {
            vm.model.tariffSheet = vm.model.tariffSheet.filter(function (t) { return t !== tariff; });
            if (vm.selectedTariff === tariff) {
                selectTariff();
            }
            sumTariff();
            setDDIndex();
        }
        function moveDown(tariff) {

            var old_index = vm.model.tariffSheet.indexOf(tariff);
            // if (old_index === 2 && vm.ddIndex === 3) {
            //     vm.model.tariffSheet = [].concat(
            //         vm.model.tariffSheet.filter(function (a, i) { return i < 2; }),
            //         [vm.model.tariffSheet[4], vm.model.tariffSheet[3], vm.model.tariffSheet[2]],
            //         vm.model.tariffSheet.filter(function (a, i) { return i > 4; }));
            //     vm.selectedIndex = 4;
            // } else {
            var new_index = old_index + 1;
            vm.model.tariffSheet.splice(new_index, 0, vm.model.tariffSheet.splice(old_index, 1)[0]);
            setDDIndex();
            // }

        }
        function moveUp(tariff) {
            var old_index = vm.model.tariffSheet.indexOf(tariff);

            var new_index = old_index - 1;
            vm.model.tariffSheet.splice(new_index, 0, vm.model.tariffSheet.splice(old_index, 1)[0]);
            setDDIndex();
        }
        function setDDIndex() {
            if (vm.isBGL5) {
                vm.ddIndex = -1;
                vm.maxElements = 7;
            } else {
                vm.maxElements = 11;
                vm.ddIndex = vm.model.tariffSheet.findIndex(function (t) { return t.Code === 'DD'; });
            }
            if (vm.selectedTariff) {
                vm.selectedIndex = vm.model.tariffSheet.findIndex(function (t) { return t === vm.selectedTariff; });
            }
            else {
                vm.selectedIndex = -1;
            }
        }

    }

}());
/*


 */