/**
 * Created by kevinpayne on 27/03/15.
 */
(function (app) {
    'use strict';

    score.$inject = executionScore.$inject = scoreLabel.$inject = passScore.$inject = ['$filter'];

    app
        .filter('urlFriendly', urlFriendly)
        .filter('chunk', chunk)
        .filter('score', score)
        .filter('executionScore', executionScore)
        .filter('passScore', passScore)
        .filter('scoreLabel', scoreLabel)
        .filter('groupBy', groupBy)
        .filter('sj', sj)
        .filter('composition', composition)
        .filter('twistToRotation', twistToRotation)
        .filter('sumTariffCollection', sumTariffCollection)
        .filter('xyData', xyData);

    function xyData() {
        return function (s) {
            if (s.constructor.name === 'String') {
                return JSON.parse(s);
            }
            return s;
        };
    }

    function sumTariffCollection(){
        return function(tariffs, isMicro){
            if(tariffs.length === 1){
                return tariffs[0].Score * (isMicro ? 3 : 6);
            } else {
                return tariffs.reduce(function(v, t){return v + t.Score;}, 0);
            }
        };
    }

    function urlFriendly() {
        return function (s) {
            return encodeURI(s.toLowerCase());
        };
    }
    function chunk() {
        return function (data, n) {
            return _.chain(data).groupBy(function (e, index) {
                return Math.floor(index / n);
            }).toArray().value();
        };
    }

    function groupBy() {
        return function (data, n) {
            return _.groupBy(function (e, index) {
                return e[n];
            });
        };
    }

    function score($filter) {
        return function (n) {

            if (!n && n !== 0) {
                return '';
            }

            if (!angular.isNumber(n)) {
                return n;
            }

            return $filter('number')(n, 3);
        };
    }
    function executionScore($filter) {
        return function (n, b) {
            return $filter('number')(n, b || 3);
        };
    }

    function sj() {
        return function (n) {

            if (!n || n === 0) {
                return '';
            }

            return n;
        };
    }
    composition.$inject = ['requirements'];
    function composition(requirements) {
        return function (v) {
            if (v) {
                return (requirements.find(function (r) {
                    return r.code === v;
                }) || { shortText: '' }).shortText;
            }
            return '';
        };
    }

    function twistToRotation() {
        return function (v) {
            if (v) {
                return v * 180;
            }
            return '';
        };
    }

    function passScore($filter) {
        return function (n) {
            if (!n) {
                return $filter('number')(n, 1);
            }
            return $filter('number')(n, 1);
        };
    }

    function scoreLabel($filter) {
        return function (n, x, k) {
            return k == 'Score' ? x : k == 'StartValue' ? 'SV' : n;
        };
    }



}(angular.module('app.shared')));