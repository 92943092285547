(function (app) {
    'use strict';

    angular.module('app')
        .controller('EntryScoreController', EntryScoreController);



EntryScoreController.$inject = ['$scope', 'webAPI', '$filter', 'activeEntry', 'search', 'scoreboardTicker', 'viewstatus', 'title'];
    function EntryScoreController($scope, webAPI, $filter, activeEntry, entrySearch, messaging, viewstatus, title) {
        var vm = $scope, filter = $filter('filter');
        vm.search = entrySearch;
        vm.activeEntry = activeEntry;
        vm.getScore = function(ex) {

            var entry = entrySearch.entry();
            var score = filter(entry.Scores,{ExerciseId: ex.Id});
            if(score.length > 0){
                score =  score[0];
                if((score.Passes || 1) < ex.Passes){
                    score.Passes = ex.Passes;
                }
                return score;
            }
            //todo: need service to initialise this
            var s = new webAPI.entryScore({
                ExerciseId: ex.Id,
                EventId: entry.CompetitionId,
                EntryNumber: entry.EntryNumber,
                EntryId: entry.Id,
                JudgeScores: [],
                Passes : ex.Passes
            });
            entry.Scores.push(s);
            return s;
        };


        $scope.$watch(function () {
            return entrySearch.entry() && entrySearch.entry().Id;

        }, function (newvalue, oldvalue) {
            if (newvalue && newvalue !== oldvalue) {

                var entry = vm.entry = entrySearch.entry();
                viewstatus.title = '[' + entry.EntryNumber + '] ' + (entry.TeamName || entry.EntryTitle);
                clearGroup();
                getGroup(entry.GroupId, entry.CompetitionId);
            } else{
                viewstatus.title = title;
            }

        });

      var msgUpdateScores =  messaging.on('entryScore', updateScores);
      var msgCorrection =  messaging.on('correction', updateScores);

        $scope.$on('$destroy', function(){
          if(msgUpdateScores){  messaging.off('entryScore', msgUpdateScores);}
           if(msgCorrection){ messaging.off('correction', msgCorrection);}
        });


        function clearGroup() {
            vm.group = new webAPI.groups();
        }

        function getGroup(id, competition) {
            if (!id) {
                return;
            }
            return vm.group.$entries({
                id: id,
                competition: competition
            }).then(
                function (r) {
                    var index = -1, entry = entrySearch.entry();
                    vm.discipline = r.discipline;
                    angular.forEach(r.Entries, function (e, i) {
                        if (e.Id === entry.Id) {
                            index = i;
                        }
                    });
                    if (index > -1) {
                        r.Entries[index] = entry;
                    }

                    return r;
                })
                .catch(function (err) {
                    if (err.status === 404) {
                        viewstatus.create('Group not found', 'danger', 3000);

                    }
                });

        }

        function updateScores(data) {
            var groupEntry, score;
            if (data.Entry.GroupId === $scope.group.Id) {
                angular.forEach($scope.group.Entries, function (entry) {
                    if (entry.Id === data.EntryId) {
                        groupEntry = entry;
                        return false;
                    }
                });
                if (groupEntry) {
                    angular.forEach(groupEntry.Scores, function (s) {
                        if (s.ExerciseId === data.ExerciseId) {
                            score = s;
                            return false;
                        }
                    });


                    if (score) {


                        angular.forEach($scope.group.Discipline.Category.Scoring, function (v) {
                            score[v.Key] = data[v.Key];
                        });
                        score.Score = data.Score;
                        if(!score.hasOwnProperty('Id')){
                            score.Id = data.Id;
                        }

                    } else{
                        score = new webAPI.entryScore(data);
                        groupEntry.Scores.push(score);

                    }
                }
            }
        }

    }
 
    OldEntryScoreController.$inject = ['$scope', 'webAPI', 'viewstatus', '$timeout',
        'scoreboardTicker', 'authorize', '$filter', 'activeCompetition'];
        function OldEntryScoreController($scope, webAPI, viewstatus, $timeout, messaging,  authorize, $filter, activeCompetition) {
            var serverScores;
            var filter = $filter('filter');



            function clear() {
                $scope.entry = new webAPI.entries({CompetitionId: activeCompetition.get().Id});
                clearScores();
            }




            clear();


            $scope.$watch('entry.EntryNumber', function (newvalue, oldvalue) {
                if (newvalue != oldvalue) {
                    clearScores();
                }
            });

            $scope.$watch('entry.Id', function (newvalue, oldvalue) {
                if (newvalue != oldvalue) {
                    clearScores();
                    if ($scope.entry.Id) {
                        angular.forEach($scope.entry.Scores, function (s) {
                            $scope.scores[s.ExerciseId] = s;
                        });

                        angular.forEach($scope.entry.Exercises, function(ex){
                            var s;
                           if(!$scope.scores.hasOwnProperty(ex.Id)){
                               s = new webAPI.entryScore({
                                   ExerciseId: ex.Id,
                                   EventId: $scope.entry.CompetitionId,
                                   EntryNumber: $scope.entry.EntryNumber,
                                   EntryId: $scope.entry.Id,
                                   JudgeScores: []
                               });
                               $scope.scores[ex.Id] = s;
                               $scope.entry.Scores.push(s);
                           }
                        });

                        getGroup($scope.entry.GroupId, $scope.entry.CompetitionId);
                    }
                }
            });


            $scope.$watch(function () {
                return viewstatus.viewLoading;
            }, function (value) {
                $scope.loading = value;
            });

            function updateScores(data) {
                var groupEntry, score;
                if (data.Entry.GroupId === $scope.group.Id) {
                    angular.forEach($scope.group.Entries, function (entry) {
                        if (entry.Id === data.EntryId) {
                            groupEntry = entry;
                            return false;
                        }
                    });
                    if (groupEntry) {
                        angular.forEach(groupEntry.Scores, function (s) {
                            if (s.ExerciseId === data.ExerciseId) {
                                score = s;
                                return false;
                            }
                        });


                        if (score) {


                            angular.forEach($scope.group.Discipline.Category.Scoring, function (v) {
                                score[v.Key] = data[v.Key];
                            });
                            if(!score.hasOwnProperty('Id')){
                                score.Id = data.Id;
                            }

                        } else{
                                score = new webAPI.entryScore(data);
                                groupEntry.Scores.push(score);

                        }
                    }
                }
            }

          var msgUpdateScores =  messaging.on('entryScore', updateScores);
          var msgCorrection = messaging.on('correction', updateScores);

            $scope.$on('$destroy', function(){
             if(msgUpdateScores){   messaging.off('entryScore', msgUpdateScores);}
              if(msgCorrection){  messaging.off('correction', msgCorrection);}
            });

        }




}(angular.module('app')));