/**
 * Created by kevinpayne on 17/05/15.
 */
(function (app) {
    'use strict';
    app.directive('rotationEntries', rotationEntries)
        .controller('RotationEntriesController', RotationEntriesController);

    var groups = {};

    rotationEntries.$inject = ['routes', 'Store'];
    function rotationEntries(routes, Store) {

        return {
            restrict: 'A',
            controller: RotationEntriesController,
            controllerAs: 'rotation',
            scope: {
                exercise: '='
            },
            templateUrl: routes.templateDirectory('rotation-entries')
        };
    }


    RotationEntriesController.$inject = ['$scope', 'activeEntry', 'activeCompetition', 'webAPI', '$routeParams', 'search', 'activeRotation', 'scoreboardTicker', 'user', 'viewstatus', '$q'];
    function RotationEntriesController($scope, activeEntry, activeCompetition, webAPI, $routeParams, search, activeRotation, messaging, user, viewstatus, $q) {

        var vm = this, categoryRounds;
        vm.model = {};
        vm.getGroup = getGroup;
        vm.getRotationEntries = getRotationEntries;
        vm.inActiveRound = checkInActiveRound;
        vm.sortEntries = sortEntries;
        vm.dropDown = dropDown;
        vm.lockScores = lockScores;
        vm.user = user;
        vm.unlock = unlock;

        vm.groups = groups;

        var exercise = $scope.exercise;


        function lockScores() {

            var scoreIds = vm.entries.reduce(function (o, e) {
                var score = e.Scores[0];
                if (score.HasScore && !score.Locked && !score._edited) {
                    o.push(score.Id);
                }
                return o;
            }, []);
            if (scoreIds.length > 0) {
                webAPI.entryScore.lockScores({ scoreIds: scoreIds }, function () { }, viewstatus.displayWebApiError);
            }
        }
        function unlock(score) {
            webAPI.entryScore.unlockScore({ scoreId: score.Id }, function () { }, viewstatus.displayWebApiError);
        }

        //live rounds:
        activeRotation.categoryRotations($routeParams.category).then(function (data) {

            vm.round = data;
            if (!(vm.model.round && vm.model.number)) {
                if (data.rounds.length > 1 && data.active.RoundNumber) {
                    vm.model.round = data.active.RoundNumber;
                    vm.model.number = data.active.ActiveRotation;
                }

            }
            return data;
        });

        activeCompetition.get().$promise.then(function (data) {

            vm.categoryRounds = activeCompetition.categoryRounds($routeParams.category);
            getRotationEntries();
        });

        exercise.$promise.then(function (e) {
            messaging.invoke('judgeJoinPanel', e.Name);
            return e;
        });

        var onWithdraw = messaging.on('withdrawn', updateEntryWithdrawStatus);
        var msgEntryScore = messaging.on('entryScore', handleEntryScore);
        var msgCorrection = messaging.on('correction', function (d) { handleEntryScore(d, true); });
        var msgSJ = messaging.on('sj', function (data) {
            var entry = findEntryById(data.id);
            if (entry) {
                entry.SJ = data.value;
            }
            entry = activeEntry.get();
            if (entry && entry.Id === data.id) {
                entry.SJ = data.value;
            }
        });


        var msgLocks = messaging.on('judgeScoresLocked', function (d) {
            var scores = {};
            var exId;
            vm.entries.forEach(function (e) {
                e.Scores.forEach(function (s) {
                    scores[s.Id] = s;
                    exId = s.ExerciseId;
                });
            });

            d.forEach(function (data) {
                if (scores.hasOwnProperty(data.Id)) {
                    scores[data.Id].Locked = data.Locked;
                    scores[data.Id].UpdatedBy = data.User.id;
                }
            });
            if (exId) {
                countCompletedScoresInRotation(exId);
            }
        });
        var msgEditedScore = messaging.on('judgeEditedScore', showEditedScore);


        function showEditedScore(data, entries) {


            var entry = findEntryById(data.entry, entries);
            if (entry) {
                var score = findScoreById(data.id, entry);
                if (score) {
                    if (!data.judges) {
                        score._edited = false;
                        score._editedBy = undefined;
                    } else {
                        score._edited = true;

                        getGroup(entry.GroupId).$promise.then(function (g) {
                            var judge = [];
                            var strings = [];
                            g.Discipline.Category.Scoring
                                .sort(sortByOrder)
                                .forEach(function (schema) {
                                    if (data.judges.hasOwnProperty(schema.Key)) {
                                        data.judges[schema.Key].sort().forEach(function (i) {
                                            var j = judge.indexOf(i);
                                            if (j === -1) {
                                                judge.push(i);
                                                strings.push([schema.ShortLabel]);
                                            } else {
                                                strings[j].push(schema.ShortLabel);
                                            }
                                        });
                                    }
                                });
                            score._editedBy = judge.map(function (j, i) {
                                return strings[i].join('') + j;
                            }).join(' ');
                            if (score._editedBy === 'CDE1 CDE2 CDE3 CDE4') {
                                score._editedBy = 'HJ';
                            }
                            return g;
                        });
                    }

                }
            }

        }
        $scope.$on('$destroy', function () {
            messaging.off('withdrawn', onWithdraw);
            messaging.off('entryScore', msgEntryScore);
            messaging.off('correction', msgCorrection);
            messaging.off('lockscores', msgLocks);
            messaging.off('judgeEditedScore', msgEditedScore);
            messaging.off('sj', msgSJ);

            messaging.invoke('judgeLeavePanel', exercise.Name);

        });


        $scope.$watch(function () {
            return search.entry() && search.entry().Id;
        }, function (newvalue, oldvalue) {
            if (angular.isNumber(newvalue)) {
                angular.forEach(vm.entries, function (e, i) {
                    if (e.Id === newvalue) {
                        angular.forEach(search.entry().Scores, function (s) {
                            if (s.Id === e.Scores[0].Id) {
                                e.Scores[0] = s;
                            }
                        });

                    }
                });
            }
        });
        $scope.$watch(function () {
            return search.entry() && search.entry().Withdrawn;
        }, function (newvalue, oldvalue) {
            if (newvalue === true || newvalue === false) {
                var entry = search.entry();

                var rotationEntry = findEntryById(entry.Id);
                if (rotationEntry && rotationEntry.Withdrawn !== entry.Withdrawn) {
                    rotationEntry.Withdrawn = entry.Withdrawn;
                    sortEntries();
                }
            }
        });
        function sortByOrder(a, b) {
            return a.Order - b.Order;
        }


        function handleEntryScore(data, isCorrection) {


            var score = findScore(data.EntryId, data.Exercise.Id);

            if (score) {

                score._edited = false;
                score._editedBy = undefined;

                score.D = data.D;
                score.E = data.E;
                score.A = data.A;
                score.Pen = data.Pen;
                score.Score = data.Score;
                score.DidNotCompete = data.DidNotCompete;
                score.HasScore = data.HasScore;
                score.StartValue = data.StartValue;

                if (!isCorrection || score.DidNotCompete) {
                    countCompletedScoresInRotation(data.Exercise.Id);
                }
            }
        }

        function countCompletedScoresInRotation(exerciseId) {
            if (user.headJudge) {
                var entries = vm.entries.filter(function (e) { return !e.Withdrawn; });
                var entriesWithScore = entries.reduce(function (counter, e) {
                    var score = findScoreByExerciseId(exerciseId, e);
                    if (score && score.HasScore) {
                        counter.hasScore++;
                        if (score.Locked) {
                            counter.lockedScores++;
                        }
                    }
                    return counter;
                }, { lockedScores: 0, hasScore: 0 });

                vm.canLockScores = entriesWithScore.hasScore > 0 && entriesWithScore.lockedScores !== entries.length;
            }
        }

        function findScore(entryId, exId) {
            return findScoreByExerciseId(exId, findEntryById(entryId));
        }

        function findScoreByExerciseId(exId, entry) {
            if (!entry) {
                return undefined;
            }
            return entry.Scores.find(function (entryScore) {
                return entryScore.ExerciseId === exId;
            });
        }

        function findScoreById(id, e) {
            if (!e) {
                return undefined;
            }
            return e.Scores.find(function (s) {
                return s.Id === id;
            });

        }

        function findEntryById(entryId, entries) {
            return (entries || vm.entries).find(function (e) {
                return e.Id === entryId;
            });
        }

        function checkInActiveRound() {
            if (vm.round) {
                return vm.model.round < vm.round.active.RoundNumber ||
                    (vm.model.round === vm.round.active.RoundNumber && vm.model.number && vm.model.number < vm.round.active.ActiveRotation);
            }
            return false;
        }


        function getGroup(groupId) {
            if (!groups[groupId]) {
                groups[groupId] = new webAPI.groups({ Id: groupId });
                groups[groupId].$promise = groups[groupId].$withDiscipline();
            }
            return groups[groupId];
        }

        function getRotationEntries(round, rotation) {
            // vm.groups = {};
            // vm.withdrawnGroups = {};

            vm.noEntries = false;
            //vm.noActiveRounds = false;

            var params = {
                categoryName: $routeParams.category,
                exerciseName: $routeParams.exercise,
                EventId: activeCompetition.data.Id
            };
            if ($routeParams.discipline) {
                params.discipline = $routeParams.discipline;
            } else {
                params.discipline = 'all';
            }

            vm.canLockScores = false;
            if (round && rotation) {
                params.round = round;
                params.rotation = rotation;

                vm.entries = webAPI.entries.getForRoundRotationAndExercise(params, entryResponse(round, rotation), errorResponse);

            } else {

                if (!round && !rotation) {
                    vm.entries = webAPI.entries.getForActiveRotationAndExercise(params, entryResponse(), errorResponse);
                }

            }
            vm.ready = false;
            vm.entries.$promise.then(function (entries) {
                var numberOfJudges = {};

                entries.forEach(function (e) {
                    var score = e.Scores[0];

                    if (user.keyFields) {
                        if (!numberOfJudges.hasOwnProperty(score.ExerciseId)) {
                            numberOfJudges[score.ExerciseId] = webAPI.judges.get({
                                ExerciseId: score.ExerciseId,
                                EventId: score.EventId,
                                RoundNumber: score.Round
                            });
                        }
                    } else {
                        if (!numberOfJudges.hasOwnProperty(score.ExerciseId)) {
                            numberOfJudges[score.ExerciseId] = { $promise: $q.when(null) };
                        }
                    }
                });
                vm.numberOfJudges = numberOfJudges;
                numberOfJudges = undefined;
                return entries;
            });

        }

        function errorResponse(err) {
            if (err.status === 404) {
                vm.noActiveRounds = true;
            }
        }
        function entryResponse(round, rotation) {
            return function entryResponse(data) {
                sortEntries(data);
                data.forEach(function (entry) {
                    getGroup(entry.GroupId);
                });


                if (round && rotation) {
                    vm.model.round = round;
                    vm.model.number = rotation;
                } else {
                    if (vm.round) {
                        //todo: don't set the round number if the active round is not set
                        //vm.model.round = vm.categoryRounds().active.RoundNumber;
                        //vm.model.number = vm.categoryRounds().active.ActiveRotation;


                        if (vm.round && vm.round.active && vm.round.active.RoundNumber) {
                            vm.model.round = vm.round.active.RoundNumber;
                            vm.model.number = vm.round.active.ActiveRotation;
                        }


                    } else {
                        if (data && data.length > 0) {
                            var score = data[0].Scores[0];
                            if (score && score.Round) {
                                vm.model.round = score.Round;
                                vm.model.number = score.Rotation;
                            }
                        }
                    }
                }
                if (data.length > 0) {
                    exercise.$promise.then(function (e) {
                        messaging.invoke('judgePendingEdits', e.Name).then(function (ed) {
                            ed.forEach(function (d) {
                                showEditedScore(d, data);
                            });
                        });
                        return e;
                    });

                    countCompletedScoresInRotation(data[0].Scores[0].ExerciseId);
                } else {
                    vm.canLockScores = false;
                }
                return data;

            };
        }
        function dropDown(entry) {
            var group = getGroup(entry.GroupId);
            if (!group.Discipline || !group.Discipline.DropDown) {
                return true;
            }
            return entry._idx < vm.model.number;
        }

        function updateEntryWithdrawStatus(data) {
            var arr = vm.entries || [];
            var requireUpdate = false;
            angular.forEach(arr, function (e) {
                if (e.Id === data.id) {
                    e.Withdrawn = data.value;
                    requireUpdate = true;
                }
            });
            if (requireUpdate) {
                sortEntries();
            }
        }


        function sortEntries(data) {
            data = data || vm.entries;
            //vm.groups = {};
            //vm.withdrawnGroups= {};
            vm.noEntries = (data.length === 0);
            vm.withdrawnEntries = 0;
            var i = 1;
            var increment = (data.length > 2) ? 1 : 0;
            if (data.length < vm.model.number) {
                i += data.length;
            }
            //data = data.sort(function(s1, s2){return s1.EntryNumber - s2.EntryNumber;});
            angular.forEach(data, function (e) {
                if (e.Withdrawn) {
                    vm.withdrawnEntries++;
                    e._idx = 0;
                } else {
                    e._idx = i;
                    i = i + increment;

                }

            });

            //angular.forEach(data, function (e) {
            //    var group;
            //
            //    if(e.Withdrawn){
            //        group = vm.withdrawnGroups[e.GroupId] || (vm.withdrawnGroups[e.GroupId] = []);
            //    }
            //    else{
            //        group = vm.groups[e.GroupId] || (vm.groups[e.GroupId] = []);
            //    }
            //
            //    group.push(e);
            //});

        }


    }


}(angular.module('app')));