
/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    "use strict";

    angular.module('app')
        .directive('judgeKeyFields', judgeKeyFields)
        .controller('JudgeKeyFieldsController', JudgeKeyFieldsController);


    judgeKeyFields.$inject = ['routes'];
    function judgeKeyFields(routes) {
        return {
            controller: JudgeKeyFieldsController,
            controllerAs: 'judging',
            templateUrl: routes.templateDirectory('judging/judge-key-fields')
        };

    }


    JudgeKeyFieldsController.$inject = ['webAPI', 'scoreboardTicker', 'activeEntry', 'activeJudges', 'user', '$scope', 'viewstatus', '$timeout'];
    function JudgeKeyFieldsController(webAPI, messaging, activeEntry, activeJudges, user, $scope, viewstatus, $timeout) {
        var vm = this;

        var exercise = activeEntry.getExercise();

        var score = activeEntry.getScore();

        vm.submitscore = submitscore;
        vm.activeEntry = activeEntry;
        vm.activeJudges = activeJudges;
        vm.judgeEnter = activeJudges.focus;
        vm.judgeExit = activeJudges.blur;

        var listeners = [];

        listeners.push(
            createUnsubscribe('judgeChangedKeyValue', updateScores),
            createUnsubscribe('judgeRevert', revertHandler)
        );


        $scope.$on('$destroy', function () {
            listeners.forEach(function (fn) { fn(); });
        });

        function revertHandler() {
            $timeout(function () {
                score = activeEntry.getScore();
                score._judges = {};
                score.tolerances = undefined;
                score.credits = undefined;
                $scope.scoresheet.$setPristine();
                if(!score.HasScore || score.DidNotCompete){
                    vm.alreadySubmitted = false;
                }
                setUpSchema();
            });
        }



        function createUnsubscribe(name, cb) {
            var fn = messaging.on(name, cb);
            return function () {
                messaging.off(name, fn);
            };
        }


        function findScore(s, defaultValue) {
            return score.JudgeScores.find(function (j) {
                return j.type === s.type && j.judgeNumber === s.judgeNumber && j.tariffid === s.tariffid;
            }) || defaultValue || null;
        }

        function updateScores(data) {
            score._judges = data.judgesSubmitted;
            data.scores.forEach(function (s) {
                var judge = findScore(s);
                if (judge) {
                    judge.score = s.score;
                } else {
                    score.JudgeScores.push(s);
                }
            });

            for (var key in data.score) {
                score[key] = data.score[key];
            }
        }


        if (exercise.$promise) {
            exercise.$promise.then(function (e) {

                setUpSchema();

                return e;
            });
        } else {
            setUpSchema();
        }


        function setUpSchema() {
            var schema = activeEntry.schema;
            var viewSchema = [];
            var judgeScores = {};

            if (score.HasScore && !score.DidNotCompete) {
                vm.alreadySubmitted = true;
            }
            var userKeyFields = user.keyFields.split(',');

            angular.forEach(schema, function (s) {
                if (user.keyFields.indexOf(s.Key) > -1) {

                    viewSchema.push(s);
                    judgeScores[s.Key] = [];

                    if (s.UseTariff) {
                        vm.tariffs = activeEntry.getTariffs();

                        var judges = {};

                        userKeyFields
                            .filter(function (k) { return k.indexOf(s.Key) === 0; })
                            .forEach(function (key) {
                                judges[key] = {};
                                var judgeNumber = +key.substr(1);

                                if(judgeNumber > s.NumberOfJudges){
                                    return;
                                }

                                if (score._judges && score._judges[s.Key] && score._judges[s.Key].indexOf(judgeNumber) > -1) {
                                    vm.alreadySubmitted = true;
                                }

                                vm.tariffs.forEach(function (tariff) {
                                    judges[key][tariff.TariffId] = {
                                        score: undefined,
                                        type: s.Key,
                                        judgeNumber: judgeNumber,
                                        tariffid: tariff.TariffId,
                                        scoreid: score.Id
                                    };
                                });
                                vm.judgeField = key; //this doesn't look right for multiple judge numbers in the same schema & user
                                score.JudgeScores
                                    .filter(function (j) {
                                        return j.tariffid && j.type === s.Key && j.judgeNumber === judgeNumber;
                                    })
                                    .forEach(function (j) {
                                        var judge = judges[key];
                                        if (!judge) {
                                            judge = judges[key] = {};
                                        }
                                        judge[j.tariffid] = j;
                                    });
                            });

                        judgeScores[s.Key] = judges;

                    } else {
                        if (s.HasMulti) {
                            judgeScores[s.Key] = score.JudgeScores.filter(function (j) {
                                return j.type === s.Key && user.keyFields.indexOf(j.type + j.judgeNumber) > -1;
                            }).sort(function (a, b) {
                                return a.judgeNumber - b.judgeNumber;
                            });
                        }
                    }
                }
            });

            vm.score = score;
            vm.scoringSchema = viewSchema;
            vm.judgeScores = judgeScores;

        }


        function submitscore() {
            if ($scope.scoresheet.$valid) {

                var data = {
                    id: exercise.Id,
                    scores: [],
                    score: {}
                };
                vm.scoringSchema.forEach(function (schema) {
                    if (schema.UseTariff) {
                        var types = vm.judgeScores[schema.Key];
                        for (var judgeField in types) {
                            for (var tariff in types[judgeField]) {
                                data.scores.push(types[judgeField][tariff]);
                            }
                        }

                    } else {
                        if (schema.HasMulti) {
                            data.scores.push.apply(data.scores, vm.judgeScores[schema.Key].slice());
                        } else {
                            data.score[schema.Key] = score[schema.Key];
                        }
                    }
                });
                // activeJudges.judgeKeyChange(data).then(function(data){
                //     viewstatus.
                // });
                activeJudges.judgeKeyChange(data, function (data) {
                    vm.alreadySubmitted = true;
                    $scope.scoresheet.$setPristine();
                    viewstatus.create('Scores sent to Head Judge', 'success');
                });
            }
        }
    }


}());