/**
 * Created by kevinpayne on 28/01/15.
 */

(function () {
    'use strict';


    //needed: Entry
    //        Score for exercise
    //        Exercise

    angular.module("app")
        .controller('JudgingController', JudgingController);

    JudgingController.$inject = ['webAPI','activeEntry', 'judgeStatus', '$routeParams', 'viewstatus', 'activeRotation', 'exercise', '$scope', 'title', 'helpModalService', 'scoreboardTicker', 'user'];
    function JudgingController(webAPI, activeEntry, judgeStatus, $routeParams, viewstatus, activeRotation, exercise, $scope, title, modal, ws, user) {
        var vm = this;
        vm.activeEntry = activeEntry;
        vm.exerciseName = exercise;
        vm.tab = [true, false, false]; 
        vm.saveSJ = saveSJ;
        vm.negateSJ = negateSJ;
        vm.user = user;
        vm.canShowSJ = user.headJudge || user.overrideLocation;

        if ($routeParams.category) {
            activeRotation.categoryRotations($routeParams.category.toLowerCase()).then(function (data) {
                vm.rotationInfo = data;
                return data;
            });     
        }   
        vm.judgeStatus = judgeStatus;
        vm.setTitle = setTitle;

        $scope.$watch(function () { return vm.tab; }, evaluateTabs, true);
        $scope.$watch(activeEntry.get, function (newvalue) {
            if (!newvalue) {
                evaluateTabs();
            }
        });

        function saveSJ(entry){
            entry.$sj();
        }
        function negateSJ(value){
            if(value.SJ && value.SJ > 0){
              value.SJ = value.SJ * -1;
            }
        }
        function setTitle(s) {
            viewstatus.title = s;
        }
        function evaluateTabs() {
            var exName = exercise && exercise.Name || '';
            angular.forEach(vm.tab, function (v, i) {
                if (v) {
                    switch (i) {

                        case 1:
                            setTitle('Search ' + exName);
                            break;
                        case 2:
                            setTitle('Withdraw Entry');
                            break;
                        case 3:
                            setTitle('Active Users');
                            break;
                        default:
                            if (exName) {
                                setTitle('Judge ' + exName);
                            } else {
                                setTitle(title);
                            }
                            break;

                    }
                }
            });
        }

        function setMessenger(client) {
            modal.open([client], client.name);
         }

        function updateClients(data) {
            vm.clients = (data || []).filter(function(u){ return u.location === exercise.Name;});
        }

         var clientListener = ws.on("Users", updateClients);
 
         vm.clients = [];
         vm.setMessenger = setMessenger;
 
 
         $scope.$on('$destroy', function () {
             ws.off('Users', clientListener);
         });
 
         ws.invoke('GetUsers', ''); 

    }
}());
