(function () {
    angular.module('app')
    .controller('RunningInfoController', RunningInfoController);
    
    RunningInfoController.$inject = ['timeline', 'activeRotation'];
    function RunningInfoController(timeline, activeRound) {
        var vm = this;
        vm.timeline = timeline;
        vm.activeRound = activeRound;
    }

}());