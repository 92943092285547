/* global angular */
/**
 * Created by kevinpayne on 17/03/15.
 */
(function () {
    'use strict';

    angular.module("app")
        .controller('WatchApparatusController', WatchApparatusController);

    WatchApparatusController.$inject = ['$scope', 'webAPI', 'scoreboardTicker', '$filter', 'activeCompetition', 'activeRotation', '$window', '$q'];

    function WatchApparatusController($scope, webAPI, messaging, $filter, activeCompetition, activeRotation, $window, $q) {
        var categories;
        var entrants = {}, msgHandleApparatusInfo;
        var groups = {};
        var groupIds = [];
        var loadedRound = {};
        var competitionId = 0;

        $scope.getPartition = partition;
        $scope.clearApparatus = clear;

        activeCompetition.get().$promise.then(showPage);

        function clear() {
            messaging.invoke('ClearApparatus');
        }

        function partition(exercises) {
            return $filter('chunk')(exercises, 3);
        }

        function checkInActiveRound(category) {
            if (!loadedRound[category] || loadedRound[category].round === 0) {
                return;
            }

            activeRotation.categoryRotations(category).then(function (round) {
                var categoryRound = loadedRound[category];
                if (categoryRound.round < round.active.RoundNumber ||
                    (categoryRound.round === round.active.RoundNumber && categoryRound.rotation < round.active.ActiveRotation)) {
                    categories[category].forEach(function (exercise) {
                        getRotationEntries(competitionId, category, exercise.data.Name).then(function (r) {
                            exercise.data.entries = r;
                            createNext(getNextEntry(exercise), exercise.data.Name)
                                .then(function (n) { exercise.next = n; return n; });
                            return r;
                        });
                    });
                }
                return round;
            });


        }

        function handleApparatusInfo(data) {
            if (!data) {
                angular.forEach(categories, function (x) {
                    angular.forEach(x, function (y) {
                        y.upNext = {};
                        y.justBeen = {};
                    });
                });
            } else {
                angular.forEach(data, function (apparatusInfo, category) {
                    angular.forEach(categories[category], function (exercise) {
                        var ex = "apparatus", upNext, justBeen;
                        if (exercise.data.MultiApparatus) {
                            ex = exercise.data.Name;
                        }
                        if (apparatusInfo[ex]) {
                            upNext = apparatusInfo[ex][0];
                            justBeen = apparatusInfo[ex][1];
                        }
                        if (upNext) {
                            entrants[upNext.entryId] = createEntrant(upNext);
                            exercise.upNext = upNext;
                        }
                        if (justBeen) {
                            entrants[justBeen.entryId] = createEntrant(justBeen);
                            exercise.justBeen = justBeen;
                        }
                    });
                    checkInActiveRound(category);
                });
            }
        }

        function showPage(newvalue) {
            competitionId = newvalue.Id;
            $scope.categories = {};
            categories = {};

            webAPI.competitionJudging.query({ competition: competitionId }, function (a) {
                angular.forEach(a, function (d) {
                    if (!categories.hasOwnProperty(d.CategoryName)) {
                        categories[d.CategoryName] = [];
                        loadedRound[d.categoryName] = { round: 0, rotation: 0 };
                    }
                    var categoryItem = { data: d, justBeen: {}, upNext: {} };
                    categories[d.CategoryName].push(categoryItem);
                    getRotationEntries(newvalue.Id, d.CategoryName, d.Name).then(function (r) {
                        d.entries = r;
                        createNext(getNextEntry(categoryItem), d.Name)
                            .then(function (n) { categoryItem.next = n; return n; });
                    });
                });
                //for (category in categories) {
                //    if (categories.hasOwnProperty(category)) {
                //
                //        $scope.categories[category] = $filter('chunk')(categories[category], 3);
                //    }
                //}
                msgHandleApparatusInfo = messaging.on('apparatusInfo', handleApparatusInfo);
                var msgHandleJudgeStatus = messaging.on('judgeStatus', handleJudgeStatus);
                var msgEntryScore = messaging.on('entryScore', handleEntryScore);
                var msgCorrection = messaging.on('correction', handleEntryScore);
                var withdrawn = messaging.on('withdrawn', function (data) {

                    Object.values(categories).forEach(function (c) {
                        c.forEach(function (exercise) {
                            exercise.data.entries.forEach(function (e) {
                                if (e.Id === data.id) {
                                    e.Withdrawn = data.value;
                                }
                            });
                        });

                    });

                });
                $scope.$on('$destroy', function () {
                    if (withdrawn) { messaging.off('withdrawn', withdrawn); }
                    if (msgEntryScore) { messaging.off('entryScore', msgEntryScore); }
                    if (msgCorrection) { messaging.off('correction', msgCorrection); }
                    if (msgHandleJudgeStatus) { messaging.off('judgeStatus', msgHandleJudgeStatus); }
                    if (msgHandleApparatusInfo) { messaging.off('apparatusInfo', msgHandleApparatusInfo); }
                });
                messaging.invoke('GetApparatusInfo');
            });
            $scope.categories = categories;


        }

        function getRotationEntries(competition, categoryName, exerciseName) {

            var params = {
                EventId: competition,
                categoryName: categoryName,
                exerciseName: exerciseName,
                discipline: 'all',
            };

            var defer = $q.defer();

            var result = webAPI.entries.getForActiveRotationAndExercise(params);

             result.$promise.then(
                function (r) {
                    if (r.length > 0) {
                        r.forEach(function (entry) {
                            if (groupIds.indexOf(entry.GroupId) === -1) {
                                groupIds.push(entry.GroupId);
                                getGroup(entry.GroupId);
                            }
                        });
                        if (!loadedRound.hasOwnProperty(categoryName)) {
                            loadedRound[categoryName] = { round: 0, rotation: 0 };
                        }
                        var categoryRound = loadedRound[categoryName];

                        categoryRound.round = r[0].Scores[0].Round;
                        categoryRound.rotation = r[0].Scores[0].Rotation;
                    }
                    getGroup(r[0].GroupId).$promise.then(function (group) {
                        defer.resolve(sortEntries(r.filter(function (e) { return !e.Withdrawn; }), group.Discipline.DropDown ?  loadedRound[categoryName].rotation : 0));
                        return group;
                    });

                    return r;
                });
                return defer.promise;
        }


        function getGroup(groupId) {
            if (!groups[groupId]) {
                groups[groupId] = new webAPI.groups({ Id: groupId });
                groups[groupId].$promise = groups[groupId].$withDiscipline();
            }
            return groups[groupId];
        }
        function sortEntries(data, dropdown) {

            var entriesToDropdown;
            data = data.sort(function (s1, s2) {
                var result = s1.Scores[0].StartOrder - s2.Scores[0].StartOrder;
                if (result === 0) {
                    return s1.EntryNumber - s2.EntryNumber;
                }
                return result;
            });
            //data = data.sort(function(s1, s2){return s1.EntryNumber - s2.EntryNumber;});
            if (dropdown > 0 && data.length > 2) {

                if (data.length >= dropdown) {
                    entriesToDropdown = data.splice(0, dropdown - 1);
                } else {
                    var diff = dropdown - data.length - 1;
                    entriesToDropdown = data.splice(0, diff);
                }
                data = data.concat(entriesToDropdown);

            }

            return data;
        }
        function findExercise(entrant, ex) {
            var exercise, isMulti, category;
            category = entrant.category;
            isMulti = entrant.isMulti;

            if (!categories[category]) {
                $window.location.reload();
            }
            if (isMulti) {
                angular.forEach(categories[category], function (e) {
                    if (e.data.Name === ex) {
                        exercise = e;
                        return false;
                    }
                });
            } else {
                exercise = categories[category][0];
            }

            if (exercise) {
                return exercise;
            }
        }

        function handleEntryScore(data) {

            var entrant = entrants[data.EntryId];
            var entry;

            if (entrant) {
                var exercise = findExercise(entrant, data.Exercise.Name);
                if (exercise) {
                    if (exercise.upNext && exercise.upNext.entryId === data.EntryId) {
                        exercise.upNext.score = data.Score;

                    } else {

                        if (exercise.justBeen && exercise.justBeen.entryId === data.EntryId) {
                            exercise.justBeen.score = data.Score;
                        }
                    }

                    entry = findEntry(exercise, data);
                    if (entry) {
                        updateEntryWithData(entry, data);
                    }
                    createNext(getNextEntry(exercise), data.Exercise.Name)
                        .then(function (n) { exercise.next = n; return n; });
                }
                setTimeout(function () {
                    checkInActiveRound(entrant.category);
                });
            } else {
                Object.keys(categories)
                    .forEach(function (category) {
                        if (!entry) {
                            categories[category].forEach(function (exercise) {
                                if (!entry && exercise.data.Name === data.Exercise.Name) {
                                    entry = findEntry(exercise, data);
                                    if (entry) {
                                        updateEntryWithData(entry, data);
                                        createNext(getNextEntry(exercise), data.Exercise.Name)
                                            .then(function (n) { exercise.next = n; return n; });
                                        setTimeout(function () {
                                            checkInActiveRound(category);
                                        });
                                    }
                                }
                            });
                        }
                    });
            }

        }


        function findEntry(exercise, data) {
            return exercise.data.entries.find(function (t) {
                return t.Id === data.EntryId;
            });
        }
        function updateEntryWithData(entry, data) {
            entry.Scores[0].HasScore = data.HasScore;
            entry.Scores[0].Score = data.Score;
            entry.Scores[0].D = data.D;
            entry.Scores[0].E = data.E;
            entry.Scores[0].A = data.A;
            entry.Scores[0].Pen = data.Pen;
        }

        function getNextEntry(exercise) {
            var currentIndex = 0;
            if (exercise.upNext) {
                currentIndex = exercise.data.entries.findIndex(function (e) {
                    return e.Id === exercise.upNext.entryId;
                });
            }
            return exercise.data.entries.find(function (e, i) {
                return !e.Withdrawn &&
                    !e.Scores[0].HasScore &&
                    i > currentIndex;
            });
            // return exercise.data.entries.find(function (e, i) {
            //     return !e.Scores[0].HasScore &&
            //         exercise.upNext && exercise.upNext.entryId !== e.Id;
            // });

        }

        function handleJudgeStatus(data) {
            var entrant = entrants[data.entryId] = createEntrant(data);
            var exercise = findExercise(entrant, data.exerciseName);

            if (exercise) {
                if (data.entryId !== exercise.upNext.entryId) {
                    exercise.justBeen = exercise.upNext;

                }
                if (data.discipline.MultiApparatus === false) {
                    if (data.entryId === exercise.upNext.entryId && data.exerciseName !== exercise.upNext.exerciseName) {
                        exercise.justBeen = exercise.upNext;
                    }
                }

                exercise.upNext = data;
                createNext(getNextEntry(exercise), data.exerciseName)
                    .then(function (n) { exercise.next = n; return n; });
            }
        }

        function createNext(data, exerciseName) {
            var result = $q.defer();
            if (!data) {
                result.resolve(null);
            }
            else {

                groups[data.GroupId].$promise.then(function (g) {
                    result.resolve({
                        clubName: data.ClubName,
                        discipline: g.Discipline,
                        entryId: data.Id,
                        entryName: data.EntryTitle,
                        entryNum: data.EntryNumber,
                        entryTeam: null,
                        exerciseId: data.Scores[0].ExerciseId,
                        exerciseName: exerciseName,
                        group: g.Name,
                        status: null
                    });
                    return g;
                });
            }
            return result.promise;
        }

        function createEntrant(data) {
            return { category: data.discipline.Category.Name, isMulti: data.discipline.MultiApparatus };
        }

    }

}());
