/**
 * Created by kevinpayne on 29/05/15.
 */
(function () {
    angular.module('app')
        .service('activeJudges', ActiveJudges);

    ActiveJudges.$inject = ['$filter', 'scoreboardTicker', 'activeEntry', 'user', 'judgeStatus', 'viewstatus'];

    function ActiveJudges($filter, messaging, activeEntry, user, judgeStatus, viewstatus) {
        var self = this;
        var filter;

        // var judgingCache = new Store('judgeUICache');
        filter = $filter('filter');
        var list = [];
        var data = {};
        var messages = [];

        this.setRoom = setRoom;
        this.clear = clear;
        this.getList = function () {
            return {
                data: data,
                list: list,
                messages: messages
            };
        };
        this.focus = focus;
        this.blur = blur;
        this.change = valueChanges;
        this.judgeChange = judgeScoreChanges;
        this.judgeKeyChange = judgeKeyScoreChanges;
        this.subscribeToChanges = subscribeToChanges;
        this.sendMessage = sendMessage;
        this.revert = revert;

        this.snitch = function (score, changes) {
            messaging.invoke('judgeFormSnitch', {
                id: score.Id,
                changedFields: changes
            });
        };


        function subscribeToChanges(fn) {
            return createUnsubscribe('judgeValueChange', fn);
        }

        function revert(id, doConfirm) {
            function doRevert() {
                messaging.invoke('judgeRevert', id);
            }
            // if (list.length > 1) {
            //     var judges = list.filter(function (i) { return i !== user.id; }).map(function (id) { return data[id].user; });
            //     if (judges.length > 1) {
            //         judges = judges.slice(0, -1).join(', ') + ' & ' & judges.slice(-1);
            //     } else {
            //         judges = judges[0];
            //     }
            if (doConfirm) {
                if (confirm('Reverting the score will remove any submitted scores and will need re-submitting. Proceed?')) {
                    doRevert();
                }
            }
            // }
            else {
                doRevert();
            }
        }

        function setRoom(score, cb) {
            self.room = score.Id;

            return messaging.invoke('judgeJoinApparatus', score.Id).then(function (response) {
                list.length = 0;
                response.judges.forEach(function (user) {
                    return addJudge(user);
                });
                if (response.messaging) {
                    messages = response.messaging;
                }
                subscribe();

                return response;
            });



            // messaging.invoke('getJudges', room, function(response){
            //     list.length = 0;
            //     data = {};
            //     response.forEach(function(user){
            //         return addJudge({user: user});
            //     });
            // });
        }
        function clear() {
            messaging.invoke('judgeLeaveApparatus', self.room);
            unsubscribe();
            messages.length = 0;
        }

        function addJudge($event) {
            if (list.indexOf($event.id) === -1) {
                list.push($event.id);
            }
            var user = data[$event.id];
            if (!user) {
                user = data[$event.id] = self.createJudge($event);
            }
            user.active = true;
        }

        function handleJoin($event) {
            if (!$event.user) {
                return;
            }
            if ($event.id === self.room) {
                addJudge($event.user);
            }
        }
        function handleLeave($event) {
            if (!$event.user) {
                return;
            }
            if ($event.room == self.room) {
                var idx = list.indexOf($event.user.id);
                if (idx > -1) {
                    list.splice(idx, 1);
                    data[$event.user.id].active = false;
                }

            }

        }
        var listeners;
        function createUnsubscribe(name, cb) {
            var fn = messaging.on(name, cb);
            return function () {
                messaging.off(name, fn);
            };
        }
        function handleReconnect() {
            var score = activeEntry.getScore();
            if (score.Id === self.room) {
                messaging.invoke('judgeJoinApparatus', activeEntry.getScore().Id).then(function (response) {
                    list.length = 0;
                    response.judges.forEach(function (user) {
                        return addJudge(user);
                    });
                    unsubscribe();
                    subscribe();

                    return response;
                });
            }



        }
        function subscribe() {
            listeners = [];
            listeners.push(createUnsubscribe('reconnect', handleReconnect));
            listeners.push(createUnsubscribe('judgeStatus', handleJudgeStatus));
            listeners.push(createUnsubscribe('judgeJoinApparatus', handleJoin));

            listeners.push(createUnsubscribe('judgeLeaveApparatus', handleLeave));
            listeners.push(createUnsubscribe('judgeAddScoreRow', addRow));
            listeners.push(createUnsubscribe('judgeValueChange', checkRowExists));
            listeners.push(createUnsubscribe('judgeKeyChange', checkKeyExists));
            listeners.push(createUnsubscribe('judgeMainValueChange', updateScore));

            listeners.push(createUnsubscribe('judgeKeyValueChange', updateScores));

            listeners.push(createUnsubscribe('judgeRemoveScoreRow', removeRow));
            listeners.push(createUnsubscribe('judgeMsg', updateJudges));
            listeners.push(createUnsubscribe('judgeEnter', judgeEnter));
            listeners.push(createUnsubscribe('judgeExit', judgeExit));

            listeners.push(createUnsubscribe('entryScore', handleScore));
            listeners.push(createUnsubscribe('correction', handleScore));

            listeners.push(createUnsubscribe('judgeRevert', handleRevertScore));


        }
        function handleRevertScore(data) {
            if (activeEntryAndRoomCorrect(data.Id)) {
                var entry = activeEntry.get();
                viewstatus.create("Entry : [" + entry.EntryNumber + "] " + entry.EntryTitle + " has been reverted by Head Judge", user.headJudge ? "success" : "warning");

                var score = activeEntry.getScore();
                score.A = data.A;
                score.E = data.E;
                score.D = data.D;
                score.Pen = data.Pen;
                score.Score = data.Score;
                score.JudgeScores.length = 0;
                score.JudgeScores.push.apply(score.JudgeScores, data.JudgeScores);
                activeEntry.setScore(score);

            }
        }


        function handleScore(data) {
            if (!user.headJudge) {
                if (activeEntryAndRoomCorrect(data.Id)) {
                    //todo: close the panel 
                    //todo: notifiy saved?

                    viewstatus.create("Entry : [" + data.Entry.EntryNumber + "] " + data.Entry.EntryTitle + ", Score: " + data.Score + " has been saved by Head Judge", "success");
                    activeEntry.clear();
                    clear();
                }
            }
        }



        function handleJudgeStatus(data) {

            var score = activeEntry.getScore();
            if (score.Id === self.room &&
                score.EntryId === data.entryId &&
                score.ExerciseId === data.exerciseId) {
                if (data.status !== judgeStatus.status.stop) {
                    judgeStatus.set(data.status);
                }
            }
        }

        function checkRowExists(data) {
            if (activeEntryAndRoomCorrect(data.id)) {
                if (data.score) {
                    var scores = activeEntry.getJudgeScores(data.score.type);
                    var judgeScore;
                    if (data.score.judgingid) {
                        judgeScore = scores.filter(function (s) { return s.judgingid === data.score.judgingid; })[0];
                    }
                    else {
                        // if (data.score.identifier) {
                        //     judgeScore = scores.filter(function (s) { return s.identifier === data.score.identifier; })[0];
                        // }
                        // if (!judgeScore) {
                        if (data.index >= scores.length) {
                            //todo: add score
                            while (true) {
                                addRowAndUpdate(data.score.type, { score: undefined });
                                scores = activeEntry.getJudgeScores(data.score.type);
                                if (scores.length > data.index) {
                                    break;
                                }
                            }

                        }
                        judgeScore = scores[data.index];

                        //judgeScore = scores[data.scores.findIndex(function (s) { return s.identifier === data.score.identifier; })];
                        // }
                    }
                    if (judgeScore) {
                        var schema = activeEntry.schema[judgeScore.type];
                        judgeScore.score = data.score.score;
                        judgeScore.identifier = data.score.identifier;
                        if (schema.Deduct) {
                            activeEntry.updateDeduction(judgeScore, schema, activeEntry.getScore());
                        }
                        activeEntry.judgeTotal(schema, undefined, judgeScore.type, undefined);
                    }
                    // else {
                    //     addRowAndUpdate(data.score.type, data.score);
                    // }

                }
            }
        }


        function checkKeyExists(data) {
            if (activeEntryAndRoomCorrect(data.id)) {
                if (data.score) {
                    var scores = activeEntry.getJudgeScores(data.score.type);
                    var judgeScore;
                    if (data.score.judgingid) {
                        judgeScore = scores.filter(function (s) { return s.judgingid === data.score.judgingid; })[0];
                    }
                    else {
                        // if (data.score.identifier) {
                        //     judgeScore = scores.filter(function (s) { return s.identifier === data.score.identifier; })[0];
                        // }
                        // if (!judgeScore) {


                        judgeScore = scores.filter(function (s) { return s.judgeNumber === data.score.judgeNumber; })[0];
                        if (!judgeScore) {
                            judgeScore = addRowAndUpdate(data.score.type, { score: undefined });
                        }

                        //judgeScore = scores[data.scores.findIndex(function (s) { return s.identifier === data.score.identifier; })];
                        // }
                    }
                    if (judgeScore) {
                        var schema = activeEntry.schema[judgeScore.type];
                        judgeScore.score = data.score.score;
                        judgeScore.identifier = data.score.identifier;
                        if (schema.Deduct) {
                            activeEntry.updateDeduction(judgeScore, schema, activeEntry.getScore());
                        }
                        activeEntry.judgeTotal(schema, undefined, judgeScore.type, undefined);
                    }
                    // else {
                    //     addRowAndUpdate(data.score.type, data.score);
                    // }

                }
            }
        }

        function judgeEnter(e) {
            data[e.judgeId].field = e.field;
        }
        function judgeExit(e) {
            var j = data[e.judgeId];
            if (j.field === e.field) {
                j.field = undefined;
            }
        }

        function activeEntryAndRoomCorrect(id) {
            return (id === self.room && activeEntry.getScore().Id === id);
        }

        function addRow(data) {

            if (activeEntryAndRoomCorrect(data.scoreid)) {
                var scores;
                while (true) {
                    scores = activeEntry.getJudgeScores(data.type);
                    if (scores.length > data.index) {
                        break;
                    }
                    addRowAndUpdate(data.type, data.score);
                }
            }
        }
        function addRowAndUpdate(key, value) {
            var schema = activeEntry.schema[key];
            var score = activeEntry.addScore(schema, key, true, value.score);
            score.identifier = value.identifier;
            activeEntry.judgeTotal(schema, undefined, key, undefined);
            return score;
        }

        function updateScore(data) {
            if (activeEntryAndRoomCorrect(data.id)) {
                activeEntry.getScore()[data.key] = data.value;
                activeEntry.total();
            }
        }
        function updateScores(data) {
            if (activeEntryAndRoomCorrect(data.id)) {
                var score = activeEntry.getScore();

                data.scores.forEach(function (judgeScore) {
                    var _score = score.JudgeScores.filter(function (s) {
                        return s.judgingid === judgeScore.judgingid ||
                            (s.type === judgeScore.type && s.judgingNumber === judgeScore.judgingNumber && s.tariffid === judgeScore.tariffid);
                    })[0];
                    _score.score = judgeScore.score;
                });
                activeEntry.setScore(score);
            }
        }

        function removeRow(data) {

            if (activeEntryAndRoomCorrect(data.scoreid)) {
                //it may become possible to remove saved judge scores which is only possible for edit, edit messaging isn't supported.
                var scores = activeEntry.getJudgeScores(data.type);
                if (data.score) {
                    var score;
                    if (data.score.judgingid) {
                        score = scores.filter(function (j) {
                            return j.judgingid === data.judgingid;
                        })[0];
                    }
                    else {
                        score = scores[data.index];
                    }
                    if (score) {
                        activeEntry.removeScore(data.type, score, -1);
                        activeEntry.judgeTotal(activeEntry.schema[data.type], undefined, data.type, undefined);
                    }
                }
            }

        }

        function unsubscribe() {
            if (listeners) {
                listeners.forEach(function (fn) { fn(); });
                listeners = undefined;
            }
        }


        function updateJudges(data) {
            messages.push(data);
        }

        function sendMessage(msg) {
            messaging.invoke('judgeMsg', {
                id: self.room,
                message: msg
            });
        }

        function focus($event) {
            // judgeEnter({
            //     judgeId: user.id,
            //     field: $event.target.name
            // });
            messaging.invoke('judgeEnter', {
                id: self.room,
                field: $event.target.name,
                value: $event.target.value
            });
        }
        function blur($event) {
            // judgeExit({
            //     judgeId: user.id,
            //     field: $event.target.name
            // });
            messaging.invoke('judgeExit', {
                id: self.room,
                field: $event.target.name,
                value: $event.target.value,
            });
        }

        function valueChanges(data, cb) {
            // var data = {
            //     key: values.key,
            //     judgeScore: judgeScore,
            //     index: values.index,
            //     value: value,
            //     field: $event.target.name
            // };


            messaging.invoke('judgeValueChange', Object.assign({}, data, { id: self.room })).then(function (data) {
                if (cb) { cb(data); }
            });
        }
        function judgeScoreChanges(data, cb) {
            // var data = { 
            //     judgeScore: judgeScore,
            //     index: values.index,  
            // };


            messaging.invoke('judgeJudgeScoreChange', Object.assign({}, data, { id: self.room })).then(function (data) {
                if (cb) { cb(data); }
            });
        }

        function judgeKeyScoreChanges(data, cb) {
            // var data = { 
            //     judgeScore: judgeScore,
            //     index: values.index,  
            // };

            messaging.invoke('judgeChangedKeyValue', Object.assign({}, data, { id: self.room })).then(function (data) {
                if (cb) { cb(data); }
            });
        }

    }


    ActiveJudges.prototype.createJudge = function (user) {
        function zeroTo255() {
            return Math.floor(Math.random() * 256);
        }

        var colours = [
            '#9300ff',
            '#159720',
            '#3b6dbb',
            '#dd8a0a',
            '#e70ddf',
            '#3d3c7a',
            '#3c747a',
            '#7a3c6f',
            '#CC00FF',
            '#0012ff'
        ];

        dataSet = this.getList();
        var usedColours = dataSet.list.map(function (id) { return dataSet.data[id] && dataSet.data[id].color; });
        var nonC = colours.filter(function (c) { return usedColours.indexOf(c) === -1; });
        var colour = nonC[0]; // nonC[~~(Math.random() * nonC.length)];
        if (!colour) {
            colour = 'rgb(' + [zeroTo255(), zeroTo255(), zeroTo255()].join(',') + ')';
        }
        return {
            user: user.name,
            color: colour,
            active: false
        };
    };


}());