(function (app) {

    app.service('gymnasts', function () {

        function getName(r) {

            if(r && r.FirstName) {
                return r.FirstName + ' ' + r.LastName;
            }
            return '';
        }

        return function (gymnasts) {
            if (gymnasts) {
                if (gymnasts.length > 1) {
                    return _.map(gymnasts, getName).join(', ');
                }
                return getName(gymnasts[0]);
            }
            return '';
        };

    });





}(angular.module('app.shared')));