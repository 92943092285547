/**
 * Created by kevinpayne on 21/12/14.
 */
/**
 * Created by kevinpayne on 21/12/14.
 */
(function (app) {
    'use strict';
    app.directive('displayScore', ['viewstatus', 'authorize', 'scoreboardTicker',
        function (viewstatus, authorize, messaging) {


            return {
                require: '^ngModel',
                link: function (scope, el, attr, ctrl) {


                    function display(){
                      messaging.invoke('Send', {
                          Id: ctrl.$modelValue.EntryId,
                          ScoreId: ctrl.$modelValue.Id
                      });
                    }


                    function clickHandler() {
                        scope.$apply(display);
                    }

                    el.addClass('btn btn-danger');
                    el.on('click.app.displayScore', clickHandler);
                    el.attr('title', 'Display score');
                    scope.$on('$destroy', function () {
                        el.off('click.app.displayScore', clickHandler);
                    });

                    scope.$watch(function(){return viewstatus.loadingView; }, function(newvalue){
                        if(newvalue === true){
                            el.attr('disabled', 'disabled');
                        }else{
                            el.removeAttr('disabled');
                        }

                    });
                }
            };
        }]);

    app.directive('displayScores', ['viewstatus', 'authorize', 'scoreboardTicker',
        function (viewstatus, authorize, messaging) {


            return {
                require: '^ngModel',
                link: function (scope, el, attr, ctrl) {


                    function display(){
                        messaging.invoke('SendScores', {
                            Id: ctrl.$modelValue.Id
                        });
                    }


                    function clickHandler() {
                        scope.$apply(display);
                    }

                    el.addClass('btn btn-danger');
                    el.on('click.app.displayScore', clickHandler);
                    el.attr('title', 'Display all scores');
                    scope.$on('$destroy', function () {
                        el.off('click.app.displayScore', clickHandler);
                    });

                    scope.$watch(function(){return viewstatus.loadingView; }, function(newvalue){
                        if(newvalue === true){
                            el.attr('disabled', 'disabled');
                        }else{
                            el.removeAttr('disabled');
                        }

                    });
                }
            };
        }]);


}(angular.module('app')));