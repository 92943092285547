
/**
 * Created by kevinpayne on 26/02/16.
 */
(function () {
    angular.module('app')
        .directive('requestHelp', requestHelp)
        .directive('displayHelp', displayHelp)
        .directive('displayMsg', displayMsg)
        .service('helperService', helperService)
        .service('helpModalService', modalService)
        .service('helpModalStack', modalStack)
        .controller('helpMessenger', messageController);

    function modalStack() {
        var modals = [];
        this.add = add;
        this.remove = remove;
        this.find = find;
        this.removeModal = removeModal;

        function add($modalInstance, user) {
            modals.push({ m: $modalInstance, u: user });
        }
        function remove(user) {
            modal = find(user);
            if (modal) {
                modals = modals.filter(function (m) { return m.u !== user; });
            }
        }
        function removeModal(modal) {
            modals = modals.filter(function (m) { return m.m !== modal; });
        }
        function find(user) {
            return modals.filter(function (m) { return m.u === user; })[0];
        }
    }

    messageController.$inject = ['$scope', '$modalInstance', 'user', 'helpModalStack', 'scoreboardTicker', 'recipients', 'data', 'title', 'id', 'Store'];
    function messageController($scope, $modalInstance, user, modalStack, messaging, recipients, data, title, id, Store) {

        var from, messages;
        var store = new Store('messaging');
        var messageData = store.find({ id: id }) || { id: id, messages: [] };
        var initialTime = new Date().getTime() - 36e5;
        messages = messageData.messages.filter(function (m) {
            return m.time > initialTime;
        });

        $scope.title = title;
        $scope.msgInput = '';
        $scope.messages = messages;


        if (data) {
            messages.push(data);
        }

        var listeners = [
            createUnsubscribe('helpMsg', updateMessages)
        ];

        //todo: subscribe to messages
        $scope.$on('$destroy', function () {
            //todo: stop listening
            listeners.forEach(function (fn) {
                fn();
            });
            listeners = undefined;
            modalStack.removeModal($modalInstance);
            var time = new Date().getTime() - 18e5;
            messageData.messages = messages.filter(function (m) {
                return m.time > time;
            });
            store.update(messageData);

        });

        $scope.send = function (data) {

            if (data) {

                messaging.invoke('helpMsg', { to: recipients, message: data }).then(
                    function (d) { messages.push(d); scrollTo(); }
                );

                $scope.msgInput = '';
            }
        };

        function createUnsubscribe(name, cb) {
            var fn = messaging.on(name, cb);
            return function () {
                messaging.off(name, fn);
            };
        }
        function updateMessages(data) {
            if (id === 'helper') {
                var m = modalStack.findModal($modalInstance);
                if (m.user === 'helper') {
                    m.user = data.user.id;
                }
                id = undefined;
            }
            if (recipients.filter(function (r) { return r.id === data.user.id; }).length > 0) {

                $scope.messages.push(data);
                scrollTo();
            }
        }
        function scrollTo() {
            setTimeout(function () {
                var element = angular.element('#scrollToMessage');
                if (element.length) {
                    element[0].scrollIntoView(false);
                }
            });
        }

    }

    modalService.$inject = ['$modal', 'helpModalStack'];
    function modalService($modal, modalStack) {
        this.open = function (recipients, title, data) {
            if (recipients && recipients.length > 0) {
                var id = recipients[0].id;
                if (recipients.length > 1) {
                    id = 'helper';
                }
                if (!modalStack.find(recipients[0].id)) {
                    modalStack.add($modal.open({
                        templateUrl: 'templates/messaging.html',
                        controller: 'helpMessenger',
                        resolve: {
                            recipients: function () { return recipients; },
                            data: function () { return data; },
                            title: function () { return title; },
                            id: function () { return id; }
                        },
                        backdrop: 'static'
                    }), id);
                }
            }
        };
    }
    requestHelp.$inject = ['scoreboardTicker', 'viewstatus', 'helpModalService'];

    function requestHelp(messaging, viewstatus, $modal) {
        return {
            link: function (scope, el, a) {
                el.on('click', getHelp);

                function getHelp(e) {
                    e.preventDefault();

                    messaging.invoke('RequestHelp').then(function (admins) {
                        requestSent = true;
                        viewstatus.create('Help request sent', 'success');
                        $modal.open(admins, 'Help Request Message');

                    });
                    return false;
                }

            }

        };
    }
    displayHelp.$inject = ['helperService'];
    function displayHelp(helperService) {
        return function () {
            helperService.init();
        };
    }
    displayMsg.$inject = ['helpModalStack', 'scoreboardTicker', 'helpModalService'];
    function displayMsg(modalStack, messaging, modal) {
        var onHelp;
        return function () {
            function init() {
                destroy();
                onHelp = messaging.on('helpMsg', function (d) {
                    if (!modalStack.find(d.user.id)) {
                        modal.open([d.user], d.user.name, d);
                    }
                });
            }
            function destroy() {
                if (onHelp) {
                    messaging.off('helpMsg', onHelp);
                }
            }
            init();
        };
    }


    helperService.$inject = ['scoreboardTicker', 'viewstatus', 'helpModalService', 'helpModalStack'];
    function helperService(messaging, viewstatus, modal, modalStack) {
        var onHelp;
        this.init = init;
        this.destroy = destroy;

        function init() {
            destroy();
            onHelp = messaging.on('HelpRequest', function (u) {
                viewstatus.create(['Help Required:', u.name, '@', u.location].join(' '), 'request-help', 900000);
                modal.open([u], "Help Request: " + u.name);
            });
        }
        function destroy() {
            if (onHelp) {
                messaging.off('HelpRequest', onHelp);
            }
        }


    }
}());