/**
 * Created by kevinpayne on 27/07/15.
 */
(function(){
    "use strict";
    angular.module('app')
        .controller('PendingScoresController', PendingScores);

    PendingScores.$inject =['scoreCache', 'webAPI', 'competition', 'viewstatus'];
    function PendingScores(scoreCache, api, competition, viewstatus) {
        var vm = this;
        var exercises = {};


        vm.getEntrant = getEntrant;
        vm.getExercise = getExercise;
        vm.deleteScore = deleteScore;
        vm.saveScore = save;
        //problem. cached pending scores only contains ids, store name information too??
        //    Add listing: Name, TeamName, Exercise Name, GroupName, Dis Name.

        loadScores();

        function getEntrant(entryId) {
            return api.entries.get({id: entryId, competition: competition.Id});
        }

        function loadScores(){
            vm.scores = scoreCache.findAllPendingScores(competition.Id);
        }


        function getExercise(exerciseId){
            if(!exercises[exerciseId]){
                exercises[exerciseId] = api.exercise.get({id: exerciseId});
            }
            return exercises[exerciseId];
        }


        function save(pending, scoreData){
            var score = new api.entryScore(scoreData);
            viewstatus.startLoading();
            var promise;
            promise = score[score.isNew() ? '$save' : '$update'](success(pending, scoreData), fail);
            promise['finally'](viewstatus.stopLoading);
        }

        function success(score, exercise) {
            return function(r) {
                r.HasScore = true;
                viewstatus.create('Score has been saved', 'success', 3000);
                deleteScore(score, exercise);
                return r;
            };

        }
        function fail(err) {
            viewstatus.displayWebApiError(err);
        }

        function deleteScore(score, exercise){
            scoreCache.removeScore(score, exercise);
            if(angular.equals({}, score.exercises)){
                loadScores();
            }

        }

//{"id":1,"exercises":{"19":{"Id":3004,"EntryNumber":1,"EntryId":745,"EventId":5,"ExerciseId":19,"StartValue":0,"Score":2,"D":1,"E":1,"A":1,"Pen":-1,"DidNotCompete":false,"HasScore":true,"Round":3,"Rotation":1,"UpdatedTime":null,"JudgeScores":[{"judgingid":21,"scoreid":3004,"score":1,"type":"E","pass1":0,"pass2":0,"pass3":0},{"judgingid":22,"scoreid":3004,"score":1,"type":"A","pass1":0,"pass2":0,"pass3":0}]}}}
    }
}());