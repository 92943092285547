/**
 * Created by kevinpayne on 12/03/16.
 */
(function(){

    angular.module('app.competitionAdmin')
        .controller('CompetitionHomeController', CompetitionHomeController);

    CompetitionHomeController.$inject = ['competitions', '$filter', 'activeCompetition', '$scope'];
    function CompetitionHomeController(competitions, $filter, activeCompetition, $scope) {
        var vm = this, filter = $filter('filter');
        vm.competitions = competitions;
        vm.active = activeCompetition.get();
        vm.multiDay = dateDiffMoreThanOneDay;
        $scope.$watch(function(){
          return  activeCompetition.data.Id;
        }, function(newvalue){
            if(newvalue){
                angular.forEach(filter(competitions, {Active: true}), function(c){
                    if(c.Id !== newvalue){
                        c.Active = false;
                    }
                });
            }
        });

        function dateDiffMoreThanOneDay(comp){
            return Math.abs(moment.duration({from: comp.Start, to: comp.End}).asDays()) > 0;
        }
    }

}());