/**
 * Created by kevinpayne on 17/03/15.
 */
(function () {
    'use strict';

    angular.module('app')
        .directive('updateStatus', ['judgeStatus', 'activeEntry',
        function (judgeStatus, activeEntry) {


            return {
                link: function (scope, el, attr, ctrl) {
                    var state = attr.state;

                    function send() {

                        if (judgeStatus.status() !== state) {
                            if(judgeStatus.statusType.hasOwnProperty(state)) {
                                judgeStatus[state](activeEntry.get(), activeEntry.getExercise());

                            }
                        }
                    }


                    function clickHandler() {
                       scope.$apply(send);
                    }

                    el.on('click.app.updateStatus', clickHandler);

                    scope.$on('$destroy', function () {
                        el.off('click.app.updateStatus', clickHandler);
                    });

                    function setActive(newvalue){
                        el[(newvalue === state)?'addClass':'removeClass']('active');
                    }

                    setActive(judgeStatus.status());

                    scope.$watch(judgeStatus.status, function(newvalue){

                        setActive(newvalue);
                    });


                }
            };
        }]);




}());