(function () {
    'use strict';

    angular.module('app')
        .controller('MessageController', MessageController);

    MessageController.$inject = ['$scope', 'scoreboardTicker'];
    function MessageController ($scope, ws) {
        //  var clientPushHubProxy = signalRHubProxy(signalRHubProxy.defaultServer, 'messagePushHub', { logging: true }); //receives.
         
        var onIdentities = ws.on("Identities", updateClients);

        $scope.msg = {};
        $scope.recipients = {};
        $scope.clients = [];

        $scope.noSelections = noSelections;
        $scope.sendMessage = sendMessage;
        $scope.refreshScreens =  refreshScreens;
        $scope.showSplash = showSplash;
        $scope.hideSplash = hideSplash;
        $scope.showTeamTime =  showTeamTime;
        $scope.hideTeamTime = hideTeamTime;
        $scope.clearLastShown = clearLastShown;
        $scope.clearDownJudges = clearJudge;

        function clearJudge(){
            ws.invoke('adminClearJudgingDown');
        }

        $scope.$on('$destroy', function () {
            ws.off('GetIdentities', onIdentities);
        });
        ws.invoke('GetIdentities', '');


        function getRecipients() {
            var recipients = [], key;

            for (key in $scope.recipients) {
                if ($scope.recipients.hasOwnProperty(key)) {
                    if ($scope.recipients[key] === true) {
                        recipients.push(key);
                    }
                }
            }
            return recipients;
        }

        function toggleSplash(recipients, value) {

            if (recipients) {
                ws.invoke('ShowSplash', {recipients: getRecipients(), value: value});
            } else {
                ws.invoke('ShowSplash', {recipients: [], value: value});
            }
        }

        function toggleTeamTime(recipients, value) {
            if (recipients) {
                ws.invoke('ShowTimeTeam', {recipients: getRecipients(), value: value});
            } else {
                ws.invoke('ShowTimeTeam', {recipients: [], value: value});
            }
        }





        function noSelections() {
            var result = _.indexOf(_.values($scope.recipients), true) === -1;
            return result;
        }

        function sendMessage(recipients) {
            var data;
            if (recipients) {
                data = angular.extend({recipients: getRecipients()}, $scope.msg);
                ws.invoke('Message', data);
            } else {
                ws.invoke('Message', $scope.msg);
            }
        }

        function refreshScreens(recipients) {
            if (recipients) {
                ws.invoke('RefreshRequest', getRecipients());
            } else {
                ws.invoke('RefreshRequest', []);
            }
        }

        function showSplash (recipients) {
            toggleSplash(recipients, true);
        }

        function hideSplash(recipients) {
            toggleSplash(recipients, false);
        }

        function showTeamTime(recipients) {
            toggleTeamTime(recipients, '/teamtime');
        }

        function hideTeamTime(recipients) {
            toggleTeamTime(recipients, '/');
        }

        function clearLastShown(recipients) {
            if (recipients) {
                ws.invoke('ClearLastShown', getRecipients());
            } else {
                ws.invoke('ClearLastShown', []);
            }
        }




        function updateClients(data) {
            var result = [];
            var oldData = $scope.recipients; //boolean values
            $scope.recipients = {};
            angular.forEach(data, function (d) {
                if(d !== 'tea-printer') {
                    result.push(d);
                    $scope.recipients[d] = oldData[d] || false;
                }
            });
            $scope.clients = result;
        }

    }


}());