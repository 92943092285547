/**
 * Created by kevinpayne on 02/09/16.
 */

//todo: music needs to sort out dropdown order.
//Sussex champs 2017

(function () {
    angular.module('app')
        .controller('RegistrationListController', RegistrationListController);



    RegistrationListController.$inject = ['entries', 'activeRotation', 'scoreboardTicker', '$scope', 'Store', 'viewstatus'];
    function RegistrationListController(entries, activeRotation, messaging, $scope, Store, viewstatus) {
        var vm = this;
        var rounds = {};
        vm.entries = rounds;
        vm.activeRotation = activeRotation;
        vm.register = register;
        vm.rounds = [];
        vm.model = {
            bib: undefined,
            search: undefined
        };
        vm.getFiltered = filterEntries;
        var onWithdraw = messaging.on('withdrawn', updateEntryWithdrawStatus);
        var onRegistered = messaging.on('registered', updateEntryRegistration);

        //var statusCache = new Store('registerCache');

        angular.forEach(entries, function (entry) {
           // var m = statusCache.find({ id: entry.Id });
            // if (m) {
            //     entry.highlight = m.highlight;
            // }

            var roundNumber;
            roundNumber = entry.Scores[0].Round;
            if (!rounds.hasOwnProperty(roundNumber)) {
                rounds[roundNumber] = { entries: [] };
                vm.rounds.push(roundNumber);
            }
            rounds[roundNumber].entries.push(entry);

        });



        $scope.$on('$destroy', function () {

            if (onWithdraw) {
                messaging.off('withdrawn', onWithdraw);
            }
            if (onRegistered) {
                messaging.off('registered', onRegistered);
            }
        });
        function register(entry){


            var temp = angular.copy(entry);

            temp.Registered = !temp.Registered;
            viewstatus.startLoading();
            temp.$register(function () {
                entry.Registered = temp.Registered;
            }, function(err){
                console.log(arguments);
            })['finally'](viewstatus.stopLoading);

        }

        // function highlightRow(row) {
        //     row.highlight = !row.highlight;
        //     updateCachedEntry(row);

        // }

        // function updateCachedEntry(entry) {
        //     var model = statusCache.find({ id: entry.Id }) || { id: entry.Id };
        //     if (entry.highlight) {
        //         model.highlight = entry.highlight;
        //         statusCache.update(model);
        //     } else {
        //         if (model.highlight === true) {
        //             statusCache.destroy(model);
        //         }
        //     }
        // }

        function updateEntryWithdrawStatus(data) {

            angular.forEach(entries, function (e) {

                if (e.Id === data.id) {
                    e.Withdrawn = data.value;
                    e.Registered = true;
                }
            });

        }
        function updateEntryRegistration(data){
            angular.forEach(entries, function(e){
                if(e.Id === data.id){
                    e.Registered = data.value;
                }
            });
        }
        function filterEntries(e){
            if(vm.model.bib){
                
                    return e.EntryNumber === vm.model.bib;
               
            }
            if(vm.model.search){
                var search = vm.model.search.toLowerCase();
                return e.EntryTitle.toLowerCase().indexOf(search) > -1 || 
                (e.TeamName && e.TeamName.toLowerCase().indexOf(search) > -1) ||
                e.ClubName.toLowerCase().indexOf(search) > -1;
            }
            return true;
        }


    }

}());