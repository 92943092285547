(function (app) {
    'use strict';
    app.controller('GlobalAppController', GlobalAppController);


    GlobalAppController.$inject = ['$scope', 'activeCompetition', 'viewstatus', '$location', 'routes', 'scoreboardTicker', '$interval', '$timeout', 'Store'];

    function GlobalAppController($scope, activeCompetition, viewstatus, $location, routes, messaging, $interval,$timeout, Store) {
        var vm = $scope, d, c;

        var timerCache = new Store('timerCache');


        vm.competition = activeCompetition.get();

        vm.viewstatus = viewstatus;
        vm.navigate = function (name, model) {
            $location.url(routes.buildRoute(name, model));
        };
        d = messaging.on('disconnect', function () {
            vm.connection = false;
        });
        c = messaging.on('connect', function () {
            if (vm.connection === false) {
                vm.connection = true;
            }
        });


        var msgCountdown = messaging.on('countdown', rotationWarmUp);
        var msgHideCountdown = messaging.on('hidecountdown', cancelRotationWarmUp);
        var int;

        var timerInfo = timerCache.first();
        if (timerInfo) {
            var timerValue = timerInfo.startTime - new Date().setMilliseconds(0);
            if (timerValue > 0) {
                vm.timer = timerValue;
                vm.totalSeconds = timerInfo.totalSeconds;
                vm.timerTitle = timerInfo.title;
                runInterval(timerInfo.startTime);
            } else {
                timerCache.clearDown();
            }
        }

        $scope.$on('$destroy', function () {
            messaging.off('disconnect', d);
            messaging.off('connect', c);
            messaging.off('countdown', msgCountdown);
            messaging.off('hidecountdown', msgHideCountdown);
            if (int) {
                $interval.cancel(int);
            }

        });


        function rotationWarmUp(data) {

            var startTime;
            if ((data.time.hours + data.time.minutes + data.time.seconds) === 0) {
                cancelRotationWarmUp();
            } else {
                if (int) {
                    $interval.cancel(int);
                }

                vm.totalSeconds = (data.time.hours * 3600000) + (data.time.minutes * 60000) + (data.time.seconds * 1000);
                vm.timerTitle = data.title;

                startTime = new Date().setMilliseconds(0) + vm.totalSeconds;
                vm.timer = vm.totalSeconds;

                if (!timerInfo) {
                    timerInfo = {};
                }

                timerInfo.startTime = startTime;
                timerInfo.totalSeconds = vm.totalSeconds;
                timerInfo.title = data.title;

                timerCache.update(timerInfo);

                runInterval(startTime);
            }

        }
        function runInterval(startTime) {
            int = $interval(function () {
                vm.timer = startTime - new Date().setMilliseconds(0);
                if (vm.timer < 0) {
                    vm.timer = 0;
                    $interval.cancel(int);
                    timerCache.clearDown();
                    $timeout(function () {
                        if (vm.timer === 0) {
                            vm.totalSeconds = 0;
                        }
                    }, 15000);
                }
            }, 1000);
        }

        function cancelRotationWarmUp() {
            if (int) {
                $interval.cancel(int);
            }
            vm.totalSeconds = 0;
            vm.timer = 0;
            timerCache.clearDown();
        }
    }
}(angular.module('app')));