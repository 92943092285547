(function () {
    "use strict";
    angular.module('app.competitionAdmin')
        .controller('DataImportController', ImportDataCtrl)
        .directive("selectNgFiles", function () {
            return {
                require: "ngModel",
                link: function postLink(scope, elem, attrs, ngModel) {
                    elem.on("change", function (e) {
                        var files = elem[0].files;
                        ngModel.$setViewValue(files);
                    });
                }
            };
        });

    ImportDataCtrl.$inject = ['webAPI', 'viewstatus', 'competition'];
    function ImportDataCtrl(webAPI, viewstatus, competition) {
        var vm = this;
        vm.model = { files: [] };
        vm.upload = submitForm;
        vm.convert = convertEntries;
        vm.competition = competition;
        vm.viewcurrent = getdata;

        function convertEntries(conversionType) {
            var request;
            switch (conversionType) {
                case 1:
                    request = webAPI.dataImport.createFV(competition);
                    break;
                case 2:

                    request = webAPI.dataImport.createTG(competition, {
                        parentEvent: vm.parentEvent,
                        createGroups: vm.parentEvent ? false : true
                    });
                    break;
                case 3:
                    request = webAPI.dataImport.createMAG(competition);
                    break;
                case 4:
                    request = webAPI.dataImport.createRegional(competition);
                    break;
                case 5:
                    request = webAPI.dataImport.createPegasusOpen(competition);
                    break;
                case 6:
                    request = webAPI.dataImport.createGrades(competition);
                    break;
            }
            if (request) {
                request.then(function (result) {
                    viewstatus.create('Entries created, please check data', 'success');
                },
                    function (err) {
                        viewstatus.create(err.data);
                    });
            }
        }
        function getdata() {
            webAPI.dataImport.query().then(function (res) {
                if (res.data.length === 0) {
                    viewstatus.create('Data cache is empty', 'info');
                }
                setView(res);
            });
        }

        function setView(res) {
            vm.newData = res.data;
            if (res.data.length > 0) {
                vm.keys = Object.keys(res.data[0]).filter(function (k) {
                    return res.data[0][k] !== null;
                });
            } else {
                vm.keys = [];
            }
        }


        function submitForm(e) {
            e.preventDefault();

            var formData = new FormData();
            var i = 0;
            for (i = 0; i < vm.model.files.length; i++) {
                formData.append("file", vm.model.files[i]);
            }
            webAPI.dataImport.post(formData)
                .then(function (res) {
                    viewstatus.create('Data saved successfully', 'success');
                    setView(res);
                })
                .catch(function (err) {
                    viewstatus.create(err, 'danger');
                    console.log(err);
                });
        }
    }

}());


