(function (app) {
    'use strict';

    angular.module('app.shared')
        .controller('GroupScoresController', GroupScoresController);

    GroupScoresController.$inject = ['$scope', 'webAPI', 'viewstatus', '$timeout', 'entryPosition', '$filter', 'reportSettings', 'gymnasts'];
    function GroupScoresController($scope, webAPI, viewstatus, $timeout, entryPosition, $filter, reportSettings, gymnasts) {

        var orderBy = $filter('orderBy'), limitTo = $filter('limitTo'), filter = $filter('filter');

        $scope.gymnasts = returnGymnasts;
        $scope.$watch('group.Entries', entriesChanged, true);
        $scope.scoring = scoring;
        $scope.getDiscipline = getDiscipline;
        $scope.calculate = calculate;
        $scope.orderValue = getOrderValue;
        $scope.isGold = isGold;
        $scope.isSilver = isSilver;
        $scope.isBronze = isBronze;
        $scope.isCounted = isCounted;
        $scope.groupPositionsOrdering = groupPositionOrdering;
        $scope.scorePositionOrdering = getScore;

        $scope.setMedalColour = setMedalColour;

        $scope.nameColspan = ($scope.group.Exercises || $scope.group.ExerciseSet.Exercises).length > 1 ? 2 : 1;
        $scope.sortByName = function (name) {
            if ($scope.columnFilter === name) {
                $scope.columnFilter = undefined;
            } else {
                $scope.columnFilter = name;
            }
        };


        function entriesChanged(newvalue) {
            if (newvalue) {
                calculate(newvalue);
            }
        }


        function returnGymnasts(entry) {
            if (entry.EntryTitle) {
                return entry.EntryTitle;
            }
            return gymnasts(entry.Gymnasts);
        }
        function setMedalColour(grpEntry, key){
            if(key === $scope.columnFilter){
                var data;
                if($scope.columnFilter){
                    data = $scope.exercisePositions[$scope.columnFilter];
                }
                if(isGold(grpEntry, data)) return 'gold';
                if(isSilver(grpEntry, data)) return 'silver';
                if(isBronze(grpEntry, data)) return 'bronze';
            }
            if(!$scope.columnFilter){
                if( isTopThree(grpEntry, key)) return 'topthree';
            }
        }
        function isTopThree(grpEntry, key){
            if(grpEntry.Guest) return false;
            if(key){
                return $scope.exercisePositions[key][grpEntry.EntryNumber].order < 4  && $scope.exercisePositions[key][grpEntry.EntryNumber].order > 0;
            }
            return $scope.groupPositions[grpEntry.EntryNumber].order < 4 && $scope.groupPositions[grpEntry.EntryNumber].order > 0;
        }
        function isGold(entry, data) {
            
            if (reportSettings.hideScores) {
                return false;
            }
            if (entry.Guest) {
                return false;
            }
            data = data || $scope.groupPositions;
            
            return 1 === data[entry.EntryNumber].order && data[entry.EntryNumber].value;
           
        }
        function isSilver(entry, data) {
            if (reportSettings.hideScores) {
                return false;
            }
            if (entry.Guest) {
                return false;
            }
            data = data || $scope.groupPositions;
            
            return 2 === data[entry.EntryNumber].order && data[entry.EntryNumber].value;
        }
        function isBronze(entry, data) {
            if (reportSettings.hideScores) {
                return false;
            }
            if (entry.Guest) {
                return false;
            }
            data = data || $scope.groupPositions;
            
            return 3 === data[entry.EntryNumber].order && data[entry.EntryNumber].value;
        }

        function isCounted(e, x) {
            var score = filter(e.Scores, { ExerciseId: x.Id })[0];
            if (score) {
                return score.isCounted || false;
            }
            return false;
        }

        function groupPositionOrdering(a) {
            if (reportSettings.hideScores) {
                return 0;
            }
            var order, pos, idx;
            if (!$scope.columnFilter) {
                pos = $scope.groupPositions[a.EntryNumber];
            }
            else {
                pos = $scope.exercisePositions[$scope.columnFilter][a.EntryNumber];
            }
            order = pos.order || $scope.group.Entries.length;
            idx = pos.index || 0;
            return  order + idx;
        }

        function getOrderValue(a) {
            var pos = $scope.groupPositions[a.EntryNumber];
            if (a.Guest) {
                return 'G';
            }
            if (pos.value && pos.order > 0) {
                return pos.order;
            }
            return '';

        }

        function getScore(a) {
            if (reportSettings.hideScores) {
                return 0;
            }
            return -$scope.groupPositions[a.EntryNumber].value;

        }
        function scoring() {
            return (getDiscipline()).Category.Scoring;
        }


        function getDiscipline() {
            return ($scope.group.Discipline || $scope.discipline);
        }


        function calculate(entries, discipline) {
            var data;
            if (!discipline) {
                discipline = getDiscipline();
            }
            data = entryPosition.calculate(entries, discipline, $scope.group);

            $scope.exercisePositions = data.exercisePositions;
            $scope.groupPositions = data.groupPositions;

            data = undefined;
        }
    }


}());